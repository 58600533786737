import { React, useEffect, useState } from "react";
import "./ChatBubble.css";
import { Modal, Box, Grid, Stack } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as moment from 'moment';
import {
  LeftOutlined,
  RightOutlined,
  CloseOutlined
} from '@ant-design/icons';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Tooltip } from 'antd';

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
    ],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
  ],
}

const ChatBubble = ({ message, sender, msgStatus, timestamp, index, msgDetails, updatemessage, setMessage, messages, setmessagekey, setmessage_id, isEditable }) => {
  let fobeuser = sessionStorage.getItem("fobeuser");
  let fobeusername = sessionStorage.getItem("fobeusername");
  let username = sessionStorage.getItem("Username");
  const [AccessKey, setAccessKey] = useState(sessionStorage.getItem("AccountId"));
  const [loginUserName, setLoginUserName] = useState(fobeusername == "" ? username == "" ? fobeuser : username : fobeusername);
  const [imgList, setImgList] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [open, setOpen] = useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,

    pt: 2,
    px: 4,
    pb: 3,
  };
  const [isEditing, setIsEditing] = useState(false);
  const [updatedMessage, setUpdatedMessage] = useState(message);
  const [updateMsgid, setUpdateMsgid] = useState();
  const [isEmptyMsg, setIsEmptyMsg] = useState(false);

  const openImg = (imgIndex, msgDetails) => {
    setImgList(msgDetails.images);
    setImageIndex(imgIndex);
    setOpen(true);
  };

  const zoom = (event) => {
    if (event.deltaY < 0) { // scroll Up
      setScale(prevScale => Math.min(prevScale + 0.1, 5));
    } else { // scroll Down
      setScale(prevScale => Math.max(prevScale - 0.1, 0.1));
    }
  };

  const onEdit = (index) => {
    setmessagekey('update');
    setUpdatedMessage(message)
    setUpdateMsgid(index);
    setIsEditing(true);
  };

  const handleSend = () => {
    console.log("Message", updatedMessage);
    if (updatedMessage === '<p><br></p>' || updatedMessage === '' || updatedMessage === '<p></p>') {
      setIsEmptyMsg(true);
      return false;
    }

    if (updatedMessage.trim()) {
      updatemessage({
        "text": updatedMessage,
        "sender": loginUserName,
        "images": [],
        "timestamp": new Date(),
        "msgStatus": 0,
        id: index,
        key: 'update'
      });

      messages.push({
        "text": updatedMessage,
        "sender": loginUserName,
        "images": [],
        "timestamp": new Date(),
        "msgStatus": 0,
        id: index,
        key: 'update'
      });

      setIsEditing(false);
    }
  };

  const handleSaveEdit = (index) => {
    handleSend();
  };

  const onDelete = (index) => {
    updatemessage({
      "text": updatedMessage,
      "sender": loginUserName,
      "images": [],
      "timestamp": new Date(),
      "msgStatus": 0,
      id: index,
      key: 'delete'
    });

    messages.push({
      "text": updatedMessage,
      "sender": loginUserName,
      "images": [],
      "timestamp": new Date(),
      "msgStatus": 0,
      id: index,
      key: 'delete'
    });
  };

  useEffect(() => {
    let ele = document.getElementById(`message_list${index}`);
    if (ele) {
      let imgTags = ele.getElementsByTagName("img");
      if (imgTags.length > 0) {
        Array.from(imgTags).forEach((img, imgIndex) => {
          img.setAttribute("id", `img_${imgIndex}`);
          img.addEventListener('click', () => openImg(imgIndex, msgDetails));
        });
      }
    }
  }, [message]);

  return (
    <>
      <div className={!loginUserName?.includes(sender) ? `chat-bubble` : `chat-bubble chat-bubble--user`}>
        <div>
          <span className={!loginUserName?.includes(sender) && `post_by`}>
            {!loginUserName?.includes(sender) && (
              <span className="chat-bubble__sender">{sender}</span>
            )}
            <time className="chat-bubble__time">
              {moment(new Date(timestamp)).format('MM/DD/YYYY hh:mm A')}
            </time>
          </span>
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: !loginUserName.includes(sender) ? '' : 'flex-end' }}>
            {sender === fobeusername && isEditable && !isEditing
              ? (
                <Tooltip
                  title={
                    <div
                      className="edit-icon"
                      style={{
                        display: 'flex',
                        gap: '10px',
                        top: 10
                      }}
                    >
                      <ModeEditOutlineOutlinedIcon
                        onClick={() => onEdit(index)}
                        style={{ cursor: 'pointer', fontSize: '18px' }}
                      />
                      <DeleteOutlineOutlinedIcon
                        onClick={() => onDelete(index)}
                        style={{ cursor: 'pointer', fontSize: '18px' }}
                      />
                    </div>
                  }
                  overlayClassName="custom-tooltip"
                  placement="topRight"
                  arrow={false}
                >
                  <div className={!loginUserName?.includes(sender) ? `chat--user` : `chat`}>
                    {!loginUserName?.includes(sender) && <span className="chat-bubble__avatar">
                      <p style={{ position: 'relative', top: '2px' }}>{sender?.charAt(0)}</p>
                    </span>
                    }
                    <div className="chat-bubble__message">
                      {isEditing ? (
                        <div style={{ position: 'relative' }}>
                          {/* Use ReactQuill for editing the message */}
                          <ReactQuill
                            value={updatedMessage}
                            onChange={(content) => {
                              setUpdatedMessage(content);
                            }}
                            theme="snow"
                            modules={quillModules}
                          />

                          {/* Send and Cancel Buttons */}
                          <div className="editor-buttons">
                            <button onClick={() => handleSaveEdit(index)} className="send-btn">Send</button>
                            <button
                              onClick={() => {
                                setIsEditing(false);
                                setIsEmptyMsg(false);
                              }}
                              className="cancel-btn"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        // Display the message in non-edit mode
                        <div
                          id={`message_list${index}`}
                          dangerouslySetInnerHTML={{ __html: message }}
                        />
                      )}
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <div className={!loginUserName?.includes(sender) ? `chat--user` : `chat`}>
                  {!loginUserName?.includes(sender) && <span className="chat-bubble__avatar">
                    <p style={{ position: 'relative', top: '2px' }}>{sender?.charAt(0)}</p>
                  </span>
                  }
                  <div className="chat-bubble__message">
                    {isEditing ? (
                      <div style={{ position: 'relative' }}>
                        {/* Use ReactQuill for editing the message */}
                        {isEmptyMsg && <p className="custom-error">Please type a message to continue.</p>}
                        <ReactQuill
                          value={updatedMessage}
                          onChange={(content) => {
                            setUpdatedMessage(content);
                          }}
                          theme="snow"
                          modules={quillModules}
                        />

                        {/* Send and Cancel Buttons */}
                        <div className="editor-buttons">
                          <button onClick={() => handleSaveEdit(index)} className="send-btn">Send</button>
                          <button
                            onClick={() => {
                              setIsEditing(false);
                              setIsEmptyMsg(false);
                            }}
                            className="cancel-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      // Display the message in non-edit mode
                      <div
                        id={`message_list${index}`}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}
                  </div>
                </div>
              )
            }
            {loginUserName.includes(sender) &&
              <div style={{ display: 'flex' }}>
                {msgStatus == 0 ?
                  <DoneIcon style={{ color: "#198754", fontSize: '12px' }} />
                  :
                  <DoneAllIcon style={{ color: "#198754", fontSize: '12px' }} />
                }
              </div>
            }
          </div>
        </div>
      </div>

      <Modal
        className="Mapping_modal"
        open={open}
        onClose={() => { setOpen(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: 'rgb(0 0 0 / 77%)' }}
      >
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseOutlined
              onClick={() => { setOpen(false) }}
              style={{ color: '#ffff', padding: '12px', cursor: 'pointer', zIndex: '999' }}
            />
          </div>
          <Box sx={style} style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                {imgList.length > 1 && <LeftOutlined
                  onClick={() => {
                    if (imageIndex > 0) {
                      setImageIndex(prevIndex => prevIndex - 1)
                    }
                  }}
                  style={{ color: '#ffff', fontSize: '30px', cursor: imageIndex > 0 ? 'poniter' : 'not-allowed' }} />
                }
              </Box>
              <Box >
                <div onWheel={(e) => { zoom(e) }}>
                  <img
                    style={{
                      width: '1080px',
                      transform: `scale(${scale})`,
                      transition: 'transform 0.2s ease-in-out',
                    }}
                    src={imgList[imageIndex]}
                  />
                </div>
              </Box>
              <Box>
                {imgList.length > 1 && <RightOutlined
                  onClick={() => {
                    if (imageIndex < imgList.length - 1) {
                      setImageIndex(prevIndex => prevIndex + 1)
                    }
                  }}
                  style={{ color: '#ffff', fontSize: '30px', cursor: imageIndex < imgList.length - 1 ? 'poniter' : 'not-allowed' }} />
                }
              </Box>
            </div>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default ChatBubble;
