import React, { useState, useEffect } from 'react';
import Header from '../../Header/header';
import { useNavigate } from "react-router-dom";
import { fobeAxios } from '../../middleware/interceptor';
import { Tabs } from 'antd';
import EDI_Mappingtable from './EDI_Mappingtable';
import "../../Admin/User.css";
import { ToastContainer } from "react-toastify";
const EDI_Mapping = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [Acctype, setAcctype] = useState(0);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);
  const [restData, setRestData] = useState([]);
  const [restDatas, setRestDatas] = useState([]);

  let navigate = useNavigate();

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    sessionStorage.clear();
    navigate("/");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const getRestaurantDetails = async () => {
    const response = await fobeAxios.get("api/Daily/GetRestaurants_InvoiceAccess");

    setRestDatas(response?.data?.rest_list)
    const manipulatedData = response?.data?.rest_list?.map(item => (
      { value: item?.rest_id, label: item?.company_name }
    ));
    console.log(manipulatedData, 'response');

    setRestData(manipulatedData);
  }

  useEffect(() => {
    getRestaurantDetails();
  }, []);

  const items = [
    {
      key: '1',
      label: 'SYSCO',
      children: <EDI_Mappingtable restData={restData} tabName={'Sysco'} restDatas={restDatas} setAcctype={setAcctype} Acctype={Acctype} />,
    },
    {
      key: '2',
      label: 'PFG',
      children: <EDI_Mappingtable restData={restData} tabName={'PFG'} />,
    },
    {
      key: '3',
      label: 'McLANE',
      children: <EDI_Mappingtable restData={restData} tabName={'McLane'} />,
    },
    {
      key: '4',
      label: 'Ben E. Keith',
      children: <EDI_Mappingtable restData={restData} tabName={'Benekeith'} />,
    },
    {
      key: '5',
      label: 'US Foods',
      children: <EDI_Mappingtable restData={restData} tabName={'USFoods'} />,
    },

  ];

  const detailuser = [
    <>
      <div className='content-container'>
        {/* <h5 className='sample'>EDI Mapping</h5> */}
        <div className='containerford'>
          <div className='first_conteent'>
            <div className='col-md-12'>
              <div className='row mappingTable'>
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ];

  return (
    <div>
      <ToastContainer autoClose={1500} />
      <Header
        handleMobileMenuClose={handleMobileMenuClose}
        anchorEl={anchorEl}
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleMenuClose={handleMenuClose}
        loginUserName={loginUserName}
        handleMenuCloseout={handleMenuCloseout}
        handleMobileMenuOpen={handleMobileMenuOpen}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        element={detailuser}
      />
    </div>
  )
}

export default EDI_Mapping;
