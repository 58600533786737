import React, { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import { Button, Drawer, Select, Input, Switch, Form, Tooltip, Modal } from 'antd';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CommonTable from '../CommonTable';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useEffect } from 'react';
import { fobeAxios } from '../../middleware/interceptor';
import { CaretDownOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from "react-toastify";
import { set } from 'date-fns';
import RoleAccessManagement from '../../RoleAccessManagement';
function UserManagementTable({ tabsValue }) {
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [Title, setTitle] = useState("Create User")
  const [Data, setData] = useState([]);
  const [fobedel, setfobedel] = useState(false)
  const [StaticList, setStaticList] = useState([]);
  const [FilterdList, SetFilterdList] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [newuser_RoleList, setnewuser_RoleList] = useState([]);
  const [SearchText, setSearchText] = useState("");
  const [SelectRole, setSelectRole] = useState(0);
  const [Role, setgetRole] = useState();
  const [Deletepopup, setDeletepopup] = useState(false);
  const [listDetails, setListDetails] = useState({});
  const [Modules, setModules] = useState([]);
  const [AccessKey, setAccessKey] = useState(sessionStorage.getItem("SpecialUser"));
  const [ValidateEmail, setvalidateEmail] = useState("");

  const showDrawer = () => { setOpen(true); setgetRole() };
  const onClose = () => { setOpen(false); };
  // useEffect(() => { GetFobeList(); GetRoleList(); }, []);
  useEffect(() => {
    if (tabsValue === '1') {
      GetFobeList();
      GetRoleList();
    }
  }, [tabsValue])
  // Set Module list
  const Module = [
    { value: 'Chat', label: 'Chat' },
    { value: 'Invoice Process', label: 'Invoice Process' },
    { value: 'EDI Mapping', label: 'EDI Mapping' },
    { value: 'Add HQ', label: 'Add HQ' },
    { value: 'Add Promocode', label: 'Add Promocode' },
    { value: 'Billing', label: 'Billing' },
    { value: 'Manage Customers (Full Access)', label: 'Manage Customers (Full Access)' },
  ]

  //Get the Role list
  const GetRoleList = async () => {
    await fobeAxios.post('api/Admin/getRolelist').then((res) => {
      let newUserRoleList = [];
      res.data.Role_list.map((data) => {
        newUserRoleList = [
          ...newUserRoleList,
          {
            value: data.Role_id,
            label: data.Role_name
          }
        ]
      });
      setnewuser_RoleList(newUserRoleList.filter((role) => (role.label != 'Boss' && role.label != 'Manager')));
      let tempRoleList = [{ value: 0, label: "All User" }]
      res.data.Role_list.map((data) => {
        tempRoleList = [
          ...tempRoleList,
          {
            value: data.Role_id,
            label: data.Role_name
          }
        ]
      });
      setRoleList(tempRoleList.filter((role) => (role.label != 'Boss' && role.label != 'Manager')));
    }).catch((err) => {
      console.log(err)
    });
  }
  // Get the Fobe Team user list
  const GetFobeList = async () => {
    await fobeAxios.get('api/Admin/GetFobeTeamUserList').then((res) => {
      setLoading(false);
      setStaticList([...res.data.Emails]);
    }).catch((err) => {
      console.log(err)
    });
  }

  // Filters for FobeTeam User Table

  useEffect(() => { handleRole(SelectRole) }, [StaticList]);

  useEffect(() => { handleSearch(SearchText) }, [FilterdList]);


  const handleSearch = (val) => {
    setSearchText(val);
    let searchresult = FilterdList.filter((res) => {
      return Object.values(res).some(a =>
        (typeof a === 'string' || typeof a === 'number') &&
        a.toString().toLowerCase().includes(val.toLowerCase()))
    })
    setData(searchresult);
  }
  const handleRole = (val) => {
    setSelectRole(val)
    let result = StaticList.filter((data) => { return dataSpliter(val, data) });
    SetFilterdList(result);
  }
  const dataSpliter = (role, list) => {
    let token = true;
    if (role != 0) {
      if (role != list.Role_id) {
        token = false;
      }
    }
    return token;
  }
  //create new user

  const newUser = () => {
    setTitle("Create User")
    form.setFieldsValue({
      First_name: undefined,
      Last_name: undefined,
      Email: undefined,
      password: undefined,
      confirm_password: undefined,
      phone_number: undefined,
      drive_link: undefined,
      Role: undefined,
      Default_Module: undefined
    });
    showDrawer();
  }
  // Edit User

  const setEditUser = (list) => {
    setTitle("Edit User");
    setvalidateEmail(list.Email);
    form.setFieldsValue({
      First_name: list.First_Name,
      Last_name: list.LastName,
      Email: list.Email,
      password: list.Password,
      confirm_password: list.ConfirmPassword,
      phone_number: list.ContactNo,
      drive_link: list.ScheduleLink,
      Role: list.Role_name,
      Default_Module: list.Default_Module,
      ID: list.Email_id,
      Access: list.special_user == 0 ? null : list.special_user
    });
    RoleAccess(list.Role_name, list.Role_id)
    showDrawer();
  }

  // Handle Form Validations
  const handleKeyDown = (e) => {
    // Allow only letters (A-Z and a-z)
    if (e.key.match(/[^A-Za-z\s]/) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };
  const handleKeyDownfna = (e) => {
    // Allow only letters (A-Z and a-z)
    if (e.key.match(/[^A-Za-z0-9\s]/) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  const handleKeynumDown = (e) => {
    // Allow only numbers (0-9)
    if (e.key.match(/[^0-9]/) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  // Save New user And Update User

  const onSubmit = async (val) => {
    console.log(val)
    let role = RoleList.filter((data) => { return (data.label === val.Role || data.value === val.Role) });
    let reqlist;
    if (Title == "Create User") {
      reqlist = {
        first_name: val.First_name,
        last_name: val.Last_name,
        email_id: val.Email,
        password: val.password,
        Role_name: role[0].label,
        Role_id: role[0].value,
        def_module: val.Default_Module,
        active: 1,
        ScheduleLink: val.drive_link,
        ContactNo: val.phone_number,
        AccountManager: false,
        // Access:role[0].value === 107 ? val.Access : 0
      }
    } else {
      reqlist = {
        first_name: val.First_name,
        last_name: val.Last_name,
        email_id: val.Email,
        Email_id: val.ID,
        password: val.password,
        Role_name: role[0].label,
        role_id: role[0].value,
        defaultmodule: val.Default_Module,
        ScheduleLink: val.drive_link,
        ContactNo: val.phone_number,
        AccountManager: false,
        // Access:role[0].value === 107 ? val.Access : 0
      }
    }
    console.log(reqlist);
    await fobeAxios.post(Title == "Create User" ? "api/Admin/AddFobeTeamUser" : "api/Admin/UpdateFobeTeam", reqlist).then((res) => {
      if (res.data.status == 1) {
        onClose();
        Title == "Create User" ? toast.success(res.data.message) : toast.success(res.data.message);
        GetFobeList();
      } else {
        Title == "Create User" ? toast.error(res.data.message) : toast.error(res.data.message);
      }
    }).catch((err) => {
      toast.error("User Not Added")
    });
  }

  // Delete User
  const DeleteUser = (list) => {
    setDeletepopup(true);
    setListDetails(list);
  }
  const deleteUserHandler = async (list) => {
    setDeletepopup(false);
    await fobeAxios.post("api/Admin/deleteFobeTeamUser", { Email_id: list.Email_id }).then((res) => {
      if (res.data.status == 1) {
        toast.success("User Deleted Successfully");
        GetFobeList();
      } else {
        toast.error("User Not Deleted");
      }
    }).catch((err) => {
      toast.error("User Not Deleted");
    });
  }

  //Change User Status

  const handleSubmit = () => {
    form.submit();
  };
  const ChangeStatus = async (sts, list) => {
    await fobeAxios.post("api/Admin/DeleteFobeTeam", { Email_id: list.Email_id, status: sts }).then((res) => {
      if (res.data.status == 1) {
        sts ? toast.success("Activated Successfully") : toast.success("De Activated Successfully");

        setData(prevState => prevState.map((res) =>
          res.s_no === list.s_no ? { ...res, Status: res.Status == 0 ? 1 : 0 } : res
        ));
      } else {
        toast.error("Status Not Updated");
      }
    }).catch((err) => {
      console.log(err)
    });
  }
  // rendering columns for FobeTeam user table

  const columns = [
    {
      accessorKey: "s_no",
      header: 'S.no',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      size: 85,
    },
    {
      accessorKey: "Name",
      header: 'Name',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "Email",
      header: 'Email Address',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      size: 230,
    },
    {
      accessorKey: "Role_name",
      header: 'Roles',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ row }) => {
        return <span style={{ color: row.original.color_code }}>{row.original.Role_name}</span>
      }
    },
    {
      accessorKey: "Status",
      header: 'Status',
      editable: false,
      filterVariant: 'select',
      filterSelectOptions: [
        {
          "label": 'Active',
          'value': '1'
        },
        {
          "label": 'De-Active',
          'value': '0'
        },],
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          <Switch
            size="small"
            checked={row.original.Status === 1} // Use 'row.original.Status' to access the value
            value={row.original.Status}
            disabled={RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')}
            onChange={(e) => ChangeStatus(e, row.original)}
          />
        )
      }

    },
    {
      accessorKey: 'Role_id',
      header: 'Action',
      editable: false,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          <>
            <div style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
              <Tooltip title="Edit">
                <EditIcon style={{ color: '#6A8AB9', cursor: RoleAccessManagement.hasReadAndWriteAccess('Fobe Team') ? 'not-allowed' : 'pointer' }} onClick={() => RoleAccessManagement.hasReadAndWriteAccess('Fobe Team') ? '' : setEditUser(row.original)} />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteOutlineOutlinedIcon style={{ color: '#F86764', cursor: RoleAccessManagement.hasReadAndWriteAccess('Fobe Team') ? 'not-allowed' : 'pointer' }} onClick={() => RoleAccessManagement.hasReadAndWriteAccess('Fobe Team') ? '' : (DeleteUser(row.original), setfobedel(false))} />
              </Tooltip>
            </div>
          </>
        );
      },

    },
  ];
  const RoleAccess = async (e, val) => {
    setgetRole(val.value);
    await fobeAxios.get('api/Admin/getRoleaccess').then((res) => {
      const field = res.data.Table_columns.find((data) => data.column_name === e)?.field;
      let temp = [];
      res.data.Roleaccess_list.map(a => { if (a['Associated with'] === 'Admin') a.List.map(b => { if (b[field] != 0) temp.push(b) }) }).filter(Boolean);
      const moduleList = temp.map((data) => ({ value: data.Role, label: data.Role }))

      if (moduleList.length > 0) {
        setModules(moduleList)
      }
      else {
        form.setFieldsValue({
          Default_Module: undefined,
        });
        setModules([])
      }
    })
  }
  // ------------------------ //

  const usermanageFilter = [
    <div className='rowbutsearch'>
      <div className='col1'>
        <div className='dropbutton'>

          <Select
            showSearch
            style={{
              width: 150,
            }}
            placeholder="All User"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
            defaultValue={SelectRole}
            options={RoleList}
            onChange={(e) => { handleRole(e) }}
            suffixIcon={<CaretDownOutlined />}
          />
        </div>
      </div>
      <div className='col1'>
        <div className='forbuttonon'>
          <Button type="primary" disabled={RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')} onClick={newUser}>New User</Button>
        </div>
      </div>
      {/* <div className='col1'>
      <div className='inputrow'>
        <Input placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
          value={SearchText || ""} />
      </div>
    </div> */}
    </div>
  ]

  return (
    <>
      <Box>
        <div className='useertable'>
          <CommonTable
            rows={Data}
            columns={columns}
            table={"user"}
            isLoading={loading}
            HiddenColumnlist={{}}
            headFilter={usermanageFilter}
            fobedel={fobedel}
            setfobedel={setfobedel}
          />
        </div>
        <div className='newuser_drawer'>
          <Drawer
            title={Title}
            width={700}
            onClose={onClose}
            open={open}
            closeIcon={<KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />}
            footer={  // Add the footer section
              <div className='validbttons'>



                <Button type="primary" onClick={handleSubmit}>
                  Save
                </Button>


                <Button style={{
                  backgroundColor: 'rgb(239 239 239)',
                  color: '#000',
                  borderRadius: '3px',
                  border: '0.5px solid #b2b2b2',
                }} htmlType="button" onClick={() => { onClose(); if (Title == "Create User") form.resetFields() }}>Close</Button>
              </div>
            }
          >
            <Form className='formvert' layout="vertical" onFinish={onSubmit} form={form} >
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Item
                      label="First Name"
                      name="First_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name."
                        }
                      ]}
                    >
                      <Input
                        className='itemoneform'
                        placeholder="Enter your First Name"
                        style={{ height: '40px' }}
                        onKeyDown={handleKeyDown}
                      />
                    </Form.Item>
                  </div>
                  <div className='col-md-6'>
                    <Form.Item
                      label="Last Name"
                      name="Last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your last name."
                        }
                      ]}
                    >
                      <Input
                        className='itemoneform'
                        placeholder="Enter your Last Name"
                        style={{ height: '40px' }}
                        onKeyDown={handleKeyDown}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row' style={{ paddingTop: '3px' }}>
                  <div className='col-md-6'>
                    <Form.Item
                      label="Email Address"
                      name="Email"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a valid email address."
                        },
                        {
                          type: 'email',
                          message: "Enter valid email"
                        },
                        ({ getFieldValue }) => ({
                          async validator(_, value) {
                            if (Title === "Edit User" ? value !== ValidateEmail : value) {
                              try {
                                const res = await fobeAxios.get('/Api/User/CheckEmailValid/' + value);
                                if (res.data === 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(new Error('Email already exists.'));
                                }
                              } catch (err) {
                                return Promise.reject(new Error('Error checking email.'));
                              }
                            } else {
                              return Promise.resolve(); // When the value is empty, the required rule will handle it.
                            }
                          }
                        }),
                      ]}
                    >
                      <Input className='itemoneform' placeholder="Enter your Email" style={{ height: '40px' }} />
                    </Form.Item>
                  </div>
                  <div className='col-md-6'>
                    <Form.Item
                      label="Phone Number"
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a valid phone number."
                        },
                        {
                          min: 10,
                          message: 'Invalid Phone number'
                        },
                      ]}
                    >
                      <Input
                        className='itemoneform'
                        placeholder="Enter Phone number"
                        style={{ height: '40px' }}
                        maxLength={10}
                        onKeyDown={handleKeynumDown}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row' style={{ paddingTop: '3px' }}>
                  <div className='col-md-6'>
                    <Form.Item
                      label="Password"
                      name="password"
                      autoComplete="new-password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a password."
                        }
                      ]}
                    >
                      <Input.Password
                        className='itemoneform'
                        placeholder="Enter Password"
                        style={{ height: '40px' }}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </div>
                  <div className='col-md-6'>
                    <Form.Item
                      label="Confirm Password"
                      name="confirm_password"
                      dependencies={['password']}
                      autoComplete="new-password"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password."
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Passwords do not match'));
                          }
                        }),
                      ]}
                    >
                      <Input.Password
                        className='itemoneform'
                        placeholder="Enter Confirm Password"
                        style={{ height: '40px' }}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row' style={{ paddingTop: '3px' }}>
                  <div className='col-md-6'>
                    <Form.Item
                      label="Role"
                      name="Role"
                      rules={[
                        {
                          required: true,
                          message: "Please choose a role."
                        }
                      ]}
                    >
                      <Select className='formroles' showSearch style={{ width: '100%', }}
                        placeholder="Choose Role"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                        options={newuser_RoleList}
                        onChange={(value, option) => {
                          RoleAccess(option.label, option);
                          form.setFieldsValue({ Default_Module: '', Access: option.value === 107 ? form.getFieldValue('Access') : '' }
                          )
                        }}
                      />
                    </Form.Item>
                  </div>
                  {/* {(Role === 107 || form.getFieldValue('Access') === 999 || form.getFieldValue('Access') === 99) && <div className='col-md-6'>
                  <Form.Item
                    label="Access"
                    name="Access"
                    rules={[
                      {
                        required:(Role === 107 || form.getFieldValue('Access') === 999 || form.getFieldValue('Access') === 99) ? true : false,
                        message: "Please choose a role."
                      }
                    ]}
                  >
                    <Select className='formroles' showSearch style={{ width: '100%', }}
                      placeholder="Choose Role"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                      options={
                        [
                          {label:'View Only' ,value:999},
                          {label:'Full Access',value:99}
                        ]
                      }
                      // onChange={(value, option) => RoleAccess(option.label)}
                    />
                  </Form.Item>
                </div>} */}
                  <div className='col-md-6'>
                    <Form.Item
                      label="Default Module"
                      name="Default_Module"
                      rules={[
                        {
                          required: true,
                          message: "Please choose a default module."
                        }
                      ]}
                    >
                      <Select showSearch className='formroles' placeholder="Choose Module" style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={Modules}
                      />

                    </Form.Item>
                  </div>
                  <div className='col-md-6'>
                    <Form.Item
                      label="Pipe Drive Link"
                      name="drive_link"
                    >
                      <Input className='itemoneform' placeholder="Enter pipe Drive Link" style={{ height: '40px' }} />
                    </Form.Item>
                  </div>
                </div>
                {/* <div className='row' style={{ paddingTop: '3px' }}>

              </div> */}
                <Form.Item name="ID"></Form.Item>
              </div>
            </Form>
          </Drawer>
        </div>
      </Box>
      <Modal
        title="Delete User"
        open={Deletepopup}
        onCancel={() => { setDeletepopup(false) }}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              onClick={() => { setDeletepopup(false) }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: '#19A73F',
                color: '#fff',
                borderRadius: '3px',
                border: 'none'
              }}
              onClick={() => deleteUserHandler(listDetails)}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p style={{ fontSize: '15px', textAlign: 'center', padding: '10px 20px 20px 20px' }}>Are you sure you want to delete the user?</p>
      </Modal>
    </>
  );
}

export default UserManagementTable;
