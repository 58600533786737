import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer, Form } from "antd";
import CommonTable from "../Admin/CommonTable";
import "./Pos.css";
import { Switch, Input, Tooltip, Select, Modal } from "antd";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { fobeAxios } from "../middleware/interceptor";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import axios from "axios";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { toast, ToastContainer } from "react-toastify";
import Header from "../Header/header";
import ChatTranscript from "../components/chat/ChatTranscript";
import { Grid, Stack, TextField } from "@mui/material";
import InstructionModal from "./InstructionModal";
import RoleAccessManagement from "../RoleAccessManagement";
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

function Pos() {
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();
  const [PosCredentials] = Form.useForm();

  const [instructionModalOpen, setInstructionModalOpen] = React.useState(false);
  const [messages, setMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const [RestDetailsForNotes, setRestDetailsForNotes] = useState({});
  const [loading, setLoading] = useState(true);
  const [deptidoption, setDeptidoption] = useState([]);
  const [AccessKey, setAccessKey] = useState(
    sessionStorage.getItem("SpecialUser")
  );
  const [dropdown5Value, setDropdown5Value] = useState(0);
  const [deleted, setdeleted] = useState(false);
  const [showdelpop, setshowdelpop] = useState(false);
  const [rowval, setrowval] = useState({});
  const [title, settitle] = useState("Add Restaurant");
  const [accountType, setAccountType] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [globalDataList, setGlobalDataList] = useState([]);
  const [fobeGolfOptions, setFobeGolfOptions] = useState([]);
  const [statusValue, setStatusValue] = useState(1);
  const [messagekey, setmessagekey] = useState('add');
  const [selectedOption, setSelectedOption] = useState([]);
  const [message_id, setmessage_id] = useState(0);
  const [addUser, setAddUser] = useState(false);

  const handleInstructionClose = () => setInstructionModalOpen(false);

  const onClose = () => {
    setOpen(false);
    setNotesOpen(false);
    setAddUser(false);
  };

  const openInstructionModal = (list) => {
    setInstructionModalOpen(true);
  };

  const showDrawer = () => {
    form.resetFields();
    setOpen(true);
    setAddUser(true);
    settitle("Add Restaurant");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    sessionStorage.clear();
    navigate("/");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const GetPOSRestaurants = async () => {
    await fobeAxios.get("Api/Daily/GetRestaurants").then((res) => {
      setSelectedOption(
        res.data.rest_list.map((value) => ({
          label: value.company_name,
          value: `${value.company_name}_${value.rest_id}`,
          id: value.acc_id

        }))
      );

    });
  };

  const GetdataList = async () => {
    try {
      await fobeAxios
        .get("Api/Daily/Get_pos_restaurantsnew")
        .then((resp) => {
          setDataList(resp.data.rest_list.map((item) => {
            return item.coursename != "" ? { ...item, company_name: item.coursename + " / " + item.company_name, last_updated: item.last_updated ? moment(item.last_updated).format('MM/DD/YYYY hh:mm A') : '-' }
              : { ...item, last_updated: item.last_updated ? moment(item.last_updated).format('MM/DD/YYYY hh:mm A') : '-' }
          }));
          setGlobalDataList(resp.data.rest_list.map((item) => {
            return item.coursename != "" ? { ...item, company_name: item.coursename + " / " + item.company_name, last_updated: item.last_updated ? moment(item.last_updated).format('MM/DD/YYYY hh:mm A') : '-' }
              : { ...item, last_updated: item.last_updated ? moment(item.last_updated).format('MM/DD/YYYY hh:mm A') : '-' }
          }));
          if (resp.data.rest_list) {
            setLoading(false);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleAccountTypeChange = async (value) => {
    setAccountType(value)
    if (value === 5) {
      await fobeAxios
        .get("/Api/Daily/GetfobeRestaurants")
        .then((resp) => {
          setFobeGolfOptions(
            resp.data.rest_list.map((values) => ({
              label: values.company_name,
              value: `${values.company_name}_${values.rest_id}`,
            }))
          );
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const handleCourseDropdown = async (dropVal) => {
    const rest_id = Number(dropVal.split('_')[1]);
    let datatosend = { Rest_id: rest_id };

    try {
      await fobeAxios.post("Api/Daily/Getfobedepts", datatosend).then((res) => {
        const deptoption = res.data.rest_list.map((value) => {
          return {
            value: `${value.acc_name}_${value.rest_id}`,
            label: value.acc_name,
          };
        });
        setDeptidoption(deptoption);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleChange = async (val, key) => {
    const datatosend = {
      id: val.id,
      rest_id: val.rest_id,
      status: val[key] == 1 ? 0 : 1,
      key: key == 'sales' ? 'sale' : key
    };
    await fobeAxios.post("api/Daily/Update_posaccount_status",
      datatosend
    ).then((res) => {
      if (res.data.status == 1) {
        toast.success("Updated Successfully");
        GetdataList();
      } else {
        toast.error("Updated Failed");
      }
    }).catch((err) => {
      console.error("Error updating status:", err);
    });
  };

  const handleSubmit = () => {
    form.submit();
  };

  const Edit = async (params) => {
    settitle("Edit Restaurant");
    setAddUser(false);
    if (params.acctype == 5) {
      setAccountType(params.acctype);
      form.setFieldsValue({
        id: params.id,
        rest_id: params.rest_id,
        AccountType: params.acctype == 1 ? "Fobesoft" : params.acctype == 5 ? "Fobegolf" : params.acctype == 3 ? "Sysco" : '',
        department: params.company_name,
        Course: params.coursename,
        PosName: params.pos_name,
        Report: [
          params?.sales == 1 && "sales",
          params?.labor == 1 && "labor",
          params?.invoice == 1 && "invoice"
        ].filter(Boolean),
      });
    } else {
      setAccountType(params.acctype);
      form.setFieldsValue({
        id: params.id,
        rest_id: params.rest_id,
        AccountType: params.acctype == 1 ? "Fobesoft" : params.acctype == 5 ? "Fobegolf" : params.acctype == 3 ? "Sysco" : '',
        restaurant: params.company_name,
        PosName: params.pos_name,
        Report: [
          params?.sales == 1 && "sales",
          params?.labor == 1 && "labor",
          params?.invoice == 1 && "invoice"
        ].filter(Boolean),
      });
    }

    setOpen(true);
  };

  const GetNotes = async (params) => {
    setNotesOpen(true);
    settitle("Notes");
    setRestDetailsForNotes(params);
    PosCredentials.resetFields();
    PosCredentials.setFieldsValue({
      Id: params?.id,
      Rest_id: params?.rest_id,
      Username: params?.username,
      Password: params?.Password,
      Site_url: params?.Site_url
    })
    setMessages([]);
    await fobeAxios.get("Api/Daily/Get_posnotes/" + params.rest_id + "/" + params.id).then((res) => {
      setMessages(res.data["Notes list"].reverse().map((msg) => { return { ...msg, msgStatus: 1, id: msg.Msgid } }));
    }).catch((err) => {
      console.log(err)
    })
  };

  const PostNotes = async (val) => {
    let req = {
      rest_id: RestDetailsForNotes.rest_id,
      rest_name: RestDetailsForNotes.company_name,
      id: RestDetailsForNotes.id,
      pos_name: RestDetailsForNotes.pos_name,
      notes: val.text,
      notes_id: val.id,
      key: val.key,
    }
    await fobeAxios.post("Api/Daily/posnotes", req).then((res) => {
      if (res.data.status == 1) {
        // toast.success("Notes Updated Successfully!");
        GetNotes(RestDetailsForNotes)
      } else {
        // toast.error("Notes Not Updated");
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  const Delete = async (params) => {
    setshowdelpop(true);
    setrowval(params);
  };

  const del = async (params) => {
    let det = { ...params, key: "delete" }
    await fobeAxios.post('Api/Daily/Update_posaccount_status', det).then((res) => {
      if (res.data.status == 1) {
        setshowdelpop(false)
        toast.success("Deleted Successfully")
        GetdataList()
      } else {
        toast.error("Failed to Delete")
      }
    }).catch((err) => {
      console.log(err)
    })


  }

  const compare = (data, status, acctype) => {
    console.log(data, status, acctype)
    let token = true;
    if (status != 2) {
      if (status != data.active) {
        token = false;
      }
    }
    if (acctype != 0) {
      if (acctype != data.acctype) {
        token = false;
      }
    }
    return token;
  };

  const handleDropdownChange = (value) => {
    setDropdown5Value(value);
    const temp = globalDataList.filter((item) => compare(item, statusValue, value));
    console.log(temp);
    setDataList(temp);
  };

  const handleStatusChange = (value) => {
    setStatusValue(value);
    const temp = globalDataList.filter((item) => compare(item, value, dropdown5Value));
    console.log(temp);
    setDataList(temp);
  };

  // const compare = (data, status, acctype) => {
  //   let token = true;
  //   if (status != 0) {
  //     if (status != data.active) {
  //       token = false;
  //     }
  //   }
  //   if (acctype != 2) {
  //     if (acctype != data.acctype) {
  //       token = false;
  //     }
  //   }
  //   return token;
  // }
  // const handleDropdownChange = (value) => {
  //   setDropdown5Value(value);
  //   let temp = globalDataList.filter((a) => {
  //     return compare(a, statusValue, value);
  //   })
  //   setDataList(temp);
  // };
  // const handleStatusChange = (value) => {
  //   setStatusValue(value)
  //   let temp = globalDataList.filter((a) => {
  //     return compare(a, value, dropdown5Value);
  //   })
  //   setDataList(temp);
  // }

  const handlestatus = async (e, i) => {
    let rest_id = i;
    let value = e;
    const datatosend = {
      rest_id: rest_id.toString(),
      status: e
    };
    await fobeAxios
      .post(
        "api/Daily/Update_posworking_status",
        datatosend
      )
      .then((res) => {
        if (res.data.status == 1) {
          toast.success("Updated Successfully");
          GetdataList();
        } else {
          toast.error("Updated Failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onsubmit = async (val) => {
    let req;
    if (title.includes('Add')) {
      req = val.AccountType == 5 ?
        {
          rest_id: Number(val.department.split('_')[1]),
          rest_name: val.department.split('_')[0],
          pos_name: val.PosName,
          sales: val.Report.includes("sales") ? 1 : 0,
          labor: val.Report.includes("labor") ? 1 : 0,
          invoice: val.Report.includes("invoice") ? 1 : 0,
          AccountType: val.AccountType,
          course_name: val.Course.split('_')[0],
          id: 0
        }
        :
        {
          rest_id: Number(val.restaurant.split('_')[1]),
          rest_name: val.restaurant.split('_')[0],
          pos_name: val.PosName,
          sales: val.Report.includes("sales") ? 1 : 0,
          labor: val.Report.includes("labor") ? 1 : 0,
          invoice: val.Report.includes("invoice") ? 1 : 0,
          AccountType: val.AccountType,
          id: 0,
        }
    } else {
      req = val.AccountType == 5 ?
        {
          rest_id: val.rest_id,
          rest_name: val.department,
          pos_name: val.PosName,
          sales: val.Report.includes("sales") ? 1 : 0,
          labor: val.Report.includes("labor") ? 1 : 0,
          invoice: val.Report.includes("invoice") ? 1 : 0,
          AccountType: val.AccountType == "Fobesoft" ? 1 : val.AccountType == "Fobegolf" ? 5 : val.AccountType == "Sysco" ? 3 : 0,
          id: val.id,
          course_name: val.Course.split('_')[0]
        }
        :
        {
          rest_id: val.rest_id,
          rest_name: val.restaurant,
          pos_name: val.PosName,
          sales: val.Report.includes("sales") ? 1 : 0,
          labor: val.Report.includes("labor") ? 1 : 0,
          invoice: val.Report.includes("invoice") ? 1 : 0,
          AccountType: val.AccountType == "Fobesoft" ? 1 : val.AccountType == "Fobegolf" ? 5 : val.AccountType == "Sysco" ? 3 : 0,
          id: val.id,
        }
    }
    console.log(req)
    await fobeAxios.post("Api/Daily/Add_posaccount", req).then((res) => {
      if (res.data.status == 1) {
        GetdataList()
        onClose()
        setAddUser(false);
        toast.success(`Restaurant ${title.includes('Add') ? 'added' : 'updated'} successfully!`);
      } else {
        toast.error("Failed to update Restaurant")
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const savePosCredentials = async (req) => {
    fobeAxios.post("Api/Daily/updateposaccountscredentials", req).then((res) => {
      if (res.data.Status == 1) {
        toast.success("Credentials updated successfully!");
        GetdataList();
      } else {
        toast.warning("Failed to update");
      }
    }).catch((err) => console.log(err));
  }

  const columns = useMemo(() => [
    {
      accessorKey: "company_name",
      header: "Restaurant Name",
      enableColumnPinning: true,
      editable: false,
      size: 200,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },

    },
    {
      accessorKey: "pos_name",
      header: "POS Name",
      editable: false,
      grow: false,
      size: 110,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "sales",
      header: "Sales",
      editable: false,
      size: 80,
      filterVariant: 'select',
      filterSelectOptions: [
        { label: 'Active', value: '1' },
        { label: 'De-Active', value: '0' },
      ],
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => {
        return (
          <>
            <Switch
              placeholder="Search to Select"
              optionfilterprop="children"
              defaultValue={row.original.sales}
              id="checkbox"
              disabled={RoleAccessManagement.hasReadAndWriteAccess('Point of Sale')}
              checked={row.original.sales == 1 ? true : false}
              onChange={(e) => handleToggleChange(row.original, "sales")}
              size="small"
            />
          </>
        );
      },
    },
    {
      accessorKey: "labor",
      header: "Labor",
      editable: false,
      size: 80,
      filterVariant: 'select',
      filterSelectOptions: [
        { label: 'Active', value: '1' },
        { label: 'De-Active', value: '0' },
      ],
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => {
        return (
          <>
            <Switch
              placeholder="Search to Select"
              optionfilterprop="children"
              defaultValue={row.original.labor}
              id="checkbox"
              disabled={RoleAccessManagement.hasReadAndWriteAccess('Point of Sale')}
              checked={row.original.labor == 1 ? true : false}
              onChange={(e) => handleToggleChange(row.original, "labor")}
              size="small"
            />
          </>
        );
      },
    },
    {
      accessorKey: "invoice",
      header: "Invoice",
      editable: false,
      size: 80,
      filterVariant: 'select',
      filterSelectOptions: [
        { label: 'Active', value: '1' },
        { label: 'De-Active', value: '0' },
      ],
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => {
        return (
          <>
            <Switch
              placeholder="Search to Select"
              optionfilterprop="children"
              defaultValue={row.original.invoice}
              id="checkbox"
              disabled={RoleAccessManagement.hasReadAndWriteAccess('Point of Sale')}
              checked={row.original.invoice == 1 ? true : false}
              onChange={(e) => handleToggleChange(row.original, "invoice")}
              size="small"
            />
          </>
        );
      },
    },
    {
      accessorKey: "active",
      header: "Status",
      editable: false,
      size: 80,
      filterVariant: 'select',
      filterSelectOptions: [
        { label: 'Active', value: '1' },
        { label: 'De-Active', value: '0' },
      ],
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => {
        return (
          <>
            <Switch
              placeholder="Search to Select"
              optionfilterprop="children"
              defaultValue={row.original.active}
              id="checkbox"
              disabled={RoleAccessManagement.hasReadAndWriteAccess('Point of Sale')}
              checked={row.original.active == 1 ? true : false}
              onChange={(e) => handleToggleChange(row.original, "active")}
              size="small"
            />
          </>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Current Status",
      editable: false,
      size: 80,
      filterVariant: 'select',
      filterSelectOptions: [
        { label: 'All', value: '' },
        { label: 'Yet to Start', value: '0' },
        { label: 'Processing', value: '1' },
        { label: 'Completed', value: '2' },
      ],
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ row }) => {
        return (
          <>
            <Select
              value={row.original.status}
              onChange={(e) => handlestatus(e, row.original.rest_id)}
              disabled={RoleAccessManagement.hasReadAndWriteAccess('Point of Sale')}
              size="small"
              style={{ width: "120px", fontSize: '12px', fontWeight: '400' }}
              options={[
                { label: 'Yet to Start', value: 0 },
                { label: 'Processing', value: 1 },
                { label: 'Completed', value: 2 },
              ]}

            />
          </>
        );
      },
    },
    {
      accessorKey: "last_updated",
      header: "Last Completed",
      editable: false,
      enableGrouping: true,
      size: 80,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      // Cell: ({ row }) => {
      //   return (
      //     <>
      //       {}
      //     </>
      //   );
      // },
    },
    {
      accessorKey: "rest_id",
      header: "Action",
      editable: false,
      enableColumnFilter: false,
      enableColumnPinning: true,
      size: 80,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ row }) => {
        return (
          <div
            style={{ display: "flex", gap: "15px", justifyContent: "center" }}
          >
            <Tooltip title="Edit">
              <EditNoteIcon
                style={{
                  color: "rgb(24, 49, 83)",
                  cursor: RoleAccessManagement.hasReadAndWriteAccess('Point of Sale') ? "not-allowed" : "pointer",
                }}
                onClick={() => (RoleAccessManagement.hasReadAndWriteAccess('Point of Sale') ? "" : Edit(row.original))}
              />
            </Tooltip>
            <Tooltip title="Notes">
              <QuestionAnswerIcon
                style={{
                  color: "#6c757dcf",
                  cursor: RoleAccessManagement.hasReadAndWriteAccess('Point of Sale') ? "not-allowed" : "pointer",
                }}
                onClick={() =>
                  RoleAccessManagement.hasReadAndWriteAccess('Point of Sale') ? "" : (GetNotes(row.original))
                }
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon
                style={{
                  color: "#F86764",
                  cursor: RoleAccessManagement.hasReadAndWriteAccess('Point of Sale') ? "not-allowed" : "pointer",
                }}
                onClick={() =>
                  RoleAccessManagement.hasReadAndWriteAccess('Point of Sale')
                    ? ""
                    : (Delete(row.original, "delete"), setdeleted(true))
                }
              />
            </Tooltip>
          </div>
        );
      },
    },
  ], [dataList]);

  const AddUser = [
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <div><span style={{ fontSize: '14px', color: '#808080' }}>Account Type:</span></div>
        <div> <Select
          size="medium"
          style={{ width: "118px" }}
          value={dropdown5Value}
          onChange={(e) => handleDropdownChange(e)}
          options={[
            { label: "All", value: 0 },
            { label: "FobeSoft", value: 1 },
            { label: "FobeGolf", value: 5 },
            { label: "Sysco", value: 3 },
          ]}
        /></div>

      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <div><span style={{ fontSize: '14px', color: '#808080' }}>Account Status:</span></div>
        <div>
          <Select
            size="medium"
            style={{ width: "118px" }}
            value={statusValue}
            onChange={(e) => handleStatusChange(e)}
            options={[
              { label: "All", value: 2 },
              { label: "Active", value: 1 },
              { label: "De Active", value: 0 }
            ]}
          />
        </div>
      </div>
    </>
  ];

  const element = [
    <>
      <div className="content-container">
        <div className="containerford" style={{ marginTop: "10px" }}>
          <div className="forbuttonon" style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              type="primary"
              disabled={RoleAccessManagement.hasReadAndWriteAccess('Point of Sale')}
              onClick={showDrawer}
            >
              Add Restaurant
            </Button>

          </div>
          <div className="first_conteent" style={{ marginTop: "4em" }}>
            <CommonTable
              rows={dataList}
              columns={columns}
              table={"Pos"}
              isLoading={loading}
              HiddenColumnlist={{}}
              headFilter={AddUser}
              deleted={deleted}
              setdeleted={setdeleted}
            />
          </div>
        </div>
      </div>
    </>,
  ];

  useEffect(() => {
    handleDropdownChange(dropdown5Value)
  }, [globalDataList]);

  useEffect(() => {
    if (location.pathname === "/pos") {
      setInstructionModalOpen(false);
    }
    else {
      setInstructionModalOpen(true);
    }
  }, [location]);

  useEffect(() => {
    GetPOSRestaurants();
    GetdataList();
  }, []);

  return (
    <>
      {!instructionModalOpen && <>
        <ToastContainer autoClose={1500} />
        <Header
          handleMobileMenuClose={handleMobileMenuClose}
          anchorEl={anchorEl}
          handleProfileMenuOpen={handleProfileMenuOpen}
          handleMenuClose={handleMenuClose}
          loginUserName={loginUserName}
          handleMenuCloseout={handleMenuCloseout}
          handleMobileMenuOpen={handleMobileMenuOpen}
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          element={element}
        />
        <Drawer
          title={title}
          width={400}
          onClose={onClose}
          open={open}
          closeIcon={
            <KeyboardArrowRightOutlinedIcon style={{ color: "#19A73F" }} />
          }
          footer={
            <div
              className="drawer-btn"
              style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
            >
              <Button
                style={{
                  backgroundColor: "rgb(239 239 239)",
                  color: "#000",
                  borderRadius: "3px",
                  border: "0.5px solid #b2b2b2",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "#19A73F" }}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          }
        >
          <div>
            <Form layout="vertical" onFinish={onsubmit} form={form} >
              <div className="col-md-12">
                <div className="row">

                  {title.includes('Add') ?
                    (
                      <Form.Item
                        label="Account Type"
                        name="AccountType"
                        rules={[{ required: true, message: "Account type is required" }]}
                      >
                        <Select
                          showSearch
                          size="large"
                          placeholder="Select account type"
                          style={{ width: "100%" }}
                          optionfilterprop="children"
                          options={[
                            {
                              value: 1,
                              label: "Fobesoft",
                            },
                            {
                              value: 5,
                              label: "Fobegolf",
                            },
                            {
                              value: 3,
                              label: "Sysco",
                            },
                          ]}
                          onChange={handleAccountTypeChange}
                        />
                      </Form.Item>
                    )
                    :
                    (
                      <Form.Item
                        label="Account Type"
                        name="AccountType"
                        rules={[{ required: true, message: "Account type is required" }]}
                      >
                        <Input
                          disabled
                          size="large"
                        />
                      </Form.Item>
                    )
                  }

                </div>

                {accountType === 5 &&
                  <div className="row">

                    {title.includes('Add') ?
                      (<Form.Item
                        label="Golf Course"
                        name="Course"
                        rules={[
                          { required: true, message: "Course is required" },
                        ]}
                      >
                        <Select
                          showSearch
                          size="large"
                          placeholder="Select Course"
                          optionfilterprop="children"
                          options={fobeGolfOptions}
                          onChange={handleCourseDropdown}
                        />
                      </Form.Item>
                      )
                      :
                      (
                        <Form.Item
                          label="Golf Course"
                          name="Course"
                          rules={[
                            { required: true, message: "Course is required" },
                          ]}
                        >
                          <Input
                            disabled
                            size="large"
                          />
                        </Form.Item>
                      )
                    }

                  </div>
                }

                <div className="row">

                  {title.includes('Add') ?
                    (
                      <>
                        {accountType === 5 ?
                          (
                            <Form.Item
                              label="Department"
                              name="department"
                              rules={[
                                { required: true, message: 'Department is required' },
                              ]}>
                              <Select
                                showSearch
                                size="large"
                                placeholder="Select department"
                                style={{ width: "100%" }}
                                optionfilterprop="children"
                                options={deptidoption}
                              />
                            </Form.Item>
                          )
                          :
                          (
                            <Form.Item
                              label='Restaurant'
                              name="restaurant"
                              rules={[
                                { required: true, message: 'Restaurant is required' },
                              ]}>
                              <Select
                                showSearch
                                size="large"
                                placeholder="Select restaurant"
                                style={{ width: "100%" }}
                                optionfilterprop="children"
                                options={selectedOption.filter(a => a.id == accountType)}
                              />
                            </Form.Item>
                          )
                        }
                      </>

                    )
                    :
                    (
                      <Form.Item
                        label={accountType === 5 ? 'Department' : "Restaurant"}
                        name={`${accountType === 5 ? 'department' : 'restaurant'}`}
                        rules={[
                          { required: true, message: `${accountType === 5 ? 'Department' : 'Restaurant'} is required` },
                        ]}>
                        <Input
                          disabled
                          size="large"
                        />
                      </Form.Item>
                    )
                  }

                </div>

                <div className="row">
                  <Form.Item
                    label="POS Name"
                    name="PosName"
                    rules={[
                      { required: true, message: "POS name is required" },
                    ]}>
                    <Input
                      placeholder="Enter POS name"
                      size="large"
                    />
                  </Form.Item>
                </div>

                <div className="row">
                  <Form.Item
                    label="Report"
                    name="Report"
                    rules={[{ required: true, message: "Report is required" }]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select report"
                      size="large"
                      optionfilterprop="children"
                      options={[
                        {
                          value: "sales",
                          label: "Sales",
                        },
                        {
                          value: "labor",
                          label: "Labor",
                        },
                        {
                          value: "invoice",
                          label: "Invoice",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item name="id"></Form.Item>
              <Form.Item name="rest_id"></Form.Item>
            </Form>
          </div>
        </Drawer >
        <Drawer
          title={<>
            <span style={{ color: '#19A73F' }}>{RestDetailsForNotes.company_name}</span>
          </>}
          width={600}
          onClose={onClose}
          open={notesOpen}
          closeIcon={
            <KeyboardArrowRightOutlinedIcon style={{ color: "#19A73F" }} />
          }
          footer={
            <>
              <Form>
                <Form.Item style={{ marginBottom: '0px' }} name="notes">
                  <Button
                    variant="outlined"
                    color="success"
                    style={{ borderColor: "#19A73F", color: "#19A73F" }}
                    onClick={() => {
                      openInstructionModal(RestDetailsForNotes);
                      navigate("/pos/mappinginstructions", { state: RestDetailsForNotes })
                    }}
                  >
                    View All/Create Instruction
                  </Button>
                </Form.Item>
              </Form>
            </>
          }
        >
          <Stack direction="column" gap={4}>
            <Form layout="vertical" form={PosCredentials} onFinish={savePosCredentials} autoComplete="off">
              <Grid container spacing={2} columns={12}>
                <Grid item xs={12}>
                  <Form.Item
                    name="Site_url"
                    label="Site Url"
                    rules={[{ required: true, message: "Site Url is required" }]}
                  >
                    <Input
                      size="large"
                    />
                  </Form.Item>
                </Grid>
                <Grid item xs={6}>
                  <Form.Item
                    name="Username"
                    label="Username"
                    autoComplete="off"
                    rules={[{ required: true, message: "Username is required" }]}
                  >
                    <Input
                      size="large"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Grid>
                <Grid item xs={6}>
                  <Form.Item
                    name="Password"
                    label="Password"
                    autoComplete="new-password"
                    rules={[{ required: true, message: "Password is required" }]}
                  >
                    <Input.Password
                      size="large"
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </Grid>
                <Form.Item name="Id" />
                <Form.Item name="Rest_id" />
                <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button type="primary" htmlType="submit"
                    style={{ backgroundColor: "#19A73F" }}
                  >Save</Button>
                </Grid>
              </Grid>
            </Form>
            <h3 style={{ fontSize: '18px', fontWeight: '500' }}>Recent Instructions</h3>
            <ChatTranscript messages={messages} messageLimit={4} setmessage_id={setmessage_id} setmessagekey={setmessagekey} message_id={message_id} isEditable={false} />
          </Stack>
        </Drawer>
        <Modal
          title="Delete Restaurant"
          open={showdelpop}
          onCancel={() => {
            setshowdelpop(false);
          }}
          footer={[
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                style={{
                  backgroundColor: "rgb(239 239 239)",
                  color: "#000",
                  borderRadius: "3px",
                  border: "0.5px solid #b2b2b2",
                }}
                onClick={() => {
                  setshowdelpop(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{
                  backgroundColor: "#19A73F",
                  color: "#fff",
                  borderRadius: "3px",
                  border: "none",
                }}
                onClick={() => del(rowval)}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p
            style={{
              fontSize: "15px",
              textAlign: "center",
              padding: "10px 20px 20px 20px",
            }}
          >
            Are you sure you want to delete this Restaurant?
          </p>
        </Modal>
      </>
      }
      {
        instructionModalOpen &&
        <>
          <InstructionModal
            open={instructionModalOpen}
            onClose={handleInstructionClose}
            messages={messages}
            onSend={PostNotes}
            setmessagekey={setmessagekey}
            setmessage_id={setmessage_id}
            message_id={message_id}
          />
        </>
      }
    </>
  );
}

export default Pos;
