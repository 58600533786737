import React, { useEffect, useMemo, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CommonTable from '../CommonTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Drawer, Input, Table, Switch, Form, Tooltip, Modal, Select, Radio, Space } from 'antd';
import { fobeAxios } from '../../middleware/interceptor';
import { ToastContainer, toast } from "react-toastify";
import { PlusCircleOutlined } from '@ant-design/icons';
import RoleAccessManagement from '../../RoleAccessManagement';

function RoleAccess() {
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState('');
  const [open, setOpen] = useState(false);
  const [RoleData, setRoleData] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [ColumnList, setColumnList] = useState([]);
  const [NewRoleSaveKey, setNewRoleSaveKey] = useState('')
  const [deletePopup, setDeletePopup] = useState(false);
  const [listDetails, setListDetails] = useState({});
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [AccessKey, setAccessKey] = useState(sessionStorage.getItem("SpecialUser"));
  const [hasPersmissionModal, setPersmissionModal] = useState(false);
  const [defaultModuleModal, setDefaultModuleModal] = useState(false);
  const [updateMaduleList, setUpdateMaduleList] = useState([]);
  const [permissions, setPermissions] = useState(1);
  const [filterValue, setFilterValue] = useState(1);
  const [defaultModuleOptions, setDefaultModuleOptions] = useState([]);
  const [selectedDefaultModule, setSelectedDefaultModule] = useState(null);

  const filterOptions = [{ label: 'Fobe Team', value: 1 }, { label: 'Customer', value: 2 }];
  const disabledOptions = [
    "Annual",
    "Month",
    "Constant Recurring",
    "Lock",
    "Revenue",
    "Invoice",
    "Cost of Goods",
    "Labor",
    "Controllable",
    "Non Controllable",
    "P&L"
  ];
  const hasCustomerRole = ["Boss", "Manager"];

  const isEditing = (record) => record.key === editingKey;

  const onClose = () => { setOpen(false); };

  const showDrawer = () => { setOpen(true); };

  const handleClose = () => {
    setPersmissionModal(false)
  };

  const handldeDefaultModuleClose = () => {
    setDefaultModuleModal(false);
  };

  //Get the Role list
  const GetRoleList = async () => {
    await fobeAxios.post('api/Admin/getRolelist').then((res) => {
      let templist = [];
      templist = res.data.Role_list.map((data) => {
        return (data?.Role_name != 'Boss' && data?.Role_name != 'Manager') && {
          key: data.Role_id,
          Role_name: data.Role_name,
          status: data.Status
        }
      }).filter(Boolean);
      setRoleList(templist);
    }).catch((err) => {
      console.log(err)
    });
  };

  // Make The Table Cell Editable
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps} style={{ width: '50%' }}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Enter ${title}!`,
              },
            ]}

          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  //Get Role Access List
  // const [RoleAccessList, setRoleAccessList] = useState([]);
  const GetRoleAccessList = async () => {
    await fobeAxios.get("api/Admin/getRoleaccess").then((res) => {
      let temp = res.data.Roleaccess_list;
      // .filter((roleAccess) => {
      //   return !roleAccess['Associated with'].includes('None')
      // });
      temp = temp.map((a) => {
        return { ...a, Role: a['Associated with'] === 'None' ? 'Other Modules' : `${a['Associated with']} Modules` }
      })

      // res.data.Roleaccess_list[0].List.map((roleAccess) => {
      //   temp.push(roleAccess);
      // })

      let otherIndex = temp.findIndex(obj => obj.Role === "Other Modules");
      if (otherIndex > -1) {
        let otherObject = temp.splice(otherIndex, 1)[0];
        temp.push(otherObject);
      }
      let adminIndex = temp.findIndex(obj => obj["Associated with"] === "Admin");
      if (adminIndex > -1) {
        let adminObject = temp.splice(adminIndex, 1)[0];
        temp.push(adminObject);
      }
      setRoleData(temp);
      setColumnList([...res.data.Table_columns]);
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false);
    });
  };

  const handlePermissionChange = (req, row, data) => {
    if (row.original[data.field] === 0) {
      setPermissions(1);
      setPersmissionModal(true);
      setUpdateMaduleList({ ...row.original, field: data.field, column_name: data.column_name, role_id: data.role_id, key: req })
    } else {
      if (req == 'Update') {
        setPermissions(row.original[data.field]);
        setPersmissionModal(true);
        setUpdateMaduleList({ ...row.original, field: data.field, column_name: data.column_name, role_id: data.role_id, key: req })
      } else {
        if (data.default_modules.includes(row.original.Role)) {
          const options = RoleData.map(item => {
            if (item["Associated with"] === "Admin") {
              return item["List"]
                .filter(item2 => item2[data["field"]] != 0 && row.original.Role !== item2.Role)
                .map(item2 => ({
                  label: item2.Role,
                  value: item2.Role
                }));
            }
            return null;
          }).filter(item => item).flat();

          setDefaultModuleOptions(options);
          setDefaultModuleModal(true);
          setUpdateMaduleList({
            ...row.original,
            field: data.field,
            column_name: data.column_name,
            role_id: data.role_id,
            key: req
          });
        } else {
          setUpdateMaduleList({ ...row.original, field: data.field, column_name: data.column_name, role_id: data.role_id, key: req });
          UpdateRoleAccess({ ...row.original, field: data.field, column_name: data.column_name, role_id: data.role_id, key: req });
        }
      }

    }
  };

  const handleDefaultModuleChange = () => {
    UpdateRoleAccess({ ...updateMaduleList, defaultmodule: selectedDefaultModule || '' });
  };

  const columns = useMemo(
    () => [
      {
        id: 'role',
        header: '',
        muiTableHeadCellProps: {
          className: 'Header--Group'
        },
        enableColumnPinning: true,
        columns: [
          {
            accessorKey: "Role",
            header: 'Modules/Roles',
            enableColumnPinning: true,
            editable: false,
            enableColumnFilter: false,
            enableColumnOrdering: false,
            enableGrouping: false,
            muiTableHeadCellProps: {
              align: 'left',
            },
            size: 80,
            muiTableBodyCellProps: ({ cell, row }) => (
              {
                style: row?.original?.Role?.includes('Modules') ? { fontWeight: 500 } : {}, // Customize sub-row cell style, e.g., indentation
                align: row?.original?.Role?.includes('Modules') ? 'left' : 'right', // Align sub-rows differently
              }),
          }
        ]
      },
      filterValue == 2 && {
        id: 'Customer',
        header: 'Customer',
        muiTableHeadCellProps: {
          className: 'Header--Group'
        },
        columns: [
          ...ColumnList.filter(a => hasCustomerRole.includes(a.field)).map((data) => {
            return {
              accessorKey: data.field,
              header: data.column_name,
              editable: false,
              enableColumnFilter: false,
              enableColumnOrdering: false,
              enableGrouping: false,
              enableSorting: false,
              muiTableHeadCellProps: {
                align: 'center',
              },
              muiTableBodyCellProps: {
                align: 'center',
              },
              size: 400,
              Cell: ({ cell, row }) => {
                return !row.original?.Role?.includes('Modules') && (
                  <>
                    {
                      parseFloat(row.id) < 3 ?
                        (<div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center', fontWeight: '500' }}>
                          {!disabledOptions.includes(row.original.Role) && <div>
                            <Switch
                              checked={row.original[data.field] != 0 ? true : false}
                              size='small'
                              disabled={RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')}
                              onChange={() => { handlePermissionChange('Add', row, data) }}
                            />
                          </div>}
                          <div style={{ width: '30px' }}>
                            {row.original[data.field] != 0 ?
                              <>
                                {row.original[data.field] === 2 ?
                                  <>
                                    <span
                                      onClick={() => { !RoleAccessManagement.hasReadAndWriteAccess('Fobe Team') && handlePermissionChange('Update', row, data) }}
                                      style={{ cursor: RoleAccessManagement.hasReadAndWriteAccess('Fobe Team') ? 'not-allowed' : 'pointer' }}
                                    >R/W</span>
                                  </>
                                  :
                                  <>
                                    <span onClick={() => { !RoleAccessManagement.hasReadAndWriteAccess('Fobe Team') && handlePermissionChange('Update', row, data) }}
                                      style={{ cursor: RoleAccessManagement.hasReadAndWriteAccess('Fobe Team') ? 'not-allowed' : 'pointer' }}
                                    >R</span>
                                  </>
                                }
                              </>
                              :
                              <><div style={{ width: '30px' }}>-</div></>
                            }
                          </div>
                        </div>
                        )
                        :
                        (
                          <>
                            -
                          </>
                        )
                    }
                  </>
                );
              },
            }
          })
        ]
      },
      filterValue == 1 && {
        id: 'id',
        header: 'Fobesoft Team',
        // className:'Header--Group',
        columns: [
          ...ColumnList.filter(a => !hasCustomerRole.includes(a.field)).map((data) => {
            return {
              accessorKey: data.field,
              header: data.column_name,
              editable: false,
              enableColumnFilter: false,
              enableSorting: false,
              enableColumnOrdering: false,
              enableGrouping: false,
              muiTableHeadCellProps: {
                align: 'center',
              },
              muiTableBodyCellProps: {
                align: 'center',
              },
              size: 50,
              // enableHiding: false,
              Cell: ({ cell, row }) => {
                return !row.original?.Role?.includes('Modules') && (
                  <>
                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center', fontWeight: '500' }}>
                      {!disabledOptions.includes(row.original.Role) && <div>
                        <Switch
                          checked={row.original[data.field] != 0 ? true : false}
                          size='small'
                          // className={
                          //   (RoleAccessManagement.hasRoleMatch('Superuser') && data.field === 'Superuser')
                          //     || (RoleAccessManagement.hasRoleMatch('Account Manager') &&
                          //       (data.field === 'AccountManager' || data.field === 'Superuser'))
                          //     ? 'custom-switch' : ''
                          // }
                          disabled={
                            RoleAccessManagement.hasRoleMatch('Superuser') && data.field === 'Superuser' ||
                            RoleAccessManagement.hasRoleMatch('Account Manager') && (data.field === 'AccountManager' || data.field === 'Superuser') ||
                            RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')
                          }
                          onChange={() => { handlePermissionChange('Add', row, data) }}
                        />
                      </div>}
                      <div style={{ width: '30px' }}>
                        {row.original[data.field] != 0 ?
                          <>
                            {row.original[data.field] === 2 ?
                              <>
                                <span
                                  onClick={() => {
                                    if (RoleAccessManagement.hasRoleMatch('Superuser') && data.field === 'Superuser') {
                                      return;
                                    } else if (RoleAccessManagement.hasRoleMatch('Account Manager') &&
                                      (data.field === 'AccountManager' || data.field === 'Superuser')) {
                                      return;
                                    } else if (!RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')) {
                                      handlePermissionChange('Update', row, data);
                                    }
                                  }}
                                  style={{
                                    cursor:
                                      (RoleAccessManagement.hasRoleMatch('Superuser') && data.field === 'Superuser') ||
                                        (RoleAccessManagement.hasRoleMatch('Account Manager') &&
                                          (data.field === 'AccountManager' || data.field === 'Superuser'))
                                        ? 'default'
                                        : !RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')
                                          ? 'pointer'
                                          : 'default'
                                  }}
                                >R/W</span>
                              </>
                              :
                              <>
                                <span
                                  onClick={() => {
                                    if (RoleAccessManagement.hasRoleMatch('Superuser') && data.field === 'Superuser') {
                                      return;
                                    } else if (RoleAccessManagement.hasRoleMatch('Account Manager') &&
                                      (data.field === 'AccountManager' || data.field === 'Superuser')) {
                                      return;
                                    } else if (!RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')) {
                                      handlePermissionChange('Update', row, data);
                                    }
                                  }}
                                  style={{
                                    cursor:
                                      (RoleAccessManagement.hasRoleMatch('Superuser') && data.field === 'Superuser') ||
                                        (RoleAccessManagement.hasRoleMatch('Account Manager') &&
                                          (data.field === 'AccountManager' || data.field === 'Superuser'))
                                        ? 'default'
                                        : !RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')
                                          ? 'pointer'
                                          : 'default'
                                  }}
                                >R</span>
                              </>
                            }
                          </>
                          :
                          <><div style={{ width: '30px' }}>-</div></>
                        }
                      </div>
                    </div >
                  </>);

              },

            }
          })
        ]
      }
    ].filter(Boolean),
    [RoleData, filterValue],
  );

  //Save The Role Access
  const UpdateRoleAccess = async (value) => {
    let req = {
      "permission": permissions,
      "role_id": value.role_id,
      "module": value.Role,
      "role_access": value.key == 'Add' ? (value[value.field] != 0 ? 0 : 1) : 1,
      "defaultmodule": value.defaultmodule
    }

    await fobeAxios.post("api/Admin/UpdateRoleAccess", req).then((res) => {
      if (res.data.status == 1) {
        toast.success("Role Access updated");
        setPermissions(1);
        setPersmissionModal(false);
        handldeDefaultModuleClose();
        setSelectedDefaultModule(null);
        setDefaultModuleOptions([]);
        GetRoleAccessList();
      } else {
        toast.error("Role Access is not updated");
        GetRoleAccessList();
        handldeDefaultModuleClose();
        setSelectedDefaultModule(null);
        setDefaultModuleOptions([]);
      }
    }).catch((err) => {
      console.log(err)
    })
  };

  //RoleName Color
  const setBg = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor.toString();
  };

  //Columns rendering for RoleManagement table
  const columns1 = [
    {
      title: 'Role',
      dataIndex: 'Role_name',
      key: 'Role_name',
      align: 'left',
      editable: true,
    },
    // {
    //   title: 'Switch',
    //   dataIndex: 'status',
    //   key: 'switch',
    //   align: 'center',
    //   render: (text,row) => {
    //     // console.log(text,row)
    //   //  return <Switch size="small" checked={text} onChange={()=>{changeRoleAccessStatus(row)}}  />
    // },
    // },
    {
      title: 'Edit',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (<div style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
          <CheckCircleIcon onClick={() => { AddRole(record) }}
            style={{ color: '#6A8AB9', cursor: 'pointer' }} />
          <CancelIcon onClick={cancel}
            style={{ color: '#F86764', cursor: 'pointer' }} />
        </div>
        ) : (
          <div style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
            <Tooltip title="Edit">
              <EditIcon onClick={() => record.key > 170 ? edit(record) : ''}
                style={{ color: '#6A8AB9', cursor: record.key > 170 ? 'pointer' : 'not-allowed' }} />
            </Tooltip>
            <Tooltip title="Delete" onClick={() => record.key > 170 ? showDeletePopup(record) : ''}>
              <DeleteOutlineOutlinedIcon
                style={{ color: '#F86764', cursor: record.key > 170 ? 'pointer' : 'not-allowed' }} />
            </Tooltip>
          </div>)
      },
    }
  ];

  //Delete the User Role
  const showDeletePopup = (record) => {
    setDeletePopup(true);
    setListDetails(record);
  };

  const deleteUserHandler = async (record) => {
    try {
      const response = await fobeAxios.get(`api/Admin/deleteRoleandShiftusers?role_to_be_deleted=${record.key}&shifting_role=${selectedRole}`);
      if (response?.data?.status) {
        GetRoleList();
        GetRoleAccessList();
      }
      setDeletePopup(false);
    } catch (err) {
      console.log(err);
      setDeletePopup(false);
    }
  };

  //Edit the Cell inside the table
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
    // setNewRoleSaveKey('exist');
  };

  //Cancel the Changes
  const cancel = () => {
    if (NewRoleSaveKey == 'New') {
      let temp = RoleList;
      temp.splice(temp.length - 1, 1);
      setRoleList([...temp]);
      setNewRoleSaveKey('');
      setEditingKey('');
    } else {
      setEditingKey('');
    }
  };

  //Save the Changes
  const AddRole = async (key) => {

    const row = await form.validateFields();
    let req = {
      "role_id": key.key == 1 ? 0 : key.key,
      "Rolename": row.Role_name,
      "color_code": setBg()
      // "role_status": 1
    }
    await fobeAxios.post("api/Admin/AddRoleaccess1", req).then((res) => {
      if (res.data.status == 1) {
        GetRoleList();
        GetRoleAccessList();
        setEditingKey('');
        setNewRoleSaveKey('');
      } else {
        form.setFields([
          {
            name: 'Role_name',
            errors: ['This role already exists'],
          },
        ]);
      }
    }).catch((err) => {
      console.log(err)
    })
  };

  //Add New Role
  const AddNewRole = () => {
    let temp = [...RoleList];
    temp.push(
      {
        "key": 1,
        "Role_name": "",
        "status": 0
      }
    )
    setRoleList(temp)
    setNewRoleSaveKey('New');
    edit(temp[temp.length - 1]);
  }

  //Columns render When the table cell is editable
  const mergedColumns = columns1.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const optionsList = RoleList.filter(item => {
    return item.key !== listDetails.key;
  });

  const options = optionsList.map(role => {
    return { label: role.Role_name, value: role.key }
  });

  const Handelfobeteam = (value) => {
    setFilterValue(value)
  };

  const manageFilter = [
    <>
      <div className='forbuttonon1' style={{ display: 'flex', gap: '100px' }}>
        <Select
          size="medium"
          style={{ width: '140px' }}
          placeholder="Select"
          value={filterValue}
          options={filterOptions}
          onChange={Handelfobeteam}
        />

        <div style={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
          {[
            { label: 'Access Given', checked: 1 },
            { label: 'Access Denied', checked: 0 }
          ].map((item, idx) => (
            <div key={idx} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Switch
                defaultChecked={item.checked}
                size='small'
                disabled={true}
                className='custom-switch default-opacity'
              />
              <span style={{ color: '#92979d' }}>{item.label}</span>
            </div>
          ))}

          {[
            { label: 'Read Only', symbol: '(R)' },
            { label: 'Read And Write', symbol: '(R/W)' },
            { label: "Don't Have a Module Access", symbol: '(-)' }
          ].map((item, idx) => (
            <div key={idx} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <span style={{ fontWeight: '600' }}>{item.symbol}</span>
              <span style={{ color: '#92979d' }}>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  ];

  useEffect(() => { GetRoleList(); GetRoleAccessList(); }, []);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div className='forbuttonon' style={{ position: 'absolute', right: '0', bottom: '6px', zIndex: '9' }}>
          <Button type="primary" disabled={RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')} onClick={showDrawer} >Add Role</Button>
        </div>
      </div>
      <div className='useertable role--access'>
        <CommonTable
          rows={RoleData}
          columns={columns}
          table={"role"}
          isLoading={loading}
          HiddenColumnlist={{}}
          headFilter={manageFilter}
        />
      </div>
      {/* <div style={{
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        bottom: '0',
        width: '100%', // This ensures the div spans the entire width of the viewport
        padding: '15px',
        background: '#fff',
        left: '0%',
        //  zIndex: '999'
      }}>
        <div className='row'>
            <div className='col-md-6'>
            <Button type="primary" disabled={RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')} onClick={UpdateRoleAccess} style={{backgroundColor: '#19A73F'}}>Save</Button>
            </div>
            <div className='col-md-6'>
            <Button style={{
        backgroundColor:'rgb(239 239 239)',
        color:'#000',
        borderRadius:'3px',
        border:'0.5px solid #b2b2b2',
                      }} disabled={RoleAccessManagement.hasReadAndWriteAccess('Fobe Team')} onClick={GetRoleAccessList}>Cancel</Button>
            </div>
        </div>

      </div> */}
      <Drawer
        title="Role Management"
        width={550}
        onClose={onClose}
        open={open}
        closeIcon={<KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />}
      >
        <div className='newroledraw'>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={RoleList}
              columns={mergedColumns}
              showHeader={false}
              pagination={false}
            />
          </Form>
        </div>
        <div className='addplus'>
          <Button type="link" className='pluslink' disabled={editingKey != ''} onClick={AddNewRole}> <AddCircleIcon style={{ color: '#19A73F', marginTop: '-4px' }} />&nbsp;&nbsp;Add New Role</Button>
        </div>
      </Drawer>
      <Modal
        title="Delete User"
        open={deletePopup}
        onCancel={() => { setDeletePopup(false) }}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              onClick={() => { setDeletePopup(false) }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: '#19A73F',
                color: '#fff',
                borderRadius: '3px',
                border: 'none'
              }}
              onClick={() => deleteUserHandler(listDetails)}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p style={{ fontSize: '15px', textAlign: 'center', padding: '10px 20px 20px 20px' }}>Before you delete the role please select the role to assign the role</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Select
            style={{
              width: '80%',
              margin: '10px 0'
            }}
            placeholder="Select Role"
            options={options}
            value={selectedRole}
            onChange={(e) => { setSelectedRole(e) }}
          />
        </div>
      </Modal>
      <Modal
        title="Permissions"
        open={hasPersmissionModal}
        onCancel={handleClose}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className='addOnSaveBtn'
              style={{
                backgroundColor: '#19A73F',
                color: '#fff',
                borderRadius: '3px',
                border: 'none'
              }}
              onClick={() => UpdateRoleAccess(updateMaduleList)}
            >
              Confirm
            </Button>
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        ]}
        width={450}
      >
        <div>
          <div style={{ paddingLeft: '2em' }}>
            <p>Please select the level of access :</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '1.3em' }}>
            <Radio.Group name="radiogroup" value={permissions} onChange={(e) => { setPermissions(e.target.value); }}>
              <Space direction="horizontal">
                <Radio value={1}>Read</Radio>
                <Radio value={2}>Read And Write</Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
      </Modal>
      <Modal
        title="Default Module Warning"
        open={defaultModuleModal}
        onCancel={handldeDefaultModuleClose}
        width={650}
        footer={[]}
      >
        <div>
          <div style={{ paddingLeft: '2em' }}>
            <p>This module serves as the default module. If you want to disable it, you need to choose another module as the default.</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '1.3em', gap: '1rem' }}>
            <Select
              placeholder="Select Module"
              options={defaultModuleOptions}
              style={{
                width: 150,
              }}
              value={selectedDefaultModule}
              onChange={(value) => setSelectedDefaultModule(value)}
            />
            <Button
              className='addOnSaveBtn'
              style={{
                backgroundColor: '#19A73F',
                color: '#fff',
                borderRadius: '3px',
                border: 'none'
              }}
              onClick={handleDefaultModuleChange}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal >
    </>
  );
}
export default RoleAccess;
