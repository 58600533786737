import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Checkbox, Input, Button, Form, Select } from 'antd';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { fobeAxios } from '../../../middleware/interceptor';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from "react-toastify";
import { FaPen } from "react-icons/fa";

function UserProfile() {
  const [emails, setEmails] = useState([]);
  const [disable, setdisable] = useState(true)
  const [inputValue, setInputValue] = useState('');
  const [ckActive, setCkActive] = useState();
  const [orgno, setorgno] = useState()
  const [account, setaccount] = useState()
  const [Security_code, setSecurity_code] = useState()
  const [routing, setrouting] = useState()
  const [checkBoxError, setCheckBoxError] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [ProfileData, setProfileData] = useState(null)
  const [paytype, setpaytype] = useState();
  const [expiryyear, setexpiryyear] = useState()
  const [form] = Form.useForm();
  let { rest_id } = useParams();
  useEffect(() => {
    GetProfileData(rest_id);
    setCurrentYear(new Date().getFullYear())
  }, []);


  const GetProfileData = async (req) => {
    await fobeAxios.get("Api/Admin/GetUserProfile/" + req).then((res) => {
      console.log(res.data.UserDetails[0], 'values');
      setEmails(res.data.UserDetails[0].notification_email ? res.data.UserDetails[0].notification_email?.split(', ') : []);
      setCkActive(res.data.UserDetails[0].ckActive);
      setorgno(res.data.UserDetails[0].OrgCard_no,)
      setrouting(res.data.UserDetails[0].OrgBank_routing_number)
      setaccount(res.data.UserDetails[0].OrgBank_account_number)
      setSecurity_code(res.data.UserDetails[0].OrgSecurity_code)
      const payment = res.data.UserDetails[0].Payment_method

      if (payment == 2) {

        setpaytype('Bank Account')
      }
      else if (payment == 1) {

        setpaytype('Credit Card')
      }

      form.setFieldsValue
        ({
          fname: res.data.UserDetails[0].FirstName,
          lname: res.data.UserDetails[0].LastName,
          Email: res.data.UserDetails[0].Email,
          Restname: res.data.UserDetails[0].RestaurantName,
          Primary_mail: res.data.UserDetails[0].Primary_mail,
          Phone_no: res.data.UserDetails[0].Phone_no,
          notification_email: res.data.UserDetails[0].notification_email,
          CreditCardNumber: res.data.UserDetails[0].Card_no,
          Expiration_Year: res.data.UserDetails[0].Expiration_year,
          CardHolderName: res.data.UserDetails[0].Card_name,
          BankAccountNumber: res.data.UserDetails[0].Bank_account_number,
          BankRoutingNumber: res.data.UserDetails[0].Bank_routing_number,
          SecurityCode: res.data.UserDetails[0].Security_code,
          location: res.data.UserDetails[0].Location,
          StreetNo: res.data.UserDetails[0].Street_number,
          address: res.data.UserDetails[0].Address,
          city: res.data.UserDetails[0].City,
          state: res.data.UserDetails[0].State,
          county: res.data.UserDetails[0].Country == null ? '' : res.data.UserDetails[0].Country,
          zip: res.data.UserDetails[0].Zipcode,
          ck_Active: res.data.UserDetails[0].ckActive,
          AccessCode: res.data.UserDetails[0].Access_code,
          CardType: res.data.UserDetails[0]?.Card_type == "Visa" ? '1' : res.data.UserDetails[0]?.Card_type == "Master Card" ? '2' : '3',
          Expiration_Month: res.data.UserDetails[0]?.Expiration_month,
          Expiration_Year: res.data.UserDetails[0]?.Expiration_year,
          customer_profile_id: res.data.UserDetails[0]?.customer_profile_id,
          payment_profile_id: res.data.UserDetails[0]?.payment_profile_id,
        });
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => (
    form.setFieldsValue({
      paymentmethod: paytype,
    })), [paytype])
  const paymentdrop = (value) => {
    setpaytype(value)
  }

  const handleKeyDown = (e) => {
    // Allow only letters (A-Z and a-z)
    if (e.key.match(/[^A-Za-z\s]/) && (e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Delete')) {
      e.preventDefault();
      console.log(e.key)

    }
  };
  const handleKeyDownfna = (e) => {
    // Allow only letters (A-Z and a-z)
    if (e.key.match(/[^A-Za-z0-9\s]/) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'delete') {
      e.preventDefault();

    }
  };

  const handleKeynumDown = (e) => {
    // Allow only letters (A-Z and a-z)
    if (e.key.match(/[^0-9]/) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'delete') {
      e.preventDefault();
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };
  const handleRemoveEmail = (emailToRemove) => {
    setEmails(emails?.filter((email) => email !== emailToRemove));
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === ' ' || event.key === 'Enter' || event.key === 'Tab' || event.key === ',' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'delete') {
      event.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue) {
        if (validateEmail(trimmedValue)) {
          setEmails([...emails, trimmedValue]);
        }
        setInputValue('');
      }
    } else if (event === 'Submit') {
      const trimmedValue = inputValue.trim();
      if (trimmedValue) {
        if (validateEmail(trimmedValue)) {
          setEmails([...emails, trimmedValue]);
        }
        setInputValue('');
      }
    }
  };
  const handleInputKeyDown = (event) => {
    if (event.key === 'Backspace' && inputValue === '') {
      const lastEmail = emails[emails?.length - 1];
      if (lastEmail) {
        setEmails(emails?.slice(0, -1));
      }
    }
  };
  const onSubmit = async (val) => {
    console.log('val', val)
    const validEmails = [...emails];
    const checkEmail = await handleInputKeyPress('Submit');
    if (checkEmail !== undefined) {
      validEmails.push(checkEmail);
    }
    let value = {};
    if (val.paymentmethod == 'Credit Card') {
      val.paymentmethod = '1'

      value = {
        ...val,
        'EditType': disable ? 0 : 1,
        'rest_id': rest_id,
        'ckActive': ckActive,
        'CardType': 0,
        'notification_email': validEmails.length > 0
          ? validEmails.join(', ')
          : '',
        'CreditCardNumber': disable ? '' : form.getFieldValue('CreditCardNumber'),
        'SecurityCode': disable ? '' : form.getFieldValue('SecurityCode')
      }
    } else if (val.paymentmethod == 'Bank Account') {
      val.paymentmethod = '2'

      value = {
        ...val,
        'EditType': disable ? 0 : 1,
        'rest_id': rest_id,
        'ckActive': ckActive,
        'notification_email': validEmails.length > 0
          ? validEmails.join(', ')
          : '',
        'BankAccountNumber': disable ? '' : form.getFieldValue('BankAccountNumber'),
        'BankRoutingNumber': disable ? '' : form.getFieldValue('BankRoutingNumber'),
      }
    }
    console.log(value, 'value');
    await fobeAxios.post('Api/Admin/UpdateUserDetils', value).then((res) => {
      if (res.data.status == 1) {
        if (res.data.payment_status.result == "Ok") {
          toast.success("Updated Successfully");
          setdisable(true);
        } else if (res.data.payment_status.result == "Error") {
          toast.error(res.data.payment_status.status);
        } else {
          toast.success("Updated Successfully");
          setdisable(true);
        }
      } else {
        toast.error("Failed to Update");
      }
    }).catch((err) => {
      console.log(err)
    });
  }

  const Editbutton = async () => {
    form.resetFields(['BankRoutingNumber', 'BankAccountNumber', 'SecurityCode', 'Expiration_Month', 'Expiration_Year', 'CreditCardNumber', 'CardHolderName', 'CardType']);
    setdisable(false)

  }
  const onfail = (e) => {
    console.log(e)
    toast.warning('Please fill Mandatory fields')
  }
  // const clearvalue=()=>{
  //   form.resetFields('paymentmethod')
  // }
  return (
    <>
      <Box style={{ width: '100%' }}>
        <div className='cardnew1'>
          <Form layout="vertical" style={{ width: '80%', margin: '0 auto' }} onFinish={onSubmit} form={form} onFinishFailed={onfail}>
            <div className='col-md-12'>
              <p className='formhead'>Enter Your Account Information</p>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="fname"
                    label="First Name"
                    rules={[
                      {
                        message: "First name is Required",
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter your First Name" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDownfna} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item
                    name="lname"
                    label="Last Name"
                    rules={[
                      {

                        message: "Last name is Required",
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter your Last Name" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDownfna} />
                  </Form.Item>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="Email"
                    label="User Name"
                    rules={[
                      {

                        message: 'UserName is required',
                        required: true,
                      },
                      {
                        type: 'email',
                        message: 'Invalid UserName'
                      }
                    ]}
                  >
                    <Input placeholder="Enter your User Name" style={{ height: '38px', width: '70%' }} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item
                    name="Restname"
                    label="Restaurant Name"
                    rules={[
                      {

                        message: "Restaurant name is Required",
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter your Restaurant Name" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDownfna} />
                  </Form.Item>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="Primary_mail"
                    label="Primary Email"
                    rules={[
                      {
                        message: 'Email is required',
                        required: true,
                      },
                      {
                        type: 'email',
                        message: "Invalid Email"
                      }
                    ]}
                  >
                    <Input placeholder="Enter your  Email" style={{ height: '38px', width: '70%' }} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item
                    name="Phone_no"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: 'Phone number is Required'
                      },
                      {
                        min: 10,
                        message: 'Invalid Phone number'
                      },

                    ]}
                  >
                    <Input placeholder="Enter your Phone number" maxLength={10} style={{ height: '38px', width: '70%' }} onKeyDown={handleKeynumDown} />
                  </Form.Item>
                </div>
              </div>
              {/*
              <div className='row'>
                <div className='primmail'>
                  <p className="noti"><NotificationsNoneIcon /> Notification Mail To</p>
                  <Form.Item
                    // name="notification_email"
                    rules={[{
                      type: 'email',
                      message: "Invalid Notifiaction Email"
                    },
                    ]}
                  >
                    <TextField
                      margin="dense"
                      id="name"
                      label="Notification Email"
                      type="email"
                      fullWidth
                      variant="standard"
                      className='textField'
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyPress={handleInputKeyPress}
                      onKeyDown={handleInputKeyDown}
                      InputProps={{
                        startAdornment: (
                          <div className='email-token-outer'>
                            {emails?.map((email) => (
                              <div key={email} className='email-token'>
                                {email}
                                <CloseIcon
                                  onClick={() => handleRemoveEmail(email)}
                                  className="remove-icon"
                                />
                              </div>
                            ))}
                          </div>
                        ),
                      }}
                    />
                  </Form.Item>
                  <p className="notilabel">
                    Entering your email here will send you and alert when there is no activity on your page for 3 days
                  </p>
                  <p className="notilabel">
                    *Use Comma or Space to add multiple emails
                  </p>
                  {emails?.length > 0 &&
                    <div className='notify-chkbox'>
                      <Form.Item name='ckActive'>
                        <Checkbox
                          id='notification'
                          defaultChecked={ckActive}
                          onChange={(e) => {
                            setCkActive(e.target.checked);
                            setCheckBoxError(e.target.checked ? '' : null);
                          }}
                          style={{ marginTop: '0.8rem', color: '#0f5c2e' }}
                        >
                          Enable Send Notification Email
                        </Checkbox>
                        {checkBoxError !== null &&
                          <div
                            style={{
                              color: 'red',
                              textAlign: 'left',
                              marginTop: 5
                            }}
                          >{checkBoxError}</div>
                        }
                      </Form.Item>
                    </div>
                  }
                </div>
              </div>
              */}
              <p className='formhead' style={{ marginTop: '20px' }}>Enter Your Billing Information - SECURE</p>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="paymentmethod"
                    label="Payment Method"
                    rules={[
                      {
                        required: true,
                        message: 'Payment Method is Required'
                      },
                    ]}
                  >
                    <Select
                      onChange={paymentdrop}
                      style={{ height: '38px', width: '70%' }}
                      className='formroles'
                      disabled={disable}
                      placeholder="Choose Payment Method"
                      options={[
                        {
                          value: 'Credit Card',
                          label: 'Credit Card',
                        },
                        {
                          value: 'Bank Account',
                          label: 'Bank Account',
                        },

                      ]}
                    />
                  </Form.Item>
                </div>
                <div className='col-md-6' style={{ alignSelf: 'center' }}>
                  <Button type="primary" style={{ backgroundColor: '#fff', border: '1px solid #19A73F', color: '#19A73F', display: 'flex', gap: '10px', alignItems: 'center' }} onClick={Editbutton}><FaPen /> Edit</Button>
                </div>

              </div>

              {paytype == 'Bank Account' &&
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Item
                      name="BankAccountNumber"
                      label="Bank Account Number"

                      rules={[
                        {
                          message: 'Account Number is Required',
                          required: disable ? false : true,
                        },
                        {
                          message: 'Maximum (4-17) digits',
                          min: 4,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Account Number" maxLength={17} style={{ height: '38px', width: '70%' }} onKeyDown={handleKeynumDown} disabled={disable} />
                    </Form.Item>
                  </div>
                  <div className='col-md-6'>
                    <Form.Item
                      name="BankRoutingNumber"
                      label="Bank Routing Number"
                      rules={[
                        {
                          message: 'Routing Number is Required',
                          required: disable ? false : true,
                        },
                        {
                          message: '*Maximum 9 digits',
                          min: disable ? 0 : 9,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Routing Number" maxLength={9} style={{ height: '38px', width: '70%' }} onKeyDown={handleKeynumDown} disabled={disable} />
                    </Form.Item>
                  </div>

                </div>
              }
              {paytype == 'Credit Card' &&
                <>
                  <div className='row'>
                    {/* <div className='col-md-6'>
                      <Form.Item
                        name="CardType"
                        label="Credit Card Type"
                      >
                        <Select
                          placeholder={'Choose Card Type'}
                          style={{ height: '38px', width: '70%' }}
                          className='formroles'
                          disabled={disable}
                          options={[
                            {
                              value: '1',
                              label: 'Visa',
                            },
                            {
                              value: '2 ',
                              label: 'Master Card',
                            },
                            {
                              value: '3',
                              label: 'American Express',
                            },



                          ]}
                        />
                      </Form.Item>
                    </div> */}
                    {!disable &&
                      <div className='col-md-6'>
                        <Form.Item
                          name="CardHolderName"
                          label="Card Holder Name"

                          rules={[
                            {
                              message: 'Cardholder name is required',
                              required: disable ? false : true,
                            },
                          ]}
                        >
                          <Input placeholder="Enter Card Holder Name" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDown} disabled={disable} />
                        </Form.Item>
                      </div>
                    }
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Form.Item
                        name="CreditCardNumber"
                        label="Credit Card Number"

                        rules={[
                          {
                            message: 'Credit Card Number is required',
                            required: disable ? false : true,
                          },
                          {
                            message: '*Maximum (9-16) digits',
                            min: 9,
                          },
                        ]}
                      >
                        <Input placeholder="Enter Credit Card Number" maxLength={16} style={{ height: '38px', width: '70%' }} onKeyDown={handleKeynumDown} disabled={disable} />
                      </Form.Item>
                    </div>
                    {!disable &&
                      <>
                        <div className='col-md-3' style={{ width: '21%' }}>
                          <Form.Item
                            name="Expiration_Month"
                            label="Expiration Month"
                            rules={[
                              {
                                required: disable ? false : true,
                              },
                              // ({getFieldValue})=>({
                              //   validator(rule,value){
                              //     if ((parseInt(value) < new Date().getMonth()) && getFieldValue('Expiration_Year')==2023 ) {
                              //       return Promise.reject('Invalid Month');
                              //     }
                              //     return Promise.resolve();
                              //   }
                              // })
                            ]}
                          >
                            <Select
                              name="expirymonth"
                              style={{ height: '38px', width: '70%' }}
                              className='formroles'
                              placeholder="Choose Month"
                              disabled={disable}
                              options={Array.from({ length: 12 }, (_, index) => ({
                                label: index + 1 > 9 ? (index + 1).toString() : '0' + (index + 1).toString(),
                                value: index + 1 > 9 ? (index + 1).toString() : '0' + (index + 1).toString(),
                              }))}
                            />
                          </Form.Item>
                        </div>
                        <div className='col-md-3' style={{ width: '20%' }}>
                          <Form.Item
                            name="Expiration_Year"
                            label="Expiration Year"
                            rules={[
                              {
                                required: disable ? false : true,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Choose Year"
                              disabled={disable}
                              style={{ height: '38px', width: '70%' }}
                              className='formroles'
                              options={
                                Array.from({ length: 12 }, (_, index) => ({
                                  label: (currentYear + index).toString(),
                                  value: (currentYear + index).toString()
                                }))}
                            />
                          </Form.Item>
                        </div>
                      </>
                    }
                  </div>
                  {!disable &&
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Item
                          name="SecurityCode"
                          label="Security Code"

                          rules={[
                            {
                              message: 'Security code is required',
                              required: disable ? false : true,
                            },
                            {
                              message: '*Maximum (3-4) digits',
                              min: 3,
                            },
                          ]}
                        >
                          <Input placeholder="Enter Security Code" disabled={disable} maxLength={4} style={{ height: '38px', width: '70%' }} onKeyDown={handleKeynumDown} />
                        </Form.Item>
                      </div>
                    </div>
                  }
                </>
              }
              <p className='formhead'>Enter Your Billing Address</p>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="location"
                    label="Location"
                    rules={[
                      {
                        message: 'Location is required',
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your Location" style={{ height: '38px', width: '70%' }} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item
                    name="StreetNo"
                    label="Street Name"
                    rules={[
                      {
                        message: 'Street Name is required',
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your Street Name" style={{ height: '38px', width: '70%' }} />
                  </Form.Item>
                </div>

              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                      {
                        message: 'Address is required',
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your Address" style={{ height: '38px', width: '70%' }} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[
                      {
                        message: 'City is required',
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your City" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDown} />
                  </Form.Item>
                </div>

              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      {
                        message: 'State is required',
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your State" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDown} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item
                    name="county"
                    label="Country"
                    rules={[
                      {
                        message: 'Country is required',
                        required: true,
                      },

                    ]}
                  >
                    <Input placeholder="Enter Your Country" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDown} />
                  </Form.Item>
                </div>

              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="zip"
                    label="Zip"
                    rules={[
                      {
                        message: 'Zip is required',
                        required: true,
                      },
                      {
                        message: 'Zip must be 3 characters long',
                        min: 3,
                      },

                    ]}
                  >
                    <Input placeholder="Enter Your Zip" maxLength={6} style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDownfna} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item
                    name="AccessCode"
                    label="Access Code(if any)"
                  >
                    <Input placeholder="Enter Your Access Code" style={{ height: '38px', width: '70%' }} />
                  </Form.Item>
                </div>

              </div>
              <Form.Item
                name="customer_profile_id"
              >
              </Form.Item>
              <Form.Item
                name="payment_profile_id"
              >
              </Form.Item>
              <div className='excardbut1' style={{ justifyContent: 'center', marginTop: '25px' }}>
                <Button type="primary" htmlType="submit" style={{ backgroundColor: '#19A73F' }}>Update Now</Button>
              </div>

            </div>



          </Form>
        </div>
      </Box>
    </>
  );
}

export default UserProfile;
