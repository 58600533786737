import React, { useState, useMemo, useEffect } from 'react';
import CommonTable from '../../Admin/CommonTable';
import { Button, Modal, Select, Switch } from 'antd';
import { fobeAxios } from '../../middleware/interceptor';
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import RoleAccessManagement from '../../RoleAccessManagement';
const Sysco = ({ restData, tabName, restDatas, Acctype, setAcctype }) => {
  const [mapData, setMapData] = useState([]);
  const [values, setValues] = useState([]);
  const [selectMapValue, setSelectMapValue] = useState('1');
  const [loading, setLoading] = useState(true);
  const [restMappingOpen, setRestMappingOpen] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [disable, setdisable] = useState(false)
  // const [accountId, setAccountId] = useState(null);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [restuarantStatusChange, setRestuarantStatusChange] = useState(null);
  const [AccessKey, setAccessKey] = useState(sessionStorage.getItem("SpecialUser"));
  const [rowData, setRowData] = useState();
  const [selectlabel, setSelectlabel] = useState('select');
  const [restData1, setRestData1] = useState();
  const [restData2, setRestData2] = useState();
  const [dropdown5Value, setDropdown5Value] = useState('0');

  const [acc_value, setAcc_value] = useState("");

  const selectMap = (value) => {
    setSelectMapValue(value);
    if (value === '1') {
      setValues(mapData);
    } else if (value === '2') {
      setValues(mapData?.filter(item => item?.Rest_name === null));
    } else if (value === '3') {
      setValues(mapData?.filter(item => item?.Rest_name !== null));
    }
  }



  const getMappingDetail = async () => {



  }

  const getMappingDetails = async () => {
    setLoading(true);
    let response;
    if (tabName === 'Sysco') {
      response = await fobeAxios.get("api/SyscoEDI/GetAllFoldersOfSysco");
    } else if (tabName === 'PFG') {
      response = await fobeAxios.get("api/PFSEDI/GetAllFoldersOfPFS");
    } else if (tabName === 'McLane') {
      response = await fobeAxios.get("api/McLane/GetAllFoldersOfMcLane");
    }
    else if (tabName === 'Benekeith') {


      response = await fobeAxios.get("api/Benekeith/GetAllFoldersOfBenekeith");

      console.log(response, "response")
    }
    else if (tabName === 'USFoods') {
      response = await fobeAxios.get("api/USFEDI/GetAllFoldersOfUSFEDI");

      console.log(response, "response")

    }
    setMapData(response?.data?.Result);
    setDropdown5Value('0');
    setLoading(false);
  }




  const mapRestaurantHandler = async (value, row, index) => {

    const filteredRests = restDatas?.filter(item => item?.rest_id === value);

    const matchingAccountIds = filteredRests?.map(item => item?.account_id);


    setRestData1(matchingAccountIds ? matchingAccountIds[0] : null)

    console.log(restData1, filteredRests, "matchingAccountIds")

    if (matchingAccountIds && matchingAccountIds[0] == 5) {

      let rest_id = {
        "Rest_id": value

      }

      await fobeAxios.post(`Api/Daily/Getfobedepts/`, rest_id).then((res) => {

        const manipulatedData = res?.data?.rest_list?.map(item => (
          { value: item?.rest_id, label: item?.acc_name }
        ));
        setRestData2(manipulatedData)
      })

      const filteredRest = restData?.filter(item => item?.value === value);
      setSelectedRestaurant(filteredRest[0]);
      setRowData(row)
      setSelectlabel(value);

      setAcctype(index)

      // setAccountId(row.Account_id);

    }
    else {
      const filteredRest = restData?.filter(item => item?.value === value);
      setSelectedRestaurant(filteredRest[0]);
      setRowData(row)
      setSelectlabel('');
      // setAccountId(row.Account_id);
      setRestMappingOpen(true);
      setAcctype(index)

    }




  }



  const mapRestaurantHandler1 = async (value, row, index) => {


    const filteredRest = restData2?.filter(item => item?.value === value);



    setSelectedRestaurant(filteredRest[0]);
    setRowData(row)
    setAcc_value(value);
    // setAccountId(row.Account_id);
    setRestMappingOpen(true);
    setAcctype(index)

  }





  const handleClose = () => {
    setRestMappingOpen(false);
    setStatusModalOpen(false);
    setRowData('')
    if (restData1 == 5) {
      setSelectlabel(selectlabel);

    }
    else {
      setSelectlabel('');
    }
    setSelectedRestaurant({});
    setdisable(false)
    // setAccountId(null);
  }
  const confirmMapHandler = async () => {
    setdisable(true)
    const req = {
      "Rest_id": selectedRestaurant?.value,
      "Restaurant_Name": selectedRestaurant?.label,
      "Account_id": rowData?.Account_id,
      "StoreNo": rowData?.StoreNo
    }
    let response;
    if (tabName === 'Sysco') {
      response = await fobeAxios.post("api/SyscoEDI/SyscoRestaurantMapping", req);
    } else if (tabName === 'PFG') {
      response = await fobeAxios.post("api/PFSEDI/PFSRestaurantMapping", req);
    } else if (tabName === 'McLane') {
      response = await fobeAxios.post("api/McLane/McLaneRestaurantMapping", req);
    }
    else if (tabName === 'Benekeith') {
      response = await fobeAxios.post("api/BeneKeith/BeneKeithRestaurantMapping", req);
    } else if (tabName === 'USFoods') {
      response = await fobeAxios.post("api/USFEDI/USFEDIRestaurantMapping", req);
    }
    if (response?.data?.status === 1) {
      getMappingDetails();
      toast.success("Restaurant Mapped Successfully");
      setdisable(false)
    } else {
      getMappingDetails();
      toast.error("Restaurant Not Mapped");
      setdisable(false)
    }
    setRestMappingOpen(false);
  }

  const statusDeactiveHandler = (restId, restName, folderPath) => {
    setStatusModalOpen(true);
    setRestuarantStatusChange({
      restId,
      restName,
      folderPath,
      status: 'Deactive'
    });
  }

  const statusActiveHandler = (restId, restName, folderPath) => {
    setStatusModalOpen(true);
    setRestuarantStatusChange({
      restId,
      restName,
      folderPath,
      status: 'Active'
    });
  }

  const statusHandler = async () => {
    let response;
    if (tabName === 'Sysco') {
      response = await fobeAxios.get(`api/SyscoEDI/ChangeStatus/?rest_id=${restuarantStatusChange?.restId}&Status=${restuarantStatusChange?.status}&folder_path=${restuarantStatusChange?.folderPath}`);
    } else if (tabName === 'PFG') {
      response = await fobeAxios.get(`api/PFSEDI/ChangeStatus/?rest_id=${restuarantStatusChange?.restId}&Status=${restuarantStatusChange?.status}&folder_path=${restuarantStatusChange?.folderPath}`);
    } else if (tabName === 'McLane') {
      response = await fobeAxios.get(`api/McLane/ChangeStatus/?rest_id=${restuarantStatusChange?.restId}&Status=${restuarantStatusChange?.status}&folder_path=${restuarantStatusChange?.folderPath}`);
    }
    else if (tabName === 'Benekeith') {
      response = await fobeAxios.get(`api/Benekeith/ChangeStatus/?rest_id=${restuarantStatusChange?.restId}&Status=${restuarantStatusChange?.status}&folder_path=${restuarantStatusChange?.folderPath}`);
    }
    else if (tabName === 'USFoods') {
      response = await fobeAxios.post(`api/USFEDI/ChangeStatus/?rest_id=${restuarantStatusChange?.restId}&Status=${restuarantStatusChange?.status}&folder_path=${restuarantStatusChange?.folderPath}`);
    }

    if (response?.data?.status === 1) {
      getMappingDetails();
      selectMap(selectMapValue);
      toast.success("Status Changed Successfully");
    } else {
      toast.error("Status Not Changed");
    }
    setStatusModalOpen(false);
  }

  useEffect(() => {
    getMappingDetails();
  }, []);

  useEffect(() => { selectMap(selectMapValue) }, [mapData])

  // useEffect(() => {
  //   if (selectedRestaurant !== null) {
  //     setRestMappingOpen(true);
  //   }
  // }, [selectedRestaurant]);

  useEffect(() => {
    if (restuarantStatusChange !== null) {
      setStatusModalOpen(true);
    }
  }, [restuarantStatusChange])

  const columns = useMemo(() => [
    {
      accessorKey: "Account_id",
      header: 'Account Id',
      editable: false,
      size: 200,
      enableColumnPinning: true,
      enableColumnResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ row }) => {
        return <span style={{ color: "#19A73F" }}>{tabName === 'McLane' ? row.original.Account_id + "_" + row.original.StoreNo : row.original.Account_id}</span>
      }
    },
    {
      accessorKey: "Rest_name",
      header: 'Restaurant Name',
      editable: false,
      size: 300,
      enableColumnPinning: true,
      enableColumnResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ row }) => {
        return (
          <>
            {row.original.Rest_name !== null &&
              <span>{row.original.Rest_name}</span>
            }
            {row.original.Rest_name === null &&
              <Select
                showSearch

                style={{
                  width: 150,
                }}
                disabled={RoleAccessManagement.hasReadAndWriteAccess('EDI Mapping')}
                placeholder='Select'
                options={restData?.map(item => ({ value: item?.value, label: item?.label }))}
                // defaultValue={row.original.Rest_name}
                value={row.index == Acctype && restData1 == 5 ? selectlabel : "select"}
                filterOption={(input, option) => (
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                )}
                onChange={(value) => mapRestaurantHandler(value, row.original, row.index)}
              />
            }
            {
              console.log(Acctype, restData1, "Rest_name")
            }
            {(row.original.Rest_name === null && restData1 == 5 && row.index == Acctype) &&

              <Select
                showSearch

                style={{
                  width: 150,
                }}
                disabled={RoleAccessManagement.hasReadAndWriteAccess('EDI Mapping')}
                placeholder='Select'
                options={restData2}
                // defaultValue={row.original.Rest_name}
                value={"select"}
                //row.index==Acctype?selectlabel:"select"
                filterOption={(input, option) => (
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                )}
                onChange={(value) => mapRestaurantHandler1(value, row.original, row.index)}
              />
            }
          </>
        )
      }
    },
    {
      accessorKey: "Folder_path",
      header: 'Folder Path',
      editable: false,
      size: 300,
      enableColumnPinning: true,
      enableColumnResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "Account_status",
      header: 'Status',
      editable: false,
      size: 230,
      filterVariant: 'select',
      filterSelectOptions: [
        { label: 'Active', value: '1' }, { label: 'De-Active', value: '0' }
      ],
      enableColumnPinning: true,
      enableColumnResizing: true,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (

          <Switch
            size="small"
            checked={row.original.Account_status === 1} // Use 'row.original.Status' to access the value
            value={row.original.Account_status}
            disabled={row.original.Rest_name === null || RoleAccessManagement.hasReadAndWriteAccess('EDI Mapping')}
            onChange={(e) => row.original.Account_status === 1 ? statusDeactiveHandler(row.original.Rest_id, row.original.Rest_name, row.original.Folder_path) : statusActiveHandler(row.original.Rest_id, row.original.Rest_name, row.original.Folder_path)}
          />
        )
      }
    },
  ],
    [values, Acctype, restData1, selectlabel]);



  const accountfilter = [

    <div>
      <Select
        onChange={getMappingDetail}
        style={{ width: '200%' }}
        options={[

          {
            value: '1',
            label: 'Fobesoft',
          },
          {
            value: '5',
            label: 'Fobegolf',
          },
        ]}
        defaultValue={'1'}
      >
      </Select>
    </div>
  ]
  const ediFilter = [
    <div>
      <Select
        onChange={selectMap}
        style={{ width: '200%' }}
        options={[
          {
            value: '1',
            label: 'All',
          },
          {
            value: '2',
            label: 'Un Mapped',
          },
          {
            value: '3',
            label: 'Mapped',
          },
        ]}
        defaultValue={'1'}
      >
      </Select>
    </div>


  ]

  const handleDropdown5Change = async (value) => {

    setDropdown5Value(value)
    if (value === '0') {
      setValues(mapData);
    } else if (value === '4') {
      setValues(mapData?.filter(item => item?.Rest_name === null));
    } else if (value === '5') {
      setValues(mapData?.filter(item => item?.Acc_id == 5));
    }
    else if (value === '1') {
      setValues(mapData?.filter(item => item?.Acc_id === null && item?.Rest_name !== null));
    }

  }





  return (
    <div className='mapFilterOuter'>
      <div className='useertable'>
        <CommonTable
          rows={values}
          columns={columns}
          table={tabName}
          isLoading={loading}
          HiddenColumnlist={{}}
          headFilter={ediFilter}
          accountfilter={selectMapValue == 3 ? accountfilter : null}
          tabName={tabName}
          dropdown5Value={dropdown5Value}
          setDropdown5Value={setDropdown5Value}
          handleDropdown5Change={handleDropdown5Change}
        />
      </div>
      <Modal
        title="Restaurant Mapping"
        open={restMappingOpen}
        onCancel={handleClose}
        maskClosable={false}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: '#19A73F',
                color: '#fff',
                borderRadius: '3px',
                border: 'none'
              }}
              disabled={disable}
              onClick={confirmMapHandler}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <p style={{ textAlign: 'center' }}>Are you sure you want to map
            <strong> {selectedRestaurant?.label}</strong> to
            <strong> {tabName === 'McLane' ? rowData?.Account_id + "_" + rowData?.StoreNo : rowData?.Account_id}</strong></p>
        </div>
      </Modal>
      <Modal
        title="Status"
        open={statusModalOpen}
        onCancel={handleClose}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              style={{
                backgroundColor: '#19A73F',
                color: '#fff',
                borderRadius: '3px',
                border: 'none'
              }}
              onClick={statusHandler}
            >
              Yes
            </Button>
          </div>
        ]}
      >
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <p style={{ textAlign: 'center' }}>Are you sure you want to {restuarantStatusChange?.status === 'Active' ? 'Activate' : 'Deactivate'} the
            <strong> {restuarantStatusChange?.restName}</strong> EDI Connection?</p>
        </div>
      </Modal>
    </div>
  )
}

export default Sysco;
