import React, { useState } from 'react';
import Header from "../../../Header/header";
import { useNavigate, Link } from "react-router-dom";
import { Tabs } from 'antd';
import "../../User.css";
import UserInvoice from './UserInvoice';
import UserPayment from './UserPayment';
import UserProfile from './UserProfile';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { MyForm } from './UserPayment'
import { ToastContainer } from "react-toastify";
import { Form, Input, Button } from 'antd';
function UserDetailsInfo() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);
  let { Rest_name } = useParams();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  const items = [
    {
      key: '1',
      label: 'Profile',
      children: <UserProfile />,
    },
    {
      key: '2',
      label: 'Invoice',
      children: <UserInvoice />,
    },
    {
      key: '3',
      label: 'Payment',
      children: <UserPayment />,
    },
  ];
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    sessionStorage.clear();
    navigate("/");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const userinfo = [
    <>
      <div className='content-container'>
        <div className='containerford'>
          <div className='first_conteent'>
            <div className='col-md-12'>
              <div className='row'>
                <div role="presentation" onClick={handleClick} style={{ marginTop: '5px' }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/userdetails" style={{ color: '#19A73F', textDecoration: 'none', fontSize: '14px' }}>
                      <HomeIcon sx={{ mr: 0.5, mt: -0.5, width: '15px', height: '15px' }} />
                      UserDetails
                    </Link>
                    <Typography style={{ color: '#000', fontSize: '14px' }}><ManageAccountsIcon sx={{ mr: 0.5, mt: -0.5, fontSize: '15px', width: '15px', height: '15px' }} />{Rest_name}</Typography>
                  </Breadcrumbs>
                </div>
              </div>
              <div className='row'>
                <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ];
  return (
    <div>
      <ToastContainer autoClose={1500} />
      <Header
        handleMobileMenuClose={handleMobileMenuClose}
        anchorEl={anchorEl}
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleMenuClose={handleMenuClose}
        loginUserName={loginUserName}
        handleMenuCloseout={handleMenuCloseout}
        handleMobileMenuOpen={handleMobileMenuOpen}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        element={userinfo}
      />

    </div>
  );
}


export default UserDetailsInfo;
