import './App.css';
import { setAxiosDefauls } from './middleware/interceptor';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { lazy, Suspense, useEffect } from "react";
import Login from './Login';
import Invoice from './Invoice/Invoice';
import { useState } from 'react';
import Loader from './loader';
import { PrivateRoute } from "./middleware/PrivateRoute";
import UserManagement from './Admin/FobeTeam/UserManagement';
import UserDetails from './Admin/UsersDetails/UserDetails';
import POSTable from './Invoice/PosAccountTable';
import SplitPdf from './Invoice/split';
import PromoCode from './Admin/PromoCode/PromoCode';
import Restaurant from './Admin/AddHQ/AddRestaurant'
import Addhq from './Admin/AddHQ/AddHq';
import UserDetailsInfo from './Admin/UsersDetails/UserDetailsInfo/UserDetailsInfo';
import Billing from './Admin/Billing/Billing';
import EDI_Mapping from './Invoice/EDI Mapping/EDI_Mapping';
import Idel from './Idel';
import Pos from './Pointofsale/Pos';
import InstructionModal from './Pointofsale/InstructionModal';
import MappingInstruction from './Invoice/MappingInstruction/MappingInstruction';
import PossibleConnectionDashboard from './Dashboard/PossibleConnectionDashboard';

setAxiosDefauls();
function App() {
  const isBackgroundRed = true;
  const [isActive, setIsActive] = useState(true)
  const [validUser, setValidUser] = useState(false);

  const validateUser = (value) => {
    setValidUser(value);
  }

  useEffect(() => {
    const handleLocationChange = () => {
      if (!window.location.href.includes('pos')) {
        sessionStorage.removeItem('mrt_globalFilter_table_2');
        sessionStorage.removeItem('mrt_showGlobalFilter_table_2');
        sessionStorage.removeItem('mrt_columnFilters_table_2');
        sessionStorage.removeItem('mrt_showColumnFilters_table_2');
      }
    };

    // Monkey patching pushState and replaceState to detect programmatic URL changes
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(this, args);
      handleLocationChange();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      handleLocationChange();
    };

    // Listen for popstate events (browser back/forward navigation)
    window.addEventListener('popstate', handleLocationChange);

    // Cleanup
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return (
    <>
      <div className={isBackgroundRed ? 'background-red' : 'background-blue'} />
      <Loader isActive={isActive} />

      <Router>
        {validUser && <Idel validateUser={validateUser} />}
        <Routes>
          <Route path="/" element={<Login setIsActive={setIsActive} validateUser={validateUser} />} />
          <Route path="/invoice" element={<PrivateRoute><Invoice key='Invoice Process' /></PrivateRoute>} />
          <Route path="/split" element={<PrivateRoute><SplitPdf key='Invoice Process' /></PrivateRoute>} />
          <Route path="/usermanagement" element={<PrivateRoute><UserManagement key='Fobe Team' /></PrivateRoute>} />
          <Route path="/userdetails" element={<PrivateRoute><UserDetails key='Users' /></PrivateRoute>} />
          <Route path="userdetailsinfo/:Rest_name/:Account_id/:rest_id" element={<PrivateRoute><UserDetailsInfo key='Users' /></PrivateRoute>} />
          <Route path="/promocode" element={<PrivateRoute><PromoCode key='Promocode' /></PrivateRoute>} />
          <Route path="/hq" element={<PrivateRoute><Addhq key='HQ' /></PrivateRoute>} />
          <Route path="/billing" element={<PrivateRoute><Billing key='Billing' /></PrivateRoute>} />
          <Route path="/pos" element={<PrivateRoute><Pos key='Point of Sale' /></PrivateRoute>} />
          <Route path="/pos/mappinginstructions" element={<PrivateRoute><Pos key='Point of Sale' /></PrivateRoute>} />
          <Route path="/dashboard/possible_connection" element={<PossibleConnectionDashboard key={'Possible Connection Dashboard'} />} />
          <Route path='invoice/mappinginstructions' element={<PrivateRoute><MappingInstruction key='Invoice Process' /></PrivateRoute>} />
          <Route path="/EDI-Mapping" element={<PrivateRoute><EDI_Mapping key='EDI Mapping' /></PrivateRoute>} />
          <Route path="/hq/group-user/:groupId/:groupName" element={<PrivateRoute><Restaurant key='HQ' /></PrivateRoute>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
