import React from "react";
import "./filter.css";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Table, Space, Input, Tooltip,Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { BiEnvelope } from "react-icons/bi";
import { IconContext } from "react-icons";
import { ToastContainer, toast } from "react-toastify";
import { BiUpload } from "react-icons/bi";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { Spinner } from "../Invoice/spinner";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Dropdown, Menu } from "antd";
import { faBook, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { DownOutlined } from "@ant-design/icons";
import { faFileEarmark } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Bbutton from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import RoleAccessManagement from "../RoleAccessManagement";
const FilterSection = ({
  handleDropdown1Change,
  handleDropdown2Change,
  handleDropdown3Change,
  handleDropdown4Change,
  handleDropdown5Change,
  dropdown1Value,
  dropdown2Value,
  dropdown3Value,
  dropdown4Value,
  dropdown5Value,
  updatedate,
  currentDate,
  handleDateChange,
  getData,
  setLoading,
  loading,
  isLoading,
   setIsLoading
}) => {
  const navigate = useNavigate();
  const [AItotal, setAItotal] = useState(0);
  const [AIaccepte, setAIaccepte] = useState(0);
  const [AIdenied, setAIdenied] = useState(0);
  const [AIuntouched, setAIuntouched] = useState(0);
  const [AIaccepteper, setAIaccepteper] = useState(0);
  const [AIdeniedper, setAIdeniedper] = useState(0);
  const [AIuntouchedper, setAIuntouchedper] = useState(0);

  const [deletedropdown1Value, setdeletedropdown1Value] = useState("");
  const [deletedropdown2Value, setdeletedropdown2Value] = useState("");
  const [deletedropdown3Value, setdeletedropdown3Value] = useState("");
  const [AIdropdown1Value, setAIdropdown1Value] = useState("");
  const [sorter, setSorter] = useState({});
  const [filters, setFilters] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setdeletedropdown1Value("");
    setdeletedropdown2Value("");
    setdeletedropdown3Value("");
  };
  const handleShow = () => setShow(true);
  const [showAI, setShowAI] = useState(false);
  const handleCloseAI = () => setShowAI(false);
  const handleShowAI = () => setShowAI(true);
  const [tableData, setTableData] = useState([]);
  const [loadinghis, setloadinghis] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData1, setFilteredData1] = useState([]);
  const [filteredFullData, setfilteredFullData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { Search } = Input;
  //DATE VARIABLE DECLARATION
  const [Delupdatedate, setDelUpdatedate] = useState(() => {
    return new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .split("T")[0];
  });
  const [DelcurrentDate, setDelcurrentDate] = useState(() => {
    return new Date().toISOString().split("T")[0];
  });

  const [AIupdatedate, setAIUpdatedate] = useState(() => {
    return new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .split("T")[0];
  });

  const [AIcurrentDate, setAIcurrentDate] = useState(() => {
    return new Date().toISOString().split("T")[0];
  });

  //DATE FUNCTION FOR DELETE HISTORY
  function handleDeleteDateChange(event) {
    if (event.target.name === "Delupdatedate") {
      setDelUpdatedate(event.target.value);
    } else if (event.target.name === "DelcurrentDate") {
      setDelcurrentDate(event.target.value);
    }
  }
  //DATE FUNCTION FOR AI REPORT
  function handleAIDateChange(event) {
    if (event.target.name === "AIupdatedate") {
      setAIUpdatedate(event.target.value);
    } else if (event.target.name === "AIcurrentDate") {
      setAIcurrentDate(event.target.value);
    }
  }

  const items = [
    {
      key: "1",
      label: (
        <span
          onClick={() => {
            setShow(true);
            setSearchText("");
            getDeleteHistoryData();
          }}
        >
          <FontAwesomeIcon
            icon={faTrashAlt}
            style={{
              color: "red",
              marginRight: "0.5em",
            }}
          />
          Deleted History
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span onClick={() => setShowAI(true)}>
          <FontAwesomeIcon
            icon={faFile}
            style={{ color: "goldenrod", marginRight: "0.5em" }}
          />
          AI Report
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span onClick={()=>{navigate('/invoice/mappinginstructions')}}>
          <FontAwesomeIcon
            icon={faBook}
            style={{ color: "#4096ff", marginRight: "0.5em" }}
          />
            Mapping Instructions
        </span>
      ),
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const handleTableChange = (pagination, filters, sorter) => {
    // setPagination(pagination);
    setCurrentIndex(pagination.current - 1);
    setPageSize(pagination.pageSize);
    setFilters(filters);
    setSorter(sorter);
  };
  const showTotal = (total, range) => {
    return (
      <div style={{ textAlign: "left" }}>
        Showing {range[0]} to {range[1]} of {total} entries
      </div>
    );
  };
  //AI REPORT DATA WHILE DROPDOWN VALUE CHANGE
  const handleAIdropdown1Value = async (e) => {
    setAIdropdown1Value(e.target.value);

    let data = {
      startat: new Date(AIupdatedate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),
      endat: new Date(AIcurrentDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),
      reportkey: "reportkey",
      keyvalue: "",
      key: e.target.value,
    };
    await fobeAxios.post("api/Common/OpenAiReport", data).then((resp) => {
      console.log(resp.data.data, "AI REPORT DATA");
      setAItotal(resp.data.data.Total);
      setAIaccepte(resp.data.data.MicrosoftAiAccept);
      setAIdenied(resp.data.data.MicrosoftAiReject);
      setAIuntouched(resp.data.data.MicrosoftAiUntouch);

      setAIaccepteper(resp.data.data.MicrosoftAiAcceptPer);
      setAIdeniedper(resp.data.data.MicrosoftAiRejectPer);
      setAIuntouchedper(resp.data.data.MicrosoftAiUntouchPer);
    });
  };
  //AI REPORT DATA
  const AIReportData = async () => {
    let data = {
      startat: new Date(AIupdatedate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),
      endat: new Date(AIcurrentDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),
      reportkey: "reportkey",
      keyvalue: "",
      key: AIdropdown1Value,
    };
    await fobeAxios.post("api/Common/OpenAiReport", data).then((resp) => {
      console.log(resp.data.data, "AI REPORT DATA");
      setAItotal(resp.data.data.Total);
      setAIaccepte(resp.data.data.MicrosoftAiAccept);
      setAIdenied(resp.data.data.MicrosoftAiReject);
      setAIuntouched(resp.data.data.MicrosoftAiUntouch);
      setAIaccepteper(resp.data.data.MicrosoftAiAcceptPer);
      setAIdeniedper(resp.data.data.MicrosoftAiRejectPer);
      setAIuntouchedper(resp.data.data.MicrosoftAiUntouchPer);
    });
  };
  //DELETE HISTORY DATA
  const getDeleteHistoryData = async () => {
    const res = await fobeAxios.get(
      "api/Daily/GetDeletedinvoice_details/" +
        Delupdatedate +
        "," +
        DelcurrentDate
    );
    console.log("invoice_details", res.data);
    const data = res.data.Deleted_Invoice.map((item) => ({
      Upload_id: item.invoice_details.Upload_id,
      Rest_id: item.invoice_details.Rest_id,
      ownername: item.invoice_details.ownername,
      company: item.invoice_details.company,
      filename: item.invoice_details.filename,
      date: item.invoice_details.date,
      invoice_from:
        item.invoice_details.invoice_from == null
          ? "INVOICE_UPLOAD"
          : item.invoice_details.invoice_from == ""
          ? "INVOICE_UPLOAD"
          : item.invoice_details.invoice_from == "SPLIT_PDF"
          ? "INVOICE_UPLOAD"
          : item.invoice_details.invoice_from,
      split_pdf:
        item.invoice_details.invoice_from == "SPLIT_PDF" ? true : false,
      file_status: item.invoice_details.file_status,
      invoicefrom_mail: item.invoice_details.invoicefrom_mail,
      Completed_date: item.invoice_details.Completed_date,
      Deleted_Reason: item.invoice_details.Deleted_Reason,
      Account_Id: item.invoice_details.AccountID,
      // Automatic_mapping:item.rest_details.Automatic_mapping
    }));
    setTableData(data);
    setFilteredData(data);
    setFilteredData1(data);
    setfilteredFullData(data);
  };

  useEffect(() => {
    check();
  }, [deletedropdown1Value, deletedropdown2Value]);

  const check = async () => {
    setloadinghis(true);
    let tempData = filteredData1.filter((item) => {
      if (deletedropdown1Value !== "" && deletedropdown2Value !== "") {
        return (
          item.invoice_from === deletedropdown1Value &&
          item.Deleted_Reason === deletedropdown2Value
        );
      } else if (deletedropdown1Value !== "" && deletedropdown2Value === "") {
        return item.invoice_from === deletedropdown1Value;
      } else if (deletedropdown1Value === "" && deletedropdown2Value !== "") {
        return item.Deleted_Reason == deletedropdown2Value;
      } else {
        return true;
      }
    });
    setTableData(tempData);
    setFilteredData(tempData);
    setTimeout(() => {
      setloadinghis(false);
    }, 100);
    console.log("aerwter", filteredData, filteredData1, deletedropdown3Value);
  };

  //DELETE HISTORY DATA WHILE DROPDOWN VALUE CHANGE
  const handledeletedropdown1Value = (e) => {
    setdeletedropdown1Value(e.target.value);
    if (e.target.value != "") {
      let filter = filteredData.filter(
        (item) => item.invoice_from == e.target.value
      );
      setTableData(filter);
    } else {
      setTableData(filteredData);
    }
  };
  const handledeletedropdown2Value = (e) => {
    setdeletedropdown2Value(e.target.value);
    console.log("e.target.value", e.target.value);
    if (e.target.value != "") {
      let filter = filteredData.filter(
        (item) => item.Deleted_Reason == e.target.value
      );
      setTableData(filter);
    } else {
      setTableData(filteredData);
    }
  };
  const handledeletedropdown3Value = (e) => {
    console.log(
      "e.target.valuefilteredFullData",
      e.target.value,
      filteredFullData
    );
    setdeletedropdown3Value(e.target.value);
    if (e.target.value != "") {
      let filter = filteredFullData.filter(
        (item) => item.Account_Id == e.target.value
      );
      setTableData(filter);
      setFilteredData(filter);
      setFilteredData1(filter);
    } else {
      setTableData(filteredFullData);
      setFilteredData(filteredFullData);
      setFilteredData1(filteredFullData);
    }
  };
  // DELETE HISTORY SEARCH
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value) {
      let filtered = filteredData.filter((record) => {
        return Object.values(record).some(a =>
          (typeof a === 'string' || typeof a === 'number') &&
          a.toString().toLowerCase().includes(value.toLowerCase()))
      })

      // const filtered = filteredData.filter((record) => {
      //   const words = Object.values(record).join(" ").split(" ");
      //   return words.some((word) => {
      //     return word.toLowerCase().startsWith(value.toLowerCase());
      //   });
      // });
      setTableData(filtered);
    } else {
      setTableData(filteredData);
    }
  };
  useEffect(() => {
    getDeleteHistoryData();
    AIReportData();
  }, []);
  useEffect(() => {
    // const { current, pageSize } = pagination;
    document.documentElement.style.backgroundColor = "#fff";
    const filteredData = tableData.filter((item) => {
      let match = true;

      Object.keys(filters).forEach((key) => {
        const filterValue = filters[key][0];

        if (filterValue && item[key] !== filterValue) {
          match = false;
        }
      });

      return match;
    });

    const sortedData = sorter.field
      ? filteredData.sort((a, b) => {
          if (sorter.order === "descend") {
            return b[sorter.field] - a[sorter.field];
          } else {
            return a[sorter.field] - b[sorter.field];
          }
        })
      : filteredData;

    // const start = (current - 1) * pageSize;
    // const end = start + pageSize;
    // const paginatedData = sortedData.slice(start, end);

    // setTableData(paginatedData);
  }, [filters, sorter]);
  const columns = [
    {
      title: "INV UPLOAD DATE",
      dataIndex: "date",
      width: 200,
      align: "center",
      key: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: (dateString, record) => {
        const formatDateforInvoiceDate = (dateStr) => {
          const date = new Date(dateStr);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${month}/${day}/${year}`;
        };
        const date = new Date(dateString);
        const formattedTime = date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        const amPm = date.getHours() >= 12 ? "PM" : "AM";
        return (
          <>
            <div>
              {record.invoice_from === "From_Mail" && (
                <>
                  <BiEnvelope
                    style={{
                      fontSize: "28px",
                      marginRight: "0.2em",
                      marginTop: "-0.3em",
                      color: "#2196F3",
                    }}
                    className="icon-styles"
                    data-mattooltip="Invoice Email"
                  />
                  <span>
                    {" "}
                    {formatDateforInvoiceDate(dateString)} {formattedTime}
                  </span>
                </>
              )}
              {(record.invoice_from === "SPLIT_PDF" || record.invoice_from === "MERGE_PDF" ||
                record.invoice_from == "INVOICE_UPLOAD") && (
                <>
                  <BiUpload
                    style={{
                      fontSize: "28px",
                      marginRight: "0.2em",
                      marginTop: "-0.3em",
                      color: "#2196F3",
                    }}
                    className="icon-styles"
                    data-mattooltip="Invoice Email"
                  />
                  <span>
                    {" "}
                    {formatDateforInvoiceDate(dateString)} {formattedTime}
                  </span>
                </>
              )}
              {record.invoice_from === "SYSCO EDI" && (
                <>
                  <img
                    src="https://dev.fobesoft.com/assets/img/sys1.png"
                    style={{ width: "1.8em", margin: "-3px 5px" }}
                    data-mattooltip="Sysco EDI"
                  />
                  <span>
                    {" "}
                    {formatDateforInvoiceDate(dateString)} {formattedTime}
                  </span>
                </>
              )}
              {record.invoice_from === "PFS EDI" && (
                <>
                  <img
                    src="https://dev.fobesoft.com/assets/img/PFG.png"
                    style={{ width:"2.8em", margin: "0px 6px 3px -10px" }}
                    data-mattooltip="PFG EDI"
                  />
                  <span>
                    {" "}
                    {formatDateforInvoiceDate(dateString)} {formattedTime}
                  </span>
                </>
              )}
              {record.invoice_from === "MCLANE EDI" && (
                <>
                  <img
                    src="https://dev.fobesoft.com/assets/img/McLANE.png"
                    style={{ width:"2.8em", margin: "0px 6px 3px -10px" }}
                    data-mattooltip="McClane EDI"
                  />
                  <span>
                    {" "}
                    {formatDateforInvoiceDate(dateString)} {formattedTime}
                  </span>
                </>
              )}
                  {record.invoice_from ==="Benekeith EDI" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/Benekeith.jpg"
                        style={{ width: "2em", margin: "0px 6px 3px -10px" }}
                        data-mattooltip="Benekeith EDI"
                  />
                  <span>
                    {" "}
                    {formatDateforInvoiceDate(dateString)} {formattedTime}
                  </span>
                </>
              )}
              {record.invoice_from === "USFOOD" && (
                <>
                  <img
                    src="https://dev.fobesoft.com/assets/img/Usfoods.png"
                    style={{ width: "2em", margin: "0px 6px 3px -10px" }}
                    data-mattooltip="USFOOD"
                  />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
            </div>
            {/* {formattedDate} {formattedTime} */}
          </>
        );
      },
    },
    {
      title: "DELETE DATE",
      dataIndex: "Completed_date",
      width: 180,
      align: "center",
      key: "Completed_date",
      sorter: (a, b) => a.Completed_date.localeCompare(b.Completed_date),
      render: (dateString, record) => {
        const formatDateforInvoiceDate = (dateStr) => {
          const date = new Date(dateStr);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${month}/${day}/${year}`;
        };
        const date = new Date(dateString);
        const formattedTime = date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        const amPm = date.getHours() >= 12 ? "PM" : "AM";
        return (
          <>
            {formatDateforInvoiceDate(dateString)} {formattedTime}
          </>
        );
      },
    },
    {
      title: "RESTAURANT NAME",
      dataIndex: "company",
      key: "company",
      align: "center",
      width: 200,
      sorter: (a, b) => a.company.localeCompare(b.company),
      render: (text, record, index) => {
        return (
          <>
            <div>{text}</div>
            {record.invoice_from == "From_Mail" && (
              <div
                style={{ fontSize: "10px", color: "grey", fontStyle: "italic" }}
              >
                {record.invoicefrom_mail}
              </div>
            )}
            {record.split_pdf && (
              <div
                style={{ fontSize: "10px", color: "grey", fontStyle: "italic" }}
              >
                Splitted Invoice
              </div>
            )}
          </>
        );
      },
    },
    // {
    //   title: "INVOICE FROM",
    //   dataIndex: "invoice_from",
    //   key: "invoice_from",
    //   width: 200,
    //   sorter: (a, b) => a.company.localeCompare(b.invoice_from),

    //   render: (text, record, index) => {
    //     return (
    //       <>
    //         <div>
    //           {text === "From_Mail" && (
    //             <ul
    //               style={{
    //                 listStyle: "none",
    //               }}
    //             >
    //               {record.invoicefrom_mail}
    //               <li
    //                 style={{
    //                   fontSize: "28px",
    //                 }}
    //               >
    //                 <IconContext.Provider value={{ color: "#4ac8df" }}>
    //                   <BiEnvelope
    //                     className="icon-styles"
    //                     data-mattooltip="Invoice Email"
    //                   />
    //                 </IconContext.Provider>
    //               </li>
    //             </ul>
    //           )}
    //           {(text === "SPLIT_PDF" || text == "INVOICE_UPLOAD") && (
    //             <ul
    //               style={{
    //                 listStyle: "none",
    //               }}
    //             >
    //               {record.split_pdf && <li>Splitted Invoice</li>}

    //               <li
    //                 style={{
    //                   fontSize: "28px",
    //                 }}
    //               >
    //                 {" "}
    //                 <IconContext.Provider value={{ color: "#4ac8df" }}>
    //                   <BiUpload
    //                     className="icon-styles"
    //                     data-mattooltip="Invoice Email"
    //                   />
    //                 </IconContext.Provider>
    //               </li>
    //             </ul>
    //           )}

    //           {text === "SYSCO EDI" && (
    //             <img
    //               src="https://dev.fobesoft.com/assets/img/sys.svg"
    //               style={{ width: "4em", margin: "-5px 0px -10px 0px" }}
    //               data-mattooltip="Sysco EDI"
    //             />
    //           )}
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "REASON",
      dataIndex: "Deleted_Reason",
      key: "Deleted_Reason",
      align: "center",
      width: 150,
      sorter: (a, b) => a.Deleted_Reason.localeCompare(b.Deleted_Reason),
      render: (text, record, index) => {
        return (
          <>
            <div>{text ? text : "-"}</div>
          </>
        );
      },
    },
    {
      title: "ACTION",
      dataIndex: "Deleted_Reason",
      key: "Deleted_Reason",
      align: "center",
      width: 130,
      sorter: (a, b) => a.Deleted_Reason.localeCompare(b.Deleted_Reason),
      render: (text, record, index) => {
        function viewinvoice(record) {
          window.open(record);
        }
        const RevertInvoice = async (e) => {
          console.log("Revet", e);
          var req = {
            Rest_id: e.Rest_id,
            upload_id: e.Upload_id,
          };
          await fobeAxios
            .post("/api/Daily/revertDeletedInvoice", req)
            .then((res) => {
              console.log("res", res);
              if (res.data.status == 1) {
                toast.success(res.data.message);
                getData();
                getDeleteHistoryData();
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        };
        return (
          <>
            <div
              class="col-md-12 col-xs-12 col-sm-12"
              style={{ paddingLeft: "1em", paddingRight: "1em" }}
            >
              <div class="row d-flex">
                <div class="col-md-6 col-xs-6 col-sm-6">
                  <Tooltip title="View Invoice">
                    <RemoveRedEyeIcon
                      onClick={() => !RoleAccessManagement.hasReadAndWriteAccess('Invoice Process') && viewinvoice(record.filename)}
                      className="icon-style icon-view"
                      style={{
                        color: "#19A73F",
                        fontSize: "43px",
                        padding: "2px 8.5px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
                <div class="col-md-6 col-xs-6 col-sm-6">
                  <Tooltip title="Revert Invoice">
                    <RotateRightIcon
                      onClick={() => !RoleAccessManagement.hasReadAndWriteAccess('Invoice Process') && RevertInvoice(record)}
                      className="icon-style icon-view"
                      style={{
                        color: "#19A73F",
                        fontSize: "42px",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
        <div class="row mt-2 mb-4">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div class="row" style={{ marginTop: "0.6em" }}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="commondiv">
                  <div
                    class="col"
                    style={{ cursor: "pointer", padding: "10px" }}
                    className={dropdown5Value == "" ? "activefortab" : ""}
                    onClick={() => handleDropdown5Change("")}
                  >
                    <span>All</span>
                  </div>
                  <div
                    class="col"
                    style={{ cursor: "pointer", padding: "10px" }}
                    className={dropdown5Value == "1" ? "activefortab" : ""}
                    onClick={() => handleDropdown5Change("1")}
                  >
                    <span>Fobesoft</span>
                  </div>
                  <div
                    class="col"
                    style={{ cursor: "pointer", padding: "10px" }}
                    className={dropdown5Value == "5" ? "activefortab" : ""}
                    onClick={() => handleDropdown5Change("5")}
                  >
                    <span>Fobegolf</span>
                  </div>
                </div>
                {/* <Tab.Container id="left-tabs-example" defaultActiveKey={dropdown5Value} onSelect={(value) => {
                    handleDropdown5Change(value);
                  }}>
                  <Nav variant="pills" className="flex-row borderstyle" >
                    <Nav.Item>
                      <Nav.Link className="tab" eventKey=""> All</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="tab" eventKey="1"> Fobesoft</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="tab" eventKey="5"> Fobegolf</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Tab.Container> */}
              </div>
            </div>
          </div>
          {/* <div className={dropdown3Value == 2  ? "col-xs-12 col-sm-12 col-md-7 col-lg-7" : "col-xs-12 col-sm-12 col-md-4 col-lg-4"}>
            <div class="row">
            <div className={dropdown3Value == 2  ? "col-xs-12 col-sm-4 col-md-4 col-lg-4" : "col-xs-12 col-sm-6 col-md-6 col-lg-6"}>
                <div class="col-xs-12">
                  <label style={{ fontSize: "14px", color: "#666666" }}>Account Type:</label>
                </div>
                <div class="col-xs-9 mt-2">
                  <select
                    className="form-control"
                    value={dropdown1Value}
                    style={{ width: '100%' }}
                    onChange={handleDropdown1Change}
                  >
                    <option value="">All</option>
                    <option value="INVOICE_UPLOAD">Invoice Upload</option>
                    <option value="SYSCO EDI">Sysco EDI</option>
                    <option value="From_Mail">Invoice Email</option>
                  </select>
                </div>
              </div>
              <div className={dropdown3Value == 2  ? "col-xs-12 col-sm-3 col-md-3 col-lg-3" : "col-xs-12 col-sm-4 col-md-4 col-lg-4"}>
                <div class="col-xs-12 ">
                  <label style={{ fontSize: "14px", color: "#666666" }}>Budget type :</label>
                </div>
                <div class="col-xs-9 mt-2">
                  <select
                    className="form-control"
                    value={dropdown2Value}
                    style={{ width: '100%' }}
                    onChange={handleDropdown2Change}
                  >
                    <option value="">Select</option>
                    <option value="2">12 Months</option>
                    <option value="1">13 Period</option>
                  </select>
                </div>
              </div> 
            <div className={dropdown3Value == 2 ? "col-xs-12 col-sm-4 col-md-4 col-lg-4" : "col-xs-12 col-sm-6 col-md-6 col-lg-6"}>
                <div class="col-xs-12 ">
                  <label style={{ fontSize: "14px", color: "#666666" }}>Status :</label>
                </div>
                <div class="col-xs-9 mt-2">
                  <select
                    className="form-control"
                    value={dropdown3Value}
                    style={{ width: '95%' }}
                    onChange={handleDropdown3Change}
                  >
                    <option value="">All</option>
                    <option value="1">Yet to Start</option>
                    <option value="5">Processing</option>
                    <option value="2">Completed</option>
                    <option value="3">Re-scan</option>
                    <option value="4">Flagged</option>
                    <option value="6">Re-Scanned</option>
                    <option value="7">Duplicate</option>
                  </select>
                </div>
              </div> 
              {dropdown3Value == 2  && <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <div class="col-xs-12 ">
                  <label style={{ fontSize: "14px", color: "#666666" }}>Status :</label>
                </div>
                <div class="col-xs-9 mt-2">
                  <select
                    className="form-control"
                    value={dropdown4Value}
                    style={{ width: '95%' }}
                    onChange={handleDropdown4Change}
                  >
                    <option value=''>All</option>
                    <option value='0'>Manual </option>
                    <option value='1'>Automatic</option>
                  </select>
                </div>
              </div>}
            </div>
          </div> */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div class="row" style={{ marginLeft: "0px" }}>
              <div class="col-md-4" style={{ padding: "0" }}>
                <div class="col-xs-12 " style={{ paddingLeft: "0" }}>
                  <label style={{ fontSize: "14px", color: "#666666" }}>
                    From :
                  </label>
                </div>
                <div class="col-xs-9 mt-2" style={{ padding: "0" }}>
                  <input
                    className="date-pck-inc invoice-date-pck-inc"
                    type="date"
                    value={updatedate}
                    max={currentDate}
                    name="updatedate"
                    onKeyDown={(e) =>{
                      e.preventDefault();
                    }}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              <div class="col-md-4" style={{ padding: "0" }}>
                <div class="col-xs-12 " style={{ padding: "0" }}>
                  <label style={{ fontSize: "14px", color: "#666666" }}>
                    To :
                  </label>
                </div>
                <div class="col-xs-9 mt-2" style={{ padding: "0" }}>
                  <input
                    className="date-pck-inc invoice-date-pck-inc"
                    type="date"
                    value={currentDate}
                    min={updatedate}
                    name="currentDate"
                    onKeyDown={(e) =>{
                      e.preventDefault();
                    }}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              <div class="col-md-4" style={{ padding: "0" }}>
                <Bbutton
                  type="button"
                  class="btn btn-success"
                  onClick={getData}
                  style={{
                    fontSize: "13px",
                    borderRadius: "3px",
                    marginTop: "33px",
                    background: "#19a73f",
                    border: "none",
                  }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                      color: "#fff",
                      marginRight: "3px",
                    }}
                  />
                  {isLoading ? (
                    <>
                      <span>Searching.. </span>

                       <Spin
                        indicator={
                          <LoadingOutlined style={{ color: "white" }} />
                        }
                      />
                    </>
                  ) : "Search"}
                </Bbutton>
              </div>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-1 col-md-2 col-lg-5"
            style={{ textAlign: "right", marginTop: "31px" }}
          >
            <Dropdown menu={{ items }} placement="bottomLeft">
              <Button
                style={{
                  padding: "5px 20px",
                  backgroundColor: "#ff9800",
                  border: "none",
                }}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  color="white"
                  onClick={(e) => e.preventDefault()}
                />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <Modal size="lg" className="deleteModal" show={show} onHide={handleClose} centered>
        <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
          <Modal.Title>
            <h5 style={{ fontSize: "22px", marginBottom: "0" }}>
              Deleted Invoices
            </h5>
          </Modal.Title>
          <span
            className="close"
            onClick={() => {
              handleClose(false);
            }}
          >
            <span class="closeicon">&times;</span>
          </span>
        </Modal.Header>
        <Modal.Body style={{ padding: "35px", paddingTop: "1em" }}>
          <div className="col-md-12 mb-2">
            <div className="row">
              <div className="col-md-3">
                <label style={{ fontSize: "13px", color: "#666666" }}>
                  Account Type :
                </label>
                <select
                  className="form-control mt-2"
                  value={deletedropdown3Value}
                  onChange={handledeletedropdown3Value}
                  style={{ width: "90%" }}
                >
                  <option value="">All</option>
                  <option value="1">Fobesoft</option>
                  <option value="5">Fobegolf</option>
                </select>
              </div>
              <div className="col-md-3">
                <label style={{ fontSize: "13px", color: "#666666" }}>
                  Invoice Type :
                </label>
                <select
                  className="form-control mt-2"
                  value={deletedropdown1Value}
                  onChange={handledeletedropdown1Value}
                  style={{ width: "90%" }}
                >
                  <option value="">All</option>
                  <option value="INVOICE_UPLOAD">Invoice Upload</option>
                  <option value="SYSCO EDI">Sysco EDI</option>
                  <option value="PFS EDI">PFG EDI</option>
                  <option value="MCLANE EDI">McLane EDI</option>
                  <option value="Benekeith EDI">Ben E. Keith EDI</option>
                  <option value="USFOOD">Us Foods EDI</option>
                  <option value="From_Mail">Invoice Email</option>
                  <option value="SPLIT_PDF">Split Invoice</option>
                  <option value="MERGE_PDF">Merge Invoice</option>
                </select>
              </div>
              <div className="col-md-3">
                <label style={{ fontSize: "13px", color: "#666666" }}>
                  Reason :
                </label>
                <select
                  className="form-control mt-2"
                  value={deletedropdown2Value}
                  style={{ width: "90%" }}
                  onChange={handledeletedropdown2Value}
                >
                  <option value="">All</option>
                  <option value="Duplicate Invoice">Duplicate Invoice</option>
                  <option value="Not An Invoice">Not An Invoice</option>
                  <option value="Test Account">Test Account</option>
                  <option value="De-Activated Account">
                    De-Activated Account
                  </option>
                  <option value="Recurring Expense">Recurring Expense</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="row mt-4">
              <div className="col-md-7 col-lg-6">
                <div className="row">
                  <div className="col-md-5">
                    <div className="row">
                      <div class="col-md-5">
                        <div class="col-xs-12 ">
                          <label style={{ fontSize: "13px", color: "#666666" }}>
                            From :
                          </label>
                        </div>
                        <div class="col-xs-9">
                          <input
                            className="date-pck-inc mt-2"
                            type="date"
                            value={Delupdatedate}
                            max={DelcurrentDate}
                            name="Delupdatedate"
                            onKeyDown={(e) =>{
                              e.preventDefault();
                            }}
                            onChange={handleDeleteDateChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div class="col-md-5" style={{ padding: "0" }}>
                        <div class="col-xs-12 " style={{ padding: "0" }}>
                          <label style={{ fontSize: "13px", color: "#666666" }}>
                            To :
                          </label>
                        </div>
                        <div class="col-xs-9" style={{ padding: "0" }}>
                          <input
                            className="date-pck-inc mt-2"
                            type="date"
                            value={DelcurrentDate}
                            min={Delupdatedate}
                            name="DelcurrentDate"
                            onKeyDown={(e) =>{
                              e.preventDefault();
                            }}
                            onChange={handleDeleteDateChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <button
                      type="button"
                      style={{
                        textAlign: "left",
                        marginTop: "30px",
                        padding: "5px 8px 3px 8px",
                        borderRadius: "50%",
                        marginLeft: "7px",
                      }}
                      class="btn src-invds"
                      onClick={getDeleteHistoryData}
                    >
                      <Tooltip title="Search">
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{
                            color: "rgb(25, 167, 63)",
                          }}
                        />
                      </Tooltip>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-lg-6 mt-3">
                <Search
                  placeholder="Search"
                  onChange={handleSearch}
                  value={searchText || ""}
                  style={{
                    width: "300",
                    marginBottom: "15px",
                    float: "right",
                    marginTop: "15px",
                    width: "220px",
                  }}
                />
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100", "500"],
              showTotal: showTotal,
            }}
            onChange={handleTableChange}
            filters={filters}
            sorter={sorter}
            showsizeChanger={true}
            scroll={{ x: 600, y: 500 }}
            loading={loadinghis}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showAI} onHide={handleCloseAI} top className="reportmodal" style={{marginTop:'8em'}}>
        <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
          <Modal.Title>
            <h5 style={{ fontSize: "22px", marginBottom: "0" }}>AI Report</h5>
          </Modal.Title>
          <span
            className="close"
            onClick={() => {
              handleCloseAI(false);
            }}
          >
            <span class="closeicon">&times;</span>
          </span>
        </Modal.Header>
        <Modal.Body style={{ padding: "14px 40px" }}>
          <div className="col-md-12">
            <div className="row">
              {/* <div className="col-md-3">
                <label style={{ fontSize: "13px", color: "#666666" }}>Invoice Type :</label>
                <select
                  className="form-control mt-2"
                  value={AIdropdown1Value}
                  style={{ width: '90%' }}
                  onChange={handleAIdropdown1Value}
                >
                  <option value="">Invoice Upload</option>
                  <option value="syscoedi">Sysco EDI</option>

                </select>
              </div> */}
              <div className="col-md-7">
                <div className="row">
                  <div class="col-md-4" style={{ padding: "0" }}>
                    <div class="col-xs-12 ">
                      <label style={{ fontSize: "13px", color: "#666666" }}>
                        From :
                      </label>
                    </div>
                    <div class="col-xs-9">
                      <input
                        className="date-pck-inc mt-2"
                        type="date"
                        value={AIupdatedate}
                        max={AIcurrentDate}
                        name="AIupdatedate"
                        onKeyDown={(e) =>{
                          e.preventDefault();
                        }}
                        onChange={handleAIDateChange}
                      />
                    </div>
                  </div>
                  <div class="col-md-4" style={{ padding: "0" }}>
                    <div class="col-xs-12 " style={{ padding: "0" }}>
                      <label style={{ fontSize: "13px", color: "#666666" }}>
                        To :
                      </label>
                    </div>
                    <div class="col-xs-9" style={{ padding: "0" }}>
                      <input
                        className="date-pck-inc mt-2"
                        type="date"
                        value={AIcurrentDate}
                        min={AIupdatedate}
                        name="AIcurrentDate"
                        onKeyDown={(e) =>{
                          e.preventDefault();
                        }}
                        onChange={handleAIDateChange}
                      />
                    </div>
                  </div>
                  <div class="col-md-4" style={{ padding: "0" }}>
                    <Bbutton
                      type="button"
                      class="btn btn-success"
                      onClick={AIReportData}
                      style={{
                        fontSize: "13px",
                        borderRadius: "3px",
                        marginTop: "33px",
                        background: "#19a73f",
                        border: "none",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{
                          color: "#fff",
                          marginRight: "3px",
                        }}
                      />
                      Search
                    </Bbutton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div
                className="head5"
                style={{ marginTop: "1em", marginBottom: "0.4em" }}
              >
                <h5
                  style={{
                    textAlign: "center",
                    marginBottom: "1em",
                    color: "#444444",
                    fontSize: "20px",
                  }}
                >
                  Microsoft AI
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <Tooltip 
                // title="Download Record" 
                placement="bottom">
                  <div
                    className="card-counter primary"
                    style={{
                      // cursor: "pointer",
                      touchAction: "none",
                      userSelect: "none",
                    }}
                  >
                    <i class="fa fa-database" style={{ marginTop: "-7px" }}></i>
                    <span class="count-numbers">{AItotal}</span>
                    <span class="count-namer">TOTAL</span>
                  </div>
                </Tooltip>
              </div>
              <div class="col-md-3">
                <Tooltip 
                // title="Download Record" 
                placement="bottom">
                  <div
                    className="card-counter success"
                    style={{
                      // cursor: "pointer",
                      touchAction: "none",
                      userSelect: "none",
                    }}
                  >
                    <i class="fa fa-check" style={{ marginTop: "-7px" }}></i>
                    <span class="count-numbers">{AIaccepte}</span>
                    <p>
                      <span class="count-name">ACCEPTED :&nbsp;&nbsp;</span>
                      <span class="count-names">{AIaccepteper}%</span>
                    </p>
                    <span class="count-per"></span>
                  </div>
                </Tooltip>
              </div>
              <div class="col-md-3">
                <Tooltip 
                // title="Download Record" 
                placement="bottom">
                  <div
                    className="card-counter danger"
                    style={{
                      // cursor: "pointer",
                      touchAction: "none",
                      userSelect: "none",
                    }}
                  >
                    <i class="fa fa-ban" style={{ marginTop: "-7px" }}></i>
                    <span class="count-numbers">{AIdenied}</span>
                    <p>
                      <span class="count-name">DENIED :</span>
                      <span class="count-names">{AIdeniedper}%</span>
                    </p>
                    <span class="count-per"></span>
                  </div>
                </Tooltip>
              </div>
              <div class="col-md-3">
                <Tooltip 
                // title="Download Record" 
                placement="bottom">
                  <div
                    className="card-counter secondary"
                    mattooltip="Download Record"
                    style={{
                      // cursor: "pointer",
                      touchAction: "none",
                      userSelect: "none",
                    }}
                  >
                    <i class="fa fa-clock" style={{ marginTop: "-7px" }}></i>
                    <span class="count-numbers">{AIuntouched}</span>
                    <p>
                      <span class="count-name">UNTOUCHED :&nbsp;&nbsp;</span>
                      <span class="count-names">{AIuntouchedper}%</span>
                    </p>
                    <span class="count-per"></span>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <div className="col-md-12">
            <div className="row">
              <div className="reddan" style={{ textAlign: "center" }}>
                <Bbutton
                  variant="contained"
                  color="error"
                  className="btn btn-danger"
                  onClick={() => handleCloseAI(false)}
                  style={{
                    fontSize: "13px",
                    borderRadius: "3px",
                    marginTop: "5px",
                    marginBottom: "10px",
                    background: "#ff4d4d",
                    border: "none",
                  }}
                >
                  CLOSE
                </Bbutton>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterSection;
