import { Modal, Button } from 'antd'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'

const timeout = 720_000
const promptBeforeIdle = 420_000

const Idel = ({ validateUser }) => {
  // const [state, setState] = useState('Active')
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate();

  const onIdle = () => {
    // setState('Idle')
    setOpen(false)
    navigate('/')
    validateUser(false);
    sessionStorage.clear();
  }

  const onActive = () => {
    // setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    // setState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 2000))
    }, 500)
    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

  return (
    <>
      <div className='Session'>
        <Modal
          title="Session Expired"
          open={open}
          maskClosable={false}
          width={450}
          closable={false}
          footer={[
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                style={{
                  backgroundColor: 'rgb(250, 89, 89)',
                  color: '#fff',
                  borderRadius: '3px',
                  border: '0.5px solid rgb(250, 89, 89)',
                }}
                onClick={onIdle}>
                No
              </Button>
              <Button
                className='addOnSaveBtn'
                style={{
                  backgroundColor: '#19A73F',
                  color: '#fff',
                  borderRadius: '3px',
                  border: 'none'
                }}
                onClick={handleStillHere}>
                Yes
              </Button>

            </div>
          ]}
        >
          <p style={{ textAlign: 'center', fontSize: '15px', fontWeight: '600', padding: '10px 0px', color: '#2f2d2d' }}>Your session is about to expire. Do you need more time?</p>
          <p style={{ textAlign: 'center', fontSize: '30px', fontWeight: '600', color: 'rgb(25, 167, 63)' }}>{remaining}</p>
          <p style={{ textAlign: 'center', fontSize: '14px', color: 'grey', marginTop: '-10px' }}>Seconds</p>

        </Modal>
      </div>
    </>
  )
}

export default Idel;
