import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, TextField, IconButton } from "@mui/material";
import {
  AttachFile,
  Image,
  Send,
  EmojiEmotions,
  TextFormat,
  Padding,
} from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import "./InstructionModal.css";
import ChatTranscript from "../components/chat/ChatTranscript";
import Header from "../Header/header";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
    ],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
  ],
};

const InstructionModal = ({ open, onClose, messages, onSend, setmessagekey, setmessage_id, message_id }) => {
  let navigate = useNavigate();
  const locationState = useLocation()?.state;

  let fobeuser = sessionStorage.getItem("fobeuser");
  let fobeusername = sessionStorage.getItem("fobeusername");
  let username = sessionStorage.getItem("Username");

  const [message, setMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(fobeusername == "" ? username == "" ? fobeuser : username : fobeusername);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    sessionStorage.clear();
    navigate("/");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSend = () => {
    if (message.trim()) {
      onSend(
        {
          "text": message,
          "sender": loginUserName,
          "images": [],
          "timestamp": new Date(),
          "msgStatus": 0,
          id: 0,
          key: 'add'

        }); // Call onSend from props
      setMessage("");
      messages.push({
        "text": message,
        "sender": loginUserName,
        "images": [],
        "timestamp": new Date(),
        "msgStatus": 0,
        id: null,
        key: 'add'
      })
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const element = [
    <>
      <div className="InstructionModal_container" style={{ position: 'relative' }}>
        <Box>
          <Breadcrumbs aria-label="breadcrumb" style={{ padding: '20px' }}>
            <Link underline="hover" to={"/pos"} color="inherit" style={{ color: '#19A73F', textDecoration: 'none', fontSize: '14px' }} onClick={onClose}>
              Point of sale
            </Link>
            <Typography style={{ color: '#000', fontSize: '14px' }}>
              {!!locationState ? locationState.company_name : "Mapping Instruction"}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ mt: 2, mb: 2 }} className="chat-container">
          <ChatTranscript
            messages={messages}
            updatemessage={onSend}
            setMessage={setMessage}
            setmessagekey={setmessagekey}
            setmessage_id={setmessage_id}
            message_id={message_id}
            isEditable={true}
          />
        </Box>

        <div className="chat-input">
          <ReactQuill
            value={message}
            onChange={(e) => { e === "<p><br></p>" ? setMessage("") : setMessage(e) }}
            theme="snow"
            modules={quillModules}
          />
          <IconButton onClick={handleSend}>
            <Send
              fontSize="medium"
              sx={{ color: message.trim() ? "#19a73f" : "#8E8E93" }}
            />
          </IconButton>
        </div>
      </div>
    </>
  ];

  return (
    <Header
      handleMobileMenuClose={handleMobileMenuClose}
      anchorEl={anchorEl}
      handleProfileMenuOpen={handleProfileMenuOpen}
      handleMenuClose={handleMenuClose}
      loginUserName={loginUserName}
      handleMenuCloseout={handleMenuCloseout}
      handleMobileMenuOpen={handleMobileMenuOpen}
      mobileMoreAnchorEl={mobileMoreAnchorEl}
      element={element}
    />
  );
};

export default InstructionModal;
