import React, { useState } from 'react';
// import ResponsiveAppBar from "../Header/header";
import Header from "./../../Header/header";
import { useNavigate } from "react-router-dom";
import "../User.css";
import { Tabs } from 'antd';
import UserDetailsTable from './UserDetailsTable';
import BillingDetailsTable from './BillingDetailsTable';
import { ToastContainer } from "react-toastify";

function UserTable() {
  const items = [
    {
      key: '1',
      label: 'Users',
      children: <UserDetailsTable />,
    },
    {
      key: '2',
      label: 'Billing Details',
      children: <BillingDetailsTable />,
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);
  let navigate = useNavigate();


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    sessionStorage.clear();
    navigate("/");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const detailuser = [
    <div className='content-container'>
      <div className='containerford'>
        <div className='first_conteent'>
          <div className='col-md-12'>
            <div className='row'>
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ]

  return (
    <>
      <div>
        <ToastContainer autoClose={1500} />
        <Header
          handleMobileMenuClose={handleMobileMenuClose}
          anchorEl={anchorEl}
          handleProfileMenuOpen={handleProfileMenuOpen}
          handleMenuClose={handleMenuClose}
          loginUserName={loginUserName}
          handleMenuCloseout={handleMenuCloseout}
          handleMobileMenuOpen={handleMobileMenuOpen}
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          element={detailuser}
        />




      </div>


    </>
  );
}


export default UserTable;
