import { useEffect, useState, useRef, useMemo } from 'react'
import { Grid } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { MdOutlineFileDownload } from 'react-icons/md'
import { Tooltip } from 'antd'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ExcelJS from 'exceljs'
const Table = ({
  columns,
  rows,
  table,
  userdetails,
  HiddenColumnlist,
  isLoading,
  FobeTeamList,
  salesteamlist,
  headFilter,
  accountfilter,
  remove,
  setremove,
  value,
  setvalue,
  setdeleted,
  deleted,
  setfobedel,
  fobedel,
  tabName,
  handleDropdown5Change,
  dropdown5Value,
  setDropdown5Value

}) => {
  const [columnVisibility, setColumnVisibility] = useState(HiddenColumnlist)
  const [userTable, setUserTable] = useState(
    table === 'userdetails' ? true : false
  )
  const [globalFilter, setGlobalFilter] = useState(undefined)
  const [showGlobalFilter, setShowGlobalFilter] = useState(false)
  const [columnFilters, setColumnFilters] = useState([])
  const [showColumnFilters, setShowColumnFilters] = useState(false)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20, //customize the default page size
  });
  useEffect(() => {
    if (table == 'userdetails') {
      const columnVisibility = sessionStorage.getItem(
        'mrt_columnVisibility_table_1'
      )
      if (columnVisibility) {
        setColumnVisibility(JSON.parse(columnVisibility))
      }
      const globalFilter = sessionStorage.getItem('mrt_globalFilter_table_1')
      const showGlobalFilter = sessionStorage.getItem(
        'mrt_showGlobalFilter_table_1'
      )
      const columnFilters = sessionStorage.getItem('mrt_columnFilters_table_1')
      const showColumnFilters = sessionStorage.getItem(
        'mrt_showColumnFilters_table_1'
      )
      if (globalFilter) {
        setGlobalFilter(JSON.parse(globalFilter) || undefined)
      }
      if (showGlobalFilter) {
        setShowGlobalFilter(JSON.parse(showGlobalFilter))
      }
      if (columnFilters) {
        setColumnFilters(JSON.parse(columnFilters))
      }
      if (showColumnFilters) {
        setShowColumnFilters(JSON.parse(showColumnFilters))
      }
      // isFirstRender.current = false;
    } else if (table === 'Pos') {
      const globalFilter = sessionStorage.getItem('mrt_globalFilter_table_2')
      const showGlobalFilter = sessionStorage.getItem(
        'mrt_showGlobalFilter_table_2'
      )
      const columnFilters = sessionStorage.getItem('mrt_columnFilters_table_2')
      const showColumnFilters = sessionStorage.getItem(
        'mrt_showColumnFilters_table_2'
      )
      if (globalFilter) {
        setGlobalFilter(JSON.parse(globalFilter) || undefined)
      }
      if (showGlobalFilter) {
        setShowGlobalFilter(JSON.parse(showGlobalFilter))
      }
      if (columnFilters) {
        setColumnFilters(JSON.parse(columnFilters))
      }
      if (showColumnFilters) {
        setShowColumnFilters(JSON.parse(showColumnFilters))
      }
    }
  }, [])

  useEffect(() => {
    if (table == 'userdetails')
      sessionStorage.setItem(
        'mrt_columnVisibility_table_1',
        JSON.stringify(columnVisibility)
      )
  }, [columnVisibility])

  useEffect(() => {
    if (table == 'userdetails') {
      sessionStorage.setItem(
        'mrt_globalFilter_table_1',
        JSON.stringify(globalFilter ?? '')
      )
    } else if (table === 'Pos') {
      sessionStorage.setItem(
        'mrt_globalFilter_table_2',
        JSON.stringify(globalFilter ?? '')
      )
    }
  }, [globalFilter])

  useEffect(() => {
    if (table == 'userdetails') {
      sessionStorage.setItem(
        'mrt_showGlobalFilter_table_1',
        JSON.stringify(showGlobalFilter)
      );
    } else if (table === 'Pos') {
      sessionStorage.setItem(
        'mrt_showGlobalFilter_table_2',
        JSON.stringify(showGlobalFilter)
      );
    }
  }, [showGlobalFilter])

  useEffect(() => {
    if (table == 'userdetails') {
      sessionStorage.setItem(
        'mrt_columnFilters_table_1',
        JSON.stringify(columnFilters)
      );
    } else if (table === 'Pos') {
      sessionStorage.setItem(
        'mrt_columnFilters_table_2',
        JSON.stringify(columnFilters)
      );
    }
  }, [columnFilters])

  useEffect(() => {
    if (table == 'userdetails') {
      sessionStorage.setItem(
        'mrt_showColumnFilters_table_1',
        JSON.stringify(showColumnFilters)
      );
    } else if (table === 'Pos') {
      sessionStorage.setItem(
        'mrt_showColumnFilters_table_2',
        JSON.stringify(showColumnFilters)
      );
    }
  }, [showColumnFilters])

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#19A73F' // Replace with your desired color
      }
    }
  })

  const ChooseValue = res => {
    let val = FobeTeamList.filter(data => {
      return data.value === res
    })
    return val[0]?.label
  }

  const ChooseValuesales = res => {
    let val = salesteamlist.filter(data => {
      return data.value === res
    })
    return val[0]?.label
  }

  const handleExportRows = async rows => {
    let data = rows.map(list => list.original)
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(`Sheet`)
    worksheet.getCell('A1').value = 'Create Date'
    worksheet.getCell('B1').value = 'Owner Name'
    worksheet.getCell('C1').value = 'Account Name'
    worksheet.getCell('D1').value = 'Company'
    worksheet.getCell('E1').value = 'Username'
    worksheet.getCell('F1').value = 'Primary Mail'
    worksheet.getCell('G1').value = 'Contact Number'
    worksheet.getCell('H1').value = 'Revenue Budget'
    worksheet.getCell('I1').value = 'Units'
    worksheet.getCell('J1').value = 'Lastlogin'
    worksheet.getCell('K1').value = 'Next Billing Amount'
    worksheet.getCell('L1').value = 'Billing Frequency'
    worksheet.getCell('M1').value = 'Next Billing Date'
    worksheet.getCell('N1').value = 'Billing Status'
    worksheet.getCell('O1').value = 'Billing Type'
    worksheet.getCell('P1').value = 'Status'
    worksheet.getCell('Q1').value = 'Billing'
    worksheet.getCell('R1').value = 'Password'
    worksheet.getCell('S1').value = 'Account Manager'
    worksheet.getCell('T1').value = 'Sales Person'
    worksheet.getCell('U1').value = 'DSR Status'
    worksheet.getCell('V1').value = 'Pos Access'
    worksheet.getCell('W1').value = 'Pos Charge'
    worksheet.getCell('X1').value = 'Invoice Access'
    worksheet.getCell('Y1').value = 'Invoice Charge'
    worksheet.getCell('Z1').value = 'Fobe Assistant'
    worksheet.getCell('AA1').value = 'Fobe Assistant Charge'
    worksheet.getCell('AB1').value = 'Inventory Access';
    worksheet.getCell('AC1').value = 'Custom Logo Access';

    let rowIndex6 = 2
    data.map((item, index) => {
      worksheet.getCell(`A${rowIndex6}`).value = item.create_Date
      worksheet.getCell(`B${rowIndex6}`).value = item.owner
      worksheet.getCell(`C${rowIndex6}`).value = item.acc_name
      worksheet.getCell(`D${rowIndex6}`).value = item.Company
      worksheet.getCell(`E${rowIndex6}`).value = item.username
      worksheet.getCell(`F${rowIndex6}`).value = item.Primary_mail
        ? item.Primary_mail
        : '-'
      worksheet.getCell(`G${rowIndex6}`).value = item.phone
      worksheet.getCell(`H${rowIndex6}`).value = item.revenue_Budget
      worksheet.getCell(`I${rowIndex6}`).value =
        item.units == 0 ? '-' : item.units
      worksheet.getCell(`J${rowIndex6}`).value = item.lastlogin
        ? item.lastlogin
        : '-'
      worksheet.getCell(`K${rowIndex6}`).value = item.amount_nextbilling
        ? item.amount_nextbilling
        : '-'
      worksheet.getCell(`L${rowIndex6}`).value = item.freq_billing
        ? item.freq_billing
        : '-'
      worksheet.getCell(`M${rowIndex6}`).value = item.Next_Billing_Date
        ? item.Next_Billing_Date
        : '-'
      worksheet.getCell(`N${rowIndex6}`).value =
        item.bill_paid_status == '' ? '-' : item.bill_paid_status
      worksheet.getCell(`O${rowIndex6}`).value =
        item.Bill_type == '' ? '-' : item.Bill_type
      worksheet.getCell(`P${rowIndex6}`).value =
        item.admin_sts == 1
          ? 'Active'
          : item.admin_sts == 0
            ? 'De-Active'
            : 'Pending'
      worksheet.getCell(`Q${rowIndex6}`).value =
        item.bill_sts == 1 ? 'Allow' : 'Deny'
      worksheet.getCell(`R${rowIndex6}`).value =
        item.password == null ? '-' : item.password
      worksheet.getCell(`S${rowIndex6}`).value = item.SalesPerson
        ? ChooseValue(item.SalesPerson)
        : '-'
      worksheet.getCell(`T${rowIndex6}`).value = item.sales
        ? ChooseValuesales(item.sales)
        : '-'
      worksheet.getCell(`U${rowIndex6}`).value =
        item.Dsr_Sts === 1
          ? 'Current'
          : item.Dsr_Sts === 2
            ? 'Behind'
            : 'Not Using'
      worksheet.getCell(`V${rowIndex6}`).value =
        item.pos_access == 1 ? 'Active' : 'De-active'
      worksheet.getCell(`W${rowIndex6}`).value =
        `$${item.pos_amt_access}` + '.00'
      worksheet.getCell(`X${rowIndex6}`).value =
        item.inv_access == 1 ? 'Active' : 'De-active'
      worksheet.getCell(`Y${rowIndex6}`).value =
        '$' + (item.inv_amt_access == 1 ? '30.00' : '0.00')
      worksheet.getCell(`Z${rowIndex6}`).value =
        item.asis_access == 1 ? 'Active' : 'De-active'
      worksheet.getCell(`AA${rowIndex6}`).value =
        '$' + (item.ass_amt_access == 2 ? '30.00' : item.ass_amt_access == 1 ? '20.00' : '0.00')
      worksheet.getCell(`AB${rowIndex6}`).value = item.inventory_access == 1 ? 'Active' : 'De-active';
      worksheet.getCell(`AC${rowIndex6}`).value = item.custom_logo_access == 1 ? 'Active' : 'De-active';
      rowIndex6++
    })
    worksheet.columns.forEach(column => {
      column.width = 25
      column.eachCell(cell => {
        cell.alignment = { horizontal: 'center' }
      })
    })
    const heading = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1', 'T1', 'U1', 'V1', 'W1', 'X1', 'Y1', 'Z1', 'AA1', 'AB1', 'AC1'];
    heading.forEach(head => {
      worksheet.getCell(head).font = { bold: true }
    })
    // Generate a buffer from the workbook
    const buffer = await workbook.xlsx.writeBuffer()
    // Create a blob from the buffer
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    // Download the file
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'UserDetails.xlsx'
    link.click()
    // Cleanup
    URL.revokeObjectURL(url)
  }

  const tabledate = useMaterialReactTable({
    columns: columns,
    data: rows,
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnResizing: true,
    enableGrouping: true,
    autoResetPageIndex: false,
    layoutMode: 'semantic',
    enableExpanding: table == 'role' ? true : false,
    getSubRows: table == 'role' ? (row) => row.List : false,
    enableStickyHeader: table == 'role' ? true : false,
    enableStickyFooter: table == 'role' ? true : false,
    enablePagination: table == 'role' ? false : true,
    muiTableContainerProps: { sx: { maxHeight: '600px' } },
    enableToolbarInternalActions: table == 'role' ? false : true,
    onGlobalFilterChange: setGlobalFilter,
    onShowGlobalFilterChange: setShowGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    onShowColumnFiltersChange: setShowColumnFilters,
    muiPaginationProps: {
      rowsPerPageOptions: ['10', '15', '20', '50', '100']
    },
    initialState: {
      columnPinning: table == 'Pos' ? { left: ['mrt-row-actions', 'company_name'] } : table == 'role' ? { left: ['mrt-row-expand', 'Role'] } : { left: ['mrt-row-actions', 'Company'] },
      expanded: table == 'role' ? true : false
    },
    state: {
      density: 'compact',
      columnVisibility: columnVisibility,
      isLoading,
      globalFilter,
      showGlobalFilter,
      columnFilters,
      showColumnFilters,
      pagination
    },
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <>
          {
            tabName == 'Sysco' && (

              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                <div className="row" style={{ marginTop: "0.6em" }}>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="commondiv">
                      <div
                        class="col"
                        style={{ cursor: "pointer", padding: "10px" }}
                        className={dropdown5Value == "0" ? "activefortab" : ""}
                        onClick={() => handleDropdown5Change("0")}
                      >
                        <span>All</span>
                      </div>
                      <div
                        class="col"
                        style={{ cursor: "pointer", padding: "10px" }}
                        className={dropdown5Value == "4" ? "activefortab" : ""}
                        onClick={() => handleDropdown5Change("4")}
                      >
                        <span>Un Mapped</span>
                      </div>

                      <div
                        class="col"
                        style={{ cursor: "pointer", padding: "10px" }}
                        className={dropdown5Value == "1" ? "activefortab" : ""}
                        onClick={() => handleDropdown5Change("1")}
                      >
                        <span>Fobesoft</span>
                      </div>
                      <div
                        class="col"
                        style={{ cursor: "pointer", padding: "10px" }}
                        className={dropdown5Value == "5" ? "activefortab" : ""}
                        onClick={() => handleDropdown5Change("5")}
                      >
                        <span>Fobegolf</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            )
          }
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>

            {tabName != 'Sysco' && headFilter}

            {userTable ? (
              <Tooltip title='Download'>
                <MdOutlineFileDownload
                  style={{
                    color: 'rgb(25, 167, 63)',
                    fontSize: '25px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    handleExportRows(table.getPrePaginationRowModel().rows)
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
        </>
      )
    }
  })

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <ThemeProvider theme={customTheme}>
            <MaterialReactTable table={tabledate} />
          </ThemeProvider>
        </Grid>
      </Grid>
    </>
  )
}

export default Table
