
const getModuleList = () => {
  return sessionStorage?.getItem('moduleNameList')?.split(',');
}

const hasModuleAccess = (moduleName) => {
  return sessionStorage?.getItem('moduleNameList')?.split(',')?.includes(moduleName);
}

const hasReadAndWriteAccess = (moduleName) => {
  let temp = JSON.parse(sessionStorage?.getItem('menulist'))?.moduleList?.find(a => a.moduleName === moduleName);
  return temp?.action == 1 ? true : false;
}

const hasRoleMatch = (roleName) => {
  return roleName === JSON.parse(sessionStorage?.getItem('menulist'))?.roleName;
}

export default { getModuleList, hasModuleAccess, hasReadAndWriteAccess, hasRoleMatch };
