import { Navigate } from "react-router-dom";
import { LocalStorageService } from '../Services/LocalStorageService';
import RoleAccessManagement from "../RoleAccessManagement"
export const PrivateRoute = ({ children, key }) => {
  const localStorageService = LocalStorageService.getService();

  const token = localStorageService.getAccessToken();

  if (!token) {
    // user is not authenticated
    return <Navigate to="/" />;
  } else {
    return RoleAccessManagement.hasModuleAccess(children.key) ? children : <Navigate to="/" />;
  }

};
