import React from 'react';
import { Tabs } from 'antd';
import INV_PossibleConnection from './INV_PossibleConnection';
import POS_PossibleConnection from './POS_PossibleConnection';
import Header from '../Header/header';

function PossibleConnectionDashboard() {

  const tabItems = [
    {
      key: 1,
      label: 'Invoice',
      children: <INV_PossibleConnection />
    },
    {
      key: 2,
      label: 'POS',
      children: <POS_PossibleConnection />
    },
  ];

  const renderElement = [
    <div className='content-container'>
      <div className='containerford'>
        <div className='first_conteent'>
          <div className='col-md-12'>
            <div className='row'>
              <Tabs defaultActiveKey={1} items={tabItems} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ];

  return (
    <div>
      <Header element={renderElement} />
    </div>
  )
}

export default PossibleConnectionDashboard
