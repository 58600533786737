import React, { useState, useEffect } from 'react';
import Header from "../../Header/header";
import { useNavigate } from "react-router-dom";
import "../User.css";
import CommonTable from '../CommonTable';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Drawer, Select, Input, Form, Tooltip, DatePicker, Modal } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { fobeAxios } from '../../middleware/interceptor';
import * as moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import RoleAccessManagement from '../../RoleAccessManagement';
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
function PromoCode() {
  const [form] = Form.useForm();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);
  const [deleted, setdeleted] = useState(false)
  let navigate = useNavigate();
  const [PromoCodedetails, setPromoCodedetails] = useState([])
  const [PromoCodevalues, setPromoCodevalues] = useState('');
  const [values, setvalues] = useState()
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [addpromo, setaddpromo] = useState(false)
  const [showdelpop, setshowdelpop] = useState(false)
  const [rowval, setrowval] = useState({})
  const today = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = today.toLocaleString(undefined, options);
  const [AccessKey, setAccessKey] = useState(sessionStorage.getItem("SpecialUser"));
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    sessionStorage.clear();
    navigate("/");
  };
  const [title, settitle] = useState('');
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const onClose = () => { setaddpromo(false); setOpen(false); };
  const showDrawer = () => {
    form.setFieldsValue({
      Promocode: undefined,
      MonthBase: 179,
      Monthfee: 179,
      MonthBaseDiscount: 0,
      BudgetBase: 399,
      BudgetBaseDiscount: 0,
      BudgetOneTime: 399,
      LocationPromoCode: undefined,
      ExDate: undefined,
      MonthBaseDiscount: 0,
      AccType: 1,
      FirstName: '',
      LastName: '',
      PromoId: undefined,
      EmailCust: '',
    });
    setOpen(true);
    setaddpromo(true)
  };

  const [Expiration_Date, setExpiration_Date] = useState('');
  const Edit = async (params) => {
    setOpen(true);
    settitle('Edit')
    setaddpromo(false);
    await fobeAxios.get(`Api/Admin/GetUpdatepromocode/${params.Promo_id}`).then((res) => {
      setvalues({ 'FirstName': res.data.Updatepromo[0].FirstName, 'LastName': res.data.Updatepromo[0].LastName, 'PromoId': res.data.Updatepromo[0].PromoId, 'Promocode': res.data.Updatepromo[0].Promocode, 'EmailCust': res.data.Updatepromo[0].EmailCust })
      const split = res.data.Updatepromo[0].Expiration_Date.split('T');
      res.data.Updatepromo[0].Expiration_Date = split[0];
      setExpiration_Date(res.data.Updatepromo[0].Expiration_Date)
      form.setFieldsValue({
        Promocode: res.data.Updatepromo[0].Promocode,
        MonthBase: res.data.Updatepromo[0].MonthBase,
        Monthfee: res.data.Updatepromo[0].Monthfee,
        MonthBaseDiscount: res.data.Updatepromo[0].MonthBaseDiscount,
        BudgetBase: res.data.Updatepromo[0].BudgetBase,
        BudgetBaseDiscount: res.data.Updatepromo[0].BudgetBaseDiscount,
        BudgetOneTime: res.data.Updatepromo[0].BudgetOneTime,
        LocationPromoCode: res.data.Updatepromo[0].LocationPromoCode,
        ExDate: dayjs(split[0]),
        MonthBaseDiscount: res.data.Updatepromo[0].MonthBaseDiscount,
        AccType: res.data.Updatepromo[0].AccType,

      });
    }).catch((err) => { });

    setPromoCodevalues(params.Promo_id)
  }

  const HandleSelect = (value) => {


    if (value == 1) {
      form.setFieldsValue({

        MonthBase: 179,
        Monthfee: 179,
        BudgetBase: 399,
        BudgetOneTime: 399,
        Discount: 0,


      });
    }

    if (value == 3) {
      form.setFieldsValue({
        MonthBase: 179,
        Monthfee: 179,
        BudgetBase: 299,
        BudgetOneTime: 299,
        Discount: 0,
      });
    }



  }

  const onChangemonth = (event) => {
    let MonthBase = form.getFieldValue('MonthBase') - event.target.value;


    form.setFieldsValue({
      Monthfee: (MonthBase < 0 || MonthBase > form.getFieldValue('MonthBase')) ? 0 : MonthBase,
    })
  }

  // const monthchange=(e)=>{
  //     let MonthBase = e.target.value-form.getFieldValue('MonthBaseDiscount');

  //     //  console.log(Budgetbase, "Budgetbase");

  //       form.setFieldsValue({
  //           Monthfee:(MonthBase < 0 || MonthBase > form.getFieldValue('MonthBase')) ? 0 :MonthBase,
  //       })
  // }
  // const changebudgetbase=(e)=>{

  //     let budgetbase= e.target.value-form.getFieldValue('BudgetBaseDiscount')
  //     form.setFieldsValue({
  //         BudgetOneTime:(budgetbase < 0 || budgetbase > form.getFieldValue('BudgetBase')) ? 0 :budgetbase,
  //     })

  // }

  const onChange = (event) => {

    console.log(event.target.value, "event");


    let Budgetbase = form.getFieldValue('BudgetBase') - event.target.value;

    console.log(Budgetbase, "Budgetbase");

    form.setFieldsValue({
      BudgetOneTime: (Budgetbase < 0 || Budgetbase > form.getFieldValue('BudgetBase')) ? 0 : Budgetbase,
    })

  }

  const Delete = async (params) => {

    setshowdelpop(true)
    setrowval(params)
  }
  const del = async (params) => {
    let del = { ...params, "status": 3 }
    await fobeAxios.post('Api/Admin/DeletePromocode', del).then((res) => {
      if (res.data == 1) {
        setshowdelpop(false)
        toast.success("Deleted Successfully")
        GetPromocodeDetails()
      } else {
        toast.error("Failed to Delete")
      }
    }).catch((err) => {
      console.log(err)
    })
  }


  useEffect(() => {

    GetPromocodeDetails();

  }, [])

  const GetPromocodeDetails = async () => {
    await fobeAxios.get("Api/Admin/GetPromoCode").then((res) => {
      console.log(res.data.Promo_Details)
      let temp = res.data.Promo_Details.map((data) => {
        return {
          ...data, Subcription: data.Subcription ? data.Subcription : "-",
          BudgetAmount: data.BudgetAmount
            ? currencyFormatter.format(data.BudgetAmount)
            : "-",
          OfferAmount: (data.OfferAmount !== null || data.OfferAmount !== undefined || data.OfferAmount !== '')
            ? currencyFormatter.format(data.OfferAmount)
            : "-",
          Created_date: data.Created_date ? moment(data.Created_date).format('YYYY/MM/DD') : "-"
        }
      });
      // console.log(temp)
      setPromoCodedetails(temp)
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  }


  const columns = [
    {
      accessorKey: "Promo_id",
      header: 'Promo Id',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "Created_date",
      header: 'Create Date',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      //   Cell: ({ row }) => {
      //     return moment(row.original.Create_date).format('YYYY/MM/DD'); // Correct the date format
      //   },
    },
    {
      accessorKey: "Promocode",
      header: 'Promocode',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ row }) => {
        return <span style={{ color: "#19A73F" }}>{row.original.Promocode}</span>
      }
    },
    {
      accessorKey: "Type",
      header: 'Type',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "OfferAmount",
      header: 'Offer Amount',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
    },
    {
      accessorKey: "BudgetAmount",
      header: 'Monthly Base Amount',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
    },
    // {
    //     accessorKey: "Subcription",
    //     header: 'Subscription',
    //     editable: false,
    //     muiTableHeadCellProps: {
    //         align: 'left',
    //       },
    //       muiTableBodyCellProps: {
    //         align: 'center',
    //       },
    // },
    {
      accessorKey: "status",
      header: 'Action',
      editable: false,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ row }) => {
        return (
          <>
            <div style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
              <Tooltip title="Edit">
                <EditIcon style={{ color: '#6A8AB9', cursor: RoleAccessManagement.hasReadAndWriteAccess('Promocode') ? 'not-allowed' : 'pointer' }} onClick={() => RoleAccessManagement.hasReadAndWriteAccess('Promocode') ? '' : Edit(row.original)} /> </Tooltip>
              <Tooltip title="Delete">
                <DeleteOutlineOutlinedIcon style={{ color: '#F86764', cursor: RoleAccessManagement.hasReadAndWriteAccess('Promocode') ? 'not-allowed' : 'pointer' }} onClick={() => RoleAccessManagement.hasReadAndWriteAccess('Promocode') ? '' : (Delete(row.original), setdeleted(true))} /></Tooltip>
            </div>
          </>
        )
      },
    },

  ];

  const promoFilter = [
    <div className='forbuttonon'>
      <Button type="primary" disabled={RoleAccessManagement.hasReadAndWriteAccess('Promocode')} onClick={showDrawer}>Add Promocode</Button>
    </div>
  ]

  const element = [
    <>
      <div className='content-container'>
        <div className='containerford' style={{ marginTop: '10px' }}>

          <div className='first_conteent' style={{ marginTop: '4em' }}>
            <CommonTable
              rows={PromoCodedetails}
              columns={columns}
              table={"promoCode"}
              isLoading={loading}
              HiddenColumnlist={{}}
              headFilter={promoFilter}
              deleted={deleted}
              setdeleted={setdeleted}
            />
          </div>
        </div>
      </div></>
  ];


  const onsubmit = async (val) => {

    console.log(values, addpromo, val, 'valobj2')
    if (addpromo == false) {
      let req = { ...val, 'FirstName': values.FirstName, 'LastName': values.LastName, 'PromoId': values.PromoId, 'EmailCust': values.EmailCust, 'ExDate': new Date(val.ExDate).toLocaleDateString() }
      console.log(req);
      await fobeAxios.post('Api/Admin/AddPromocode', req).then((res) => {
        if (res.data.Addpromo[0].Status == 2) {
          GetPromocodeDetails()
          onClose()
          setaddpromo(false)
          toast.success("Promocode Updated Successfully");
        } else {
          toast.error("Failed to Add Promocode")
        }
      }).catch((err) => {
        console.log(err)
      })
    } else {
      let req1 = { ...val, 'FirstName': '', 'LastName': '', 'PromoId': '', 'EmailCust': '', 'ExDate': new Date(val.ExDate).toLocaleDateString() }
      await fobeAxios.post('Api/Admin/AddPromocode', req1).then((res) => {
        if (res.data.Addpromo[0].Status == 1) {
          GetPromocodeDetails()
          onClose()
          setaddpromo(false)
          toast.success("Promocode Added Successfully")
        } else if (res.data.Addpromo[0].Status == 3) {
          toast.warning("Promocode Already Exist. Please Use a Different Name");
        }
        else {
          toast.error("Failed to Update");
        }
      }).catch((err) => {
        console.log(err)
      })

    }

  }

  const handleKeyDown = (e) => {
    // Allow only numbers (0-9) and allow 'Backspace', 'Delete', and arrow keys
    if (e.key.match(/[^0-9]/) && (e.key !== 'Backspace' && e.key !== 'Delete' && (e.key !== 'ArrowUp' && e.key !== 'ArrowDown' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight'))) {
      e.preventDefault();

    }
  };
  const handleSubmit = () => {
    form.submit();
  };
  const disabledate = (current) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
  }
  return (
    <div>
      <ToastContainer autoClose={1500} />
      <Header
        handleMobileMenuClose={handleMobileMenuClose}
        anchorEl={anchorEl}
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleMenuClose={handleMenuClose}
        loginUserName={loginUserName}
        handleMenuCloseout={handleMenuCloseout}
        handleMobileMenuOpen={handleMobileMenuOpen}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        element={element}
      />

      <Drawer
        title="Promocode Details"
        width={700}
        onClose={onClose}
        open={open}
        closeIcon={<KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />}


        footer={  // Add the footer section
          <div
            className='drawer-btn' style={{ display: 'flex', gap: '10px' }} >
            <Button type="primary" style={{ backgroundColor: '#19A73F' }} onClick={handleSubmit}>Save</Button>
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        }

      >
        <div className='newroledraw'>
          <Form className='formvert' layout="vertical" onFinish={onsubmit} form={form}   >
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item label="Type" name="AccType" rules={[{ required: true, message: "Please choose a Type" }]}>
                    <Select showSearch
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                      onChange={HandleSelect} defaultValue={[2]} className='formroles' placeholder="Choose Type" style={{ width: '100%' }}
                      optionFilterProp="children"
                      options={[
                        {
                          value: 1,
                          label: 'Fobesoft',
                        },

                        {
                          value: 3,
                          label: 'Sysco',
                        },

                      ]}

                    />

                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item label="Monthly Base" name={"MonthBase"} rules={[{ required: true, message: "Monthly Base is Required" },]}>
                    <Input className='itemoneform' disabled={true} placeholder="Monthly Base" style={{ height: '40px' }} />
                  </Form.Item>
                </div>
              </div>
              <div className='row' style={{ paddingTop: '3px' }}>
                <div className='col-md-6'>
                  <Form.Item label="Monthly Base Discount" onChange={onChangemonth} name={"MonthBaseDiscount"} rules={[{ required: true, message: "Monthly Base Discount is Required" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      console.log(typeof (value), typeof (getFieldValue('MonthBase')), 'chkmonth');
                      if (parseInt(value) > parseInt(getFieldValue('MonthBase'))) {
                        return Promise.reject('This value exceeds the Month Base value');
                      }
                      return Promise.resolve();
                    },
                  }),]}>

                    <Input className='itemoneform' onKeyDown={handleKeyDown} placeholder="MonthBaseDiscount" style={{ height: '40px' }} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item label="Monthly Fee" name={"Monthfee"} rules={[{ required: true, message: "Monthly Fee is Required" }]}>
                    <Input disabled={true} className='itemoneform' onKeyDown={handleKeyDown} placeholder="Monthly Fee" style={{ height: '40px' }} />
                  </Form.Item>
                </div>
              </div>
              <div className='row' style={{ paddingTop: '3px' }}>
                <div className='col-md-6'>
                  <Form.Item label="Promocode" name={"Promocode"} rules={[{ required: true, message: "Promocode is Required" }]}  >
                    <Input className='itemoneform' placeholder="Promocode" style={{ height: '40px' }} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item label="Budget Base" name={"BudgetBase"} rules={[{ required: true, message: "Budget Base is Required" }]}>
                    <Input className='itemoneform' disabled={true} placeholder="Budget Base" style={{ height: '40px' }} />
                  </Form.Item>
                </div>

              </div>
              <div className='row' style={{ paddingTop: '3px' }}>
                <div className='col-md-6'>
                  <Form.Item label="Budget Base Discount" onChange={onChange} name={"BudgetBaseDiscount"} rules={[{ required: true, message: "Budget Base Discount is Required" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      console.log(typeof (value), typeof (getFieldValue('BudgetBase')), 'chkmonth');
                      if (parseInt(value) > parseInt(getFieldValue('BudgetBase'))) {
                        return Promise.reject('This value exceeds the Budget Base value');
                      }
                      return Promise.resolve();
                    },
                  })]}>
                    <Input className='itemoneform' onKeyDown={handleKeyDown} placeholder="Budget Base" style={{ height: '40px' }} />
                  </Form.Item>
                </div>

                <div className='col-md-6'>
                  <Form.Item label="Budget One Time" name={"BudgetOneTime"} rules={[{ required: true, message: "" }]}>
                    <Input disabled={true} onKeyDown={handleKeyDown} className='itemoneform' placeholder="Budget One Time" style={{ height: '40px' }} />
                  </Form.Item>
                </div>


              </div>
              <div className='row' style={{ paddingTop: '3px' }}>
                <div className='col-md-6'>

                  <Form.Item label="Location" name={"LocationPromoCode"} rules={[{ required: true, message: "Location is Required" }]}>
                    <Input className='itemoneform' placeholder="Enter your Location" style={{ height: '40px' }} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item label="Expiry Date" onKeyDown={handleKeyDown} name={'ExDate'} rules={[{ required: true, message: "Please enter a Expiry Date" }]}>

                    <DatePicker format={'YYYY-MM-DD'} allowClear={false} disabledDate={disabledate} style={{ width: '100%', height: '40px', borderRadius: '3px' }} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <div className='forbuttonon' style={{ display: 'flex', justifyContent: 'start' }}>

                  </div>
                </div>
              </div>

              <Form.Item name="ID"></Form.Item>
            </div>

          </Form>
        </div>

      </Drawer>
      <Modal
        title="Delete Promocode"
        open={showdelpop}
        onCancel={() => { setshowdelpop(false) }}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              onClick={() => { setshowdelpop(false) }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: '#19A73F',
                color: '#fff',
                borderRadius: '3px',
                border: 'none'
              }}
              onClick={() => del(rowval)}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p style={{ fontSize: '15px', textAlign: 'center', padding: '10px 20px 20px 20px' }}>Are you sure you want to delete this item?</p>
      </Modal>
    </div>

  );
}


export default PromoCode;
