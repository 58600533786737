export const LocalStorageService = (function () {
  var _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  }

  function _setToken(token) {
    sessionStorage.setItem('auth-token', token);
  }
  function _getAccessToken() {
    return sessionStorage.getItem('auth-token');
  }

  function _getRefreshToken() {
    return sessionStorage.getItem('refresh_token');
  }

  function _clearToken() {
    sessionStorage.clear();
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken
  }
})();
