import React, { useState } from 'react';
import Header from "../../Header/header";
import { useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import "../User.css";
import UserManagementTable from './UserManagementTable';
import RoleAccess from './RoleAccess';
import { ToastContainer, toast } from "react-toastify";
function UserManagement() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);
  const [tabsValue, setTabsValue] = useState('1');
  let navigate = useNavigate();

  const items = [
    // {
    //   key: '1',
    //   label: 'User Management',
    //         disabled: true,
    //     },
    {
      key: '1',
      label: 'Users',
      children: <UserManagementTable tabsValue={tabsValue} />,
    },
    {
      key: '2',
      label: 'Role Access',
      children: <RoleAccess />,
    },

  ];
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onChange = key => {
    setTabsValue(key);
  }
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    sessionStorage.clear();
    navigate("/");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const td = [
    <>
      <div className='content-container'>
        <div className='containerford'>
          <div className='first_conteent'>
            <div className='col-md-12'>
              {/* <div className='row'>
                <h2 className='titleuser'>User Management</h2>
              </div> */}
              <div className='row'>
                <Tabs defaultActiveKey={tabsValue} items={items} onChange={onChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ]
  return (
    <div>
      <ToastContainer autoClose={1500} />
      <Header
        handleMobileMenuClose={handleMobileMenuClose}
        anchorEl={anchorEl}
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleMenuClose={handleMenuClose}
        loginUserName={loginUserName}
        handleMenuCloseout={handleMenuCloseout}
        handleMobileMenuOpen={handleMobileMenuOpen}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        element={td}
      />
    </div>
  );
}


export default UserManagement;
