import React, { useEffect, useState } from "react";
import ChatBubble from "./ChatBubble";
import "./ChatTranscript.css";
import { getDateLabel } from "../../utils/dateUtils";


const ChatTranscript = ({ messages, messageLimit, updatemessage, setMessage, setmessagekey, setmessage_id, message_id, isEditable }) => {



  const displayedMessages = messageLimit ? messages?.slice(0, messageLimit) : messages;
  console.log("displayedMessages", displayedMessages);

  let lastDateLabel = null;
  // useEffect(() => {
  //   let test = messages?.slice(((messages.length - 1) - (messageLimit + 1)), ((messages.length - 1) - (messageLimit + 1)));
  //   console.log(test);
  // }, [])
  return (
    <div className="chat-transcript">
      {displayedMessages?.map((msg, index) => {
        const dateLabel = getDateLabel(msg.timestamp);
        const showDateLabel = dateLabel !== lastDateLabel;
        lastDateLabel = dateLabel;

        return (
          <React.Fragment key={index}>
            {showDateLabel && (
              <div className="chat-transcript__date-label">{dateLabel}</div>
            )}
            <ChatBubble
              message={msg.text}
              sender={msg.sender}
              msgStatus={msg.msgStatus}
              timestamp={msg.timestamp}
              index={msg.Note_id}
              msgDetails={msg}
              updatemessage={updatemessage}
              setMessage={setMessage}
              messages={messages}
              setmessagekey={setmessagekey}
              setmessage_id={setmessage_id}
              isEditable={isEditable}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ChatTranscript;
