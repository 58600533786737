import React, { useState, useEffect } from 'react';
import Header from "../../Header/header";
import { useNavigate } from "react-router-dom";
import "../User.css";
import CommonTable from '../CommonTable';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import EditIcon from '@mui/icons-material/Edit';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Drawer, Select, Input, Form, Tooltip, DatePicker, Modal } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { fobeAxios } from '../../middleware/interceptor';
import { ToastContainer, toast } from "react-toastify";
import RoleAccessManagement from '../../RoleAccessManagement';


function AddHq() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);
  const [hqData, setHqData] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true)
  const [showdel, setshowdel] = useState(false)
  const [rowval, setrowval] = useState({});
  const [remove, setremove] = useState(false)
  const [AccessKey, setAccessKey] = useState(sessionStorage.getItem("SpecialUser"));
  let navigate = useNavigate();
  useEffect(() => {
    //   const getHqData = async () => {
    //     const fetchedHqData = await
    //     setHqData(fetchedHqData);
    //   }
    //   getHqData();
    fetchHqData();
  }, []);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    sessionStorage.clear();
    navigate("/");
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
    form.resetFields()
  };
  const showDrawer = () => { setOpen(true); };

  const fetchHqData = async () => {
    //   const response =
    await fobeAxios.get('Api/Admin/GetHq').then((res) => {
      let temp = res.data.Hq_list.map((data) => {
        return { ...data, Hq_name: data.Hq_name ? data.Hq_name : '-', Admin_id: data.Admin_id ? data.Admin_id : '-' }
      })
      setHqData(temp)
      setLoading(false);
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    });
    //   return response?.data?.Hq_list;
  }

  // const checkEmail = async (email) => {
  //   const response = await fobeAxios.get(`Api/User/CheckEmailValid/${email}`);
  // // setValidEmail(response?.data === 1 ? true : false);
  //   if(response?.data === 0 ){
  //   setValidEmail(false)
  // }
  // }

  const addHqHandler = async (val) => {
    console.log(val, 'val')
    const response1 = await fobeAxios.get(`Api/User/CheckEmailValid_hq/${val.email}`);
    // setValidEmail(response?.data === 1 ? true : false);
    if (response1?.data === 0) {

      form.setFields([
        {
          name: 'email',
          errors: ['Email does not exist'],
        },
      ]);
    }

    if (response1?.data === 1) {
      const response = await fobeAxios.post(
        "Api/Admin/AddHq",
        {
          "Hq_name": val.Default_Module,
          "Hq_email": val.email
        }
      );

      if (response?.data === 1) {
        toast.success("HQ Added Successfully");
        fetchHqData();
        setOpen(false);
      } else if (response?.data === 2) {
        toast.error("HQ Not Added");
      } else {
        toast.warning("This HQ is Already Exist");
      }
      form.resetFields()
    }
  }

  // const clearDataHandler = () => {
  //   setHqName('');
  //   setEmail('');
  // }

  const addRestaurantHandler = async (row) => {
    console.log(row);
    const restId = row?.original?.Rest_id;
    const restName = row?.original?.Hq_name;
    navigate(`/hq/group-user/${restId}/${restName}`);
  }





  // const rows = hqData?.map(item => {
  //   return {
  //     id: item?.Hq_id,
  //     name: item?.Hq_name==undefined?'-':item?.Hq_name,
  //     adminid: item?.Admin_id,
  //     count: item?.Count,
  //     addrestaurant: item?.Add_Rest,
  //     status: item?.Remove,
  //     restaurantId: item?.Rest_id,
  //   }
  // });
  const columns = [
    {
      accessorKey: "Hq_id",
      header: 'HQ ID',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "Hq_name",
      header: 'HQ Name',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ row }) => {
        return <span style={{ color: "#19A73F" }}>{row.original.Hq_name}</span>
      }
    },
    {
      accessorKey: "Admin_id",
      header: 'Admin ID',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "Count",
      header: 'Count',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
    },
    {
      accessorKey: "Add_Rest",
      header: 'Add  Restaurant',
      editable: false,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          <span onClick={() => addRestaurantHandler(row)} style={{ cursor: 'pointer' }}>
            <svg xmlns="
http://www.w3.org/2000/svg"
              width="22" height="22" viewBox="0 0 25 25" fill="none">
              <circle cx="12.5" cy="12.5" r="12.5" fill="#4ECB71" />
              <path d="M12.5 6C12.7155 6 12.9222 6.0856 13.0745 6.23798C13.2269 6.39035 13.3125 6.59701 13.3125 6.8125V11.6875H18.1875C18.403 11.6875 18.6097 11.7731 18.762 11.9255C18.9144 12.0778 19 12.2845 19 12.5C19 12.7155 18.9144 12.9222 18.762 13.0745C18.6097 13.2269 18.403 13.3125 18.1875 13.3125H13.3125V18.1875C13.3125 18.403 13.2269 18.6097 13.0745 18.762C12.9222 18.9144 12.7155 19 12.5 19C12.2845 19 12.0778 18.9144 11.9255 18.762C11.7731 18.6097 11.6875 18.403 11.6875 18.1875V13.3125H6.8125C6.59701 13.3125 6.39035 13.2269 6.23798 13.0745C6.0856 12.9222 6 12.7155 6 12.5C6 12.2845 6.0856 12.0778 6.23798 11.9255C6.39035 11.7731 6.59701 11.6875 6.8125 11.6875H11.6875V6.8125C11.6875 6.59701 11.7731 6.39035 11.9255 6.23798C12.0778 6.0856 12.2845 6 12.5 6Z" fill="white" />
            </svg>
          </span>
        )
      }

    },
    {
      accessorKey: "Remove",
      header: 'Remove',
      editable: false,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          <Tooltip title="Delete">
            <DeleteOutlineOutlinedIcon style={{ color: '#F86764', cursor: RoleAccessManagement.hasReadAndWriteAccess('HQ') ? 'not-allowed' : 'pointer' }} onClick={() => RoleAccessManagement.hasReadAndWriteAccess('HQ') ? '' : (del(row.original), setremove(true))} /></Tooltip>)
      },
    },
  ];


  const hqFilter = [
    <div className='forbuttonon'>
      <Button type="primary" disabled={RoleAccessManagement.hasReadAndWriteAccess('HQ')} onClick={showDrawer}>Add HQ</Button>
    </div>
  ]

  const hq = [
    <div className='content-container'>
      <div className='containerford' style={{ marginTop: '4em' }}>

        <div className='first_conteento'>
          <CommonTable
            rows={hqData}
            columns={columns}
            table={"Hq"}
            isLoading={loading}
            HiddenColumnlist={{}}
            headFilter={hqFilter}
            remove={remove}
            setremove={setremove}
          />
        </div>
      </div>
    </div>
  ]

  // const fail=()=>{
  //   toast.warning('fields ')
  // }
  const del = async (value) => {


    setshowdel(true)
    setrowval(value)

  }
  const confirmdel = async (value) => {
    await fobeAxios.post('/Api/Admin/RemoveHq', value).then((res) => {
      if (res.data == 1) {
        setshowdel(false)
        fetchHqData()
        toast.success('Deleted Successfully')

      }
      else {
        toast.error('Not deleted')
      }
    }
    )
  }
  const handleKeyDown = (e) => {
    // Allow only letters (A-Z and a-z)
    if (e.key.match(/[^A-Za-z0-9\s]/) && !['Backspace', 'Control', 'c', 'Control', 'v', 'Control', 'x'].includes(e.key)) {
      e.preventDefault();

    }
  };

  // const validateEmail = (email) => {
  //   const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  //   return emailRegex.test(email);
  // };

  const onsubmit = () => {
    form.submit();
  }



  return (
    <div>
      <ToastContainer autoClose={1500} />
      <Header
        handleMobileMenuClose={handleMobileMenuClose}
        anchorEl={anchorEl}
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleMenuClose={handleMenuClose}
        loginUserName={loginUserName}
        handleMenuCloseout={handleMenuCloseout}
        handleMobileMenuOpen={handleMobileMenuOpen}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        element={hq}
      />
      <Drawer
        title="HQ Details"
        width={500}
        onClose={onClose}
        open={open}
        closeIcon={<KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />}
        footer={  // Add the footer section
          <div
            className='drawer-btn'
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              gap: '10px'
            }}
          >
            <Button
              type="primary" style={{ backgroundColor: '#19A73F' }} onClick={onsubmit}
            >
              Save
            </Button>
            <Button style={{
              backgroundColor: 'rgb(239 239 239)',
              color: '#000',
              borderRadius: '3px',
              border: '0.5px solid #b2b2b2',
            }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        }

      >
        <div className='newroledraw'>

          <Form className='formvert' layout="vertical" onFinish={addHqHandler} form={form}   >
            <Form.Item
              label="HQ Name"
              name="Default_Module"
              rules={[{ required: true, message: "HQ name is Required" }]}
            >
              <Input
                className='itemoneform'
                placeholder="Enter the HQ Name"
                style={{ height: '40px' }}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
            <Form.Item
              label="Email ID"
              name="email"
              rules={[{ required: true, message: "Email ID is Required" }, { type: 'email', message: "Email ID is Invalid" },
              ]}
            >
              <Input
                className='itemoneform'
                placeholder="Enter the Email"
                style={{ height: '40px' }}
              />
            </Form.Item>

          </Form>
        </div>
      </Drawer>
      <Modal
        title="Delete User"
        open={showdel}
        onCancel={() => { setshowdel(false) }}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              onClick={() => { setshowdel(false) }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: '#19A73F',
                color: '#fff',
                borderRadius: '3px',
                border: 'none'
              }}
              onClick={() => confirmdel(rowval)}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p style={{ fontSize: '15px', textAlign: 'center', padding: '10px 20px 20px 20px' }}>Are You sure you want to delete this item?</p>
      </Modal>
    </div>
  );
}


export default AddHq;
