import React, { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./invoice.css";
import { format } from "date-fns";
import * as moment from 'moment';
const MappingTopBarReview = ({
  tdata,
  setTotal,
  total,
  record,
  invoiceData,
  qa_review,
  setQa_review,
  fortotal,
}) => {
  const [invSource, setInvSource] = useState();
  const [records, setRecord] = useState();
  const [file_status, setFile_status] = useState();
  const [InvoiceDatas, setInvoiceDatas] = useState();
  const [invoiceDate, setInvoiedate] = useState();
  useEffect(() => {
    // let index = tdata.findIndex((x) => x.Upload_id === record.ReviewData.Upload_id);
    // nextAndPrevious(5, index);
    setRecord(record);
    setInvSource(record?.ReviewData?.invoice_from);
    console.log("Invoice5235", record?.ReviewData?.invoice_from);
    if (record?.ReviewData?.invoice_from == "INVOICE_UPLOAD") {
      setInvSource("Invoice Upload");
    } else if (record?.ReviewData?.invoice_from == "SPLIT_PDF") {
      setInvSource("Split Invoice");
    } else if (record?.ReviewData?.invoice_from == "From_Mail") {
      setInvSource("Invoice Email");
    } else if (record?.ReviewData?.invoice_from == "MERGE_PDF") {
      setInvSource("Merge Invoice");
    } else if (record?.ReviewData?.invoice_from == "SYSCO EDI") {
      setInvSource("Sysco EDI");
    } else if (record?.ReviewData?.invoice_from == "PFS EDI") {
      setInvSource("PFG EDI");
    } else if (record?.ReviewData?.invoice_from == "MCLANE EDI") {
      setInvSource("McLane EDI");
    }
    else if (record?.ReviewData?.invoice_from == "Benekeith EDI") {
      setInvSource("Ben E. Keith EDI");
    } else if(record?.ReviewData?.invoice_from == "USFOOD"){
      setInvSource("US Foods EDI");
    }
    else {
      setInvSource("");
    }
    setFile_status(record?.ReviewData?.file_status);
    console.log("invSourceee", invoiceData);
    if (invoiceData != undefined && invoiceData != "") {
      console.log(invoiceData.data, ":ddddd");
      setInvoiceDatas(invoiceData.data);
      var temp_date = invoiceData.data.invoice_date == '' ? new Date().toISOString().slice(0, 10) : moment(invoiceData.data.invoice_date.slice(0, 10)).format('YYYY-MM-DD');
      temp_date = temp_date == 'Invalid date' ? moment(invoiceData.data.invoice_date.slice(0, 8)).format('YYYY-MM-DD') : temp_date;
      setInvoiedate(temp_date);
      const sumOfIds = fortotal.reduce((acc, obj) => {
        return acc + parseFloat(obj.Total);
      }, 0);
      console.log(sumOfIds, "aaaaaaaa");
      setTotal(sumOfIds);
    } else {
      setInvoiceDatas("");
      setTotal("");
    }
    console.log("invSourcReviewe", invSource, InvoiceDatas);
    ArrangeDetails();
  }, [record, invoiceData]);
  const ArrangeDetails = () => {
    console.log(JSON.stringify(invoiceData.data) + ": InvoiceData");
    console.log(JSON.stringify(record) + ": record");
  };
  useEffect(() => {
    if (fortotal != null && fortotal != undefined && fortotal != "") {
      const sumOfIds = fortotal.reduce((acc, obj) => {
        return acc + parseFloat(obj.Total);
      }, 0);
      console.log(sumOfIds, "aaaaaaaa");
      setTotal(sumOfIds);
    } else {
      setInvoiceDatas("");
      setTotal("");
    }
  }, [fortotal]);

  function formatDate(dateStr) {
    if (dateStr != "" && dateStr != null && dateStr != undefined) {
      const date = new Date(dateStr);
      return format(date, "MM/dd/yyyy hh:mm a");
    } else {
      return "";
    }
  }
  console.log("file_status", file_status);
  // const formatDateforInvoiceDate = (dateStr) => {
  //   const date = new Date(dateStr);

  //   const year = date.getFullYear();

  //   const month = String(date.getMonth() + 1).padStart(2, "0");

  //   const day = String(date.getDate()).padStart(2, "0");

  //   return `${month}/${day}/${year}`;
  // };
  return (
    <>
      <div class="col-md-12 mb-4">
        <div class="container_card">
          <div
            class={
              (invSource == "Invoice Upload" && "carddatasysco") ||
              (invSource == "Invoice Email" && "carddatasysco") ||
              (invSource == "Split Invoice" && "carddatasysco") ||
              (invSource == "Merge Invoice" && "carddatasysco") ||
              (invSource == "Sysco EDI" && "carddatainvoice") ||
              (invSource == "PFG EDI" && "carddatainvoice") ||
              (invSource == "McLane EDI" && "carddatainvoice") ||
              (invSource == "Ben E. Keith EDI" && "carddatainvoice") ||
              (invSource == "US Foods EDI" && "carddatainvoice") ||
              (invSource == "" && "carddata")
            }
            id="ribbon"
            data-label={invSource ? invSource : "----"}
            style={{ fontSize: "0.87em" }}
          >
            <div class="card__container">
              <div class="row" style={{ paddingTop: "0.5em" }}>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
                  <div class="row mb-3">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Restaurant</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <h2 className="mapsizer">
                        {records?.ReviewData?.company}
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
                  <div class="row mb-3">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Invoice No</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <h2 className="mapsizer">
                        {InvoiceDatas?.invoice_number}
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6 mb-3">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Date & Time</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <h2 className="mapsizer">
                        {formatDate(records?.ReviewData?.date)}
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-6 col-sm-12 mb-3 mb-lg-1">
                  <div class="row ">
                    <div class="col-xs-5  col-sm-5 col-md-5">
                      <h2 className="mapsize">Total </h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6 p-0 m-0">
                      <h2
                        style={{
                          fontSize: "14px",
                          color: "#5c5757",
                          margin: "0",
                          marginTop: "0.2em",
                          marginLeft: "1em",
                        }}
                      >
                        $ {total ? total?.toFixed(2) : 0.0}
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
                  <div class="row mb-3">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Invoice Status</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      {file_status == 3 && (
                        <h2 className="mapsizer">Re-Scan</h2>
                      )}
                      {file_status == 4 && (
                        <h2 className="mapsizer">Flagged</h2>
                      )}
                      {(file_status == 5 ||
                        file_status == 1 ||
                        file_status == 6) && (
                          <h2 className="mapsizer">Processing</h2>
                        )}
                      {
                        file_status == 2 && (
                          <h2 className="mapsizer">Completed</h2>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6 mb-3 mb-lg-1">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Invoice Date</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <h2 className="mapsizer">
                        {/* {InvoiceDatas?.invoice_date} */}
                        {invoiceDate}
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6"></div>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
                  <div class="row" style={{ marginBottom: "1em" }}>
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Purveyor</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <h2
                        className="mapsizer"
                        style={{ fontSize: "14px", lineHeight: "1.5em" }}
                      >
                        {InvoiceDatas?.PurveyorRawName}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MappingTopBarReview;
