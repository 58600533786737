import React, { useState, useEffect, useRef } from 'react';
import './PossibleConnectionDashboard.css';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { fobeAxios } from '../middleware/interceptor';
import axios from 'axios';

const today = dayjs();
const edi_options = [
  { label: "SYSCO", value: "Sysco" },
  { label: "PFG", value: "PFS" },
  { label: "Ben E. Keith", value: "BenEkeith" },
  { label: "US Foods", value: "USFOOD" },
  { label: "McLANE", value: "Mclane" },
];

function INV_PossibleConnection() {
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([today, today]);
  const [selectedEDI, setSelectedEDI] = useState('Sysco');

  const cancelTokenSourceRef = useRef(null);

  const { RangePicker } = DatePicker;

  const rangePresets = [
    {
      label: 'Today',
      value: [today, today],
    },
    {
      label: 'Yesterday',
      value: [today.subtract(1, 'd'), today.subtract(1, 'd')],
    },
    {
      label: 'This Week',
      value: [today.startOf('week'), today.endOf('week')],
    },
    {
      label: 'This Month',
      value: [today.startOf('month'), today.endOf('month')],
    },
    {
      label: 'Last 3 Months',
      value: [today.subtract(3, 'month').startOf('month'), today.endOf('day')],
    },
  ];

  const onRangeChange = (val) => {
    setDateRange(val);
  };

  const getTableData = async () => {
    try {
      if (selectedEDI) {
        if (cancelTokenSourceRef.current) {
          cancelTokenSourceRef.current.cancel('Previous request canceled.');
        }

        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSourceRef.current = cancelTokenSource;

        setLoading(true);
        const startDate = dayjs(dateRange[0]).format('YYYY-MM-DD');
        const endDate = dayjs(dateRange[1]).format('YYYY-MM-DD');

        const response = await fobeAxios.get(
          `api/Invoice/getdeckAcountlist/?startDate=${startDate}&endDate=${endDate}&ediType=${selectedEDI}`,
          { cancelToken: cancelTokenSource.token }
        );

        console.log(response.data);
        setTableData(response.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setTableData([]);
        console.log('Request canceled:', error.message);
      } else {
        setTableData([]);
        console.error('Error fetching data:', error);
      }
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getTableData();
  }, [dateRange, selectedEDI]);

  return (
    <>
      <div className='first_conteent'>
        <div className='filter-head'>
          <RangePicker
            presets={rangePresets}
            value={dateRange}
            placeholder={['From', ' To']}
            onChange={(e) => onRangeChange(e)}
            allowClear={false}
          />
          <Select
            style={{ width: 150 }}
            placeholder="Select EDI"
            options={edi_options}
            onChange={val => setSelectedEDI(val)}
            value={selectedEDI}
          />
        </div>
        <div className='account-table-container'>
          {!!tableData && Object.keys(tableData).map((item) => {
            return (item !== 'Error' && item !== 'Message') && (
              <div key={item} className={`table-card-view-container ${item === "Possible Connection" ? "flex-2" : ""}`}>
                <div className='table-card-view'>
                  <div className='table-name'>{item} - {tableData[item].length}</div>
                  <div className='table-container'>
                    <div className={`table-head-outer ${item !== "Possible Connection" ? 'justify-center' : ''}`}>
                      <div className={`table-head ${item !== "Possible Connection" ? 'text-center' : ''}`}>Restaurant Name</div>
                      {item === "Possible Connection" && (
                        <div className='table-head'>Account Manager</div>
                      )}
                    </div>
                    <div className='table-data-outer-container'>
                      {!loading && tableData[item].length > 0 && tableData[item].map((item) => (
                        <div className='table-data-outer'>
                          <div className='table-data'>{item["Company"]}</div>
                          {item === "Possible Connection" && (
                            <div className='table-data'>{item["Account Manager"]}</div>
                          )}
                        </div>
                      ))}
                      {!loading && tableData[item].length === 0 && (
                        <div className='table-data-outer align-center'>
                          No records found
                        </div>
                      )}
                      {loading && (
                        <div className='table-data-outer align-center'>
                          Loading...
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  )
}

export default INV_PossibleConnection;
