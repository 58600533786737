import { useState } from "react";
import "./loader.css";
import { useEffect } from "react";

function Loader({ isActive }) {
  const [isLoad, setIsLoad] = useState(isActive)
  useEffect(()=>{
   setTimeout(() => { setIsLoad(false) }, 1000);
  },[])
  return (
    <>
      {isLoad && (
        <div className="se-pre-con">
          <div className="childen">
            <img className="im-g" src={require("./preloader.gif")} />
          </div>
        </div>
      )}
    </>
  );
}

export default Loader;
