import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Select } from 'antd';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import { fobeAxios } from '../../../middleware/interceptor';
import { ToastContainer, toast } from "react-toastify";
function MyForm() {
  const [form] = Form.useForm();
  // let month = []
  // let year = []
  const [expanded, setExpanded] = useState('Payment');
  const [ManualPayForm] = Form.useForm();
  const [Card_type, SetCard_type] = useState();
  const [Card_number, SetCard_number] = useState();
  const [Expiry_date, SetExpiry_date] = useState();
  const [Security_code, SetSecurity_code] = useState();
  const [disable, setdisable] = useState(false)
  const [exdisable, setexdisable] = useState(false)
  const [currentYear, setCurrentYear] = useState(null);
  const [paytype, setpaytype] = useState('')
  let { rest_id } = useParams();
  useEffect(() => {
    GetPaymentData(rest_id)
    Getpaymentdetails(rest_id)
    setCurrentYear(new Date().getFullYear())
  }, []);

  const GetPaymentData = async (req) => {
    await fobeAxios.get("Api/Admin/GetManualPayment/" + req).then((res) => {
      SetCard_type(res.data.ManualPay[0].Card_Type);
      SetCard_number(res.data.ManualPay[0].Card_No);
      SetExpiry_date(res.data.ManualPay[0].Expriy_Date);
      SetSecurity_code(res.data.ManualPay[0].Security_Code);
    }).catch((err) => {
      console.log(err)
    })
  }
  const Getpaymentdetails = async (req) => {
    await fobeAxios.get("Api/Admin/GetUserProfile/" + req).then((res) => {
      form.setFieldsValue({
        cfname: res.data.UserDetails[0].FirstName,
        clname: res.data.UserDetails[0].LastName,
        CEmail: res.data.UserDetails[0].Email,
        CRestname: res.data.UserDetails[0].RestaurantName,
        customer_profile_id: res.data.UserDetails[0]?.customer_profile_id,
        payment_profile_id: res.data.UserDetails[0]?.payment_profile_id,
      })
    }).catch((err) => err)

  }
  //useEffect(()=>{form.resetFields,[]})
  const ManualPay = async (val) => {
    setexdisable(true)
    let req = {
      payAmount: val.Payment,
      rest_id: rest_id
    }
    await fobeAxios.post("Api/Admin/UpdateCardPayment", req).then((res) => {
      if (res.data.Error_Code == 1) {
        toast.success("Payment Successful");
        ManualPayForm.resetFields();
        setexdisable(false)
      } else {
        toast.error(res.data.Message);
        setexdisable(false)
      }
    }).catch((err) => {
      console.log(err)
      setexdisable(false)
    })
  }

  // month?.push({ id: 1, month: 1 }, { id: 2, month: 2 }, { id: 3, month: 3 }, { id: 4, month: 4 }, { id: 5, month: 5 }, { id: 6, month: 6 }, { id: 7, month: 7 }, { id: 8, month: 8 }, { id: 9, month: 9 }, { id: 10, month: 10 }, { id: 11, month: 11 }, { id: 12, month: 12 })
  // const currentDate = new Date();
  // let currentYear = currentDate.getFullYear();
  // let currentMonth=currentDate.getMonth() + 1;
  // let expiryyear = currentYear + 20
  // for (currentYear; currentYear < expiryyear; currentYear++) {
  //   year.push({ id: currentYear, year: currentYear })
  // }

  const selectpayment = (value) => {
    console.log(`selected ${value}`);
    setpaytype(value)
  };


  const handlePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    ManualPayForm.resetFields();
  };

  const Submit = async (val) => {
    setdisable(true)
    const value = ({ ...val, 'crest_id': rest_id })
    console.log(value, 'val')
    const t = { NewcardManualPayment: {...value,CCardType:0} }
    await fobeAxios.post('Api/Admin/NewcardManualPayment', t).then((res) => {
      if (res.data.Error_Code == 1) {
        toast.success("Payment Successful");
        form.resetFields(['cpaymentmethod', 'CBankAccountNumber', 'CBankRoutingNumber', 'CCardType', 'CCardHolderName', 'CCreditCardNumber', 'CExpiration_Month', 'CExpiration_Year', 'CSecurityCode', 'clocation', 'CStreetNo', 'caddress', 'ccity', 'cstate', 'ccounty', 'czip', 'cproduct', 'ctotal'])
        setpaytype('')
      } else {
        toast.error(res.data.Message);
      }
      setdisable(false)
    }).catch((err) => {
      console.log(err)
      setdisable(false)
    })
  }
  const cancel = async () => {
    form.resetFields(['cpaymentmethod', 'CBankAccountNumber', 'CBankRoutingNumber', 'CCardType', 'CCardHolderName', 'CCreditCardNumber', 'CExpiration_Month', 'CExpiration_Year', 'CSecurityCode', 'clocation', 'CStreetNo', 'caddress', 'ccity', 'cstate', 'ccounty', 'czip', 'cproduct', 'ctotal'])
    setpaytype('')
  }
  const handleKeyDown = (e) => {
    // Allow only letters (A-Z and a-z)
    if (e.key.match(/[^A-Za-z\s]/) && !['Backspace', 'Control', 'c', 'Control', 'v', 'Control', 'x'].includes(e.key)) {
      e.preventDefault();

    }
  };

  const handleKeyDownfna = (e) => {
    // Allow only letters (A-Z and a-z)
    if (e.key.match(/[^A-Za-z0-9\s]/) && !['Backspace', 'Control', 'c', 'Control', 'v', 'Control', 'x'].includes(e.key)) {
      e.preventDefault();

    }
  };
  const handleKeynumDown = (e) => {
    // Allow only letters (A-Z and a-z)
    if ((e.key.match(/[^0-9]/)) && !['Backspace', 'Control', 'c', 'Control', 'v', 'Control', 'x'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeynumtotal = (e) => {
    // Allow only letters (A-Z and a-z)
    if ((e.key.match(/[^0-9]/)) && !['Backspace', 'Control', 'c', 'Control', 'v', 'Control', 'x'].includes(e.key)) {
      e.preventDefault();
    }

  }

  const onfail = (e) => {
    toast.warning('Please fill Mandatory fields')
  }

  return (
    <>
      <Box style={{ width: '100%' }}>
        <Accordion expanded={expanded === 'Payment'} onChange={handlePanel('Payment')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#19A73F' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className='accortwo'>Existing Card</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <>
              <Form form={ManualPayForm} onFinish={ManualPay} >
                <div className='excard'>

                  <table>
                    <tbody>
                      {Card_type === "Bank Account" ?
                        [<>
                          <tr>
                            <td>Bank Account Number</td>
                            <td>:</td>
                            <td>{Card_number ? Card_number : "-"}</td>
                          </tr>
                          <tr>
                            <td>Bank Routing Number</td>
                            <td>:</td>
                            <td>{Security_code ? Security_code : "-"}</td>
                          </tr></>] :
                        [<>
                          {/* <tr>
                            <td>Card Type</td>
                            <td>:</td>
                            <td>{Card_type ? Card_type : "-"}</td>
                          </tr> */}
                          <tr>
                            <td>Card Number</td>
                            <td>:</td>
                            <td>{Card_number ? Card_number : "-"}</td>
                          </tr>
                          {/* <tr>
                            <td>Expiry Date</td>
                            <td>:</td>
                            <td>{Expiry_date ? Expiry_date : "-"}</td>
                          </tr>
                          <tr>
                            <td>Security Code</td>
                            <td>:</td>
                            <td>{Security_code ? Security_code : "-"}</td>
                          </tr> */}
                        </>]
                      }
                      <tr>
                        <td>Amount</td>
                        <td>:</td>
                        <td><Form.Item name="Payment" rules={[{ required: true, message: "Please enter a amount" }]} style={{ marginBottom: '0px' }} ><Input style={{ height: '34px', width: '60%' }} onKeyDown={handleKeynumtotal} /></Form.Item></td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div className='excardbut'>
                  <Button type="primary" disabled={exdisable} htmlType="submit" style={{ backgroundColor: '#19A73F' }}>Pay</Button>
                  <Button type="primary" style={{ backgroundColor: 'rgb(239 239 239)', border: '0.5px solid #b2b2b2', color: '#000' }} onClick={() => { ManualPayForm.resetFields() }}>Cancel</Button>
                </div>
              </Form>
            </>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'NewCard'} onChange={handlePanel('NewCard')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#19A73F' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className='accortwo'>New Card</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <>
              <div className='cardnew'>
                <Form form={form} layout="vertical" style={{ width: '80%', margin: '0 auto' }} onFinish={Submit} onFinishFailed={onfail}>
                  <div className='col-md-12'>
                    <p className='formhead'>Enter Your Account Information</p>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Item
                          name="cfname"
                          label="First Name"

                          rules={[
                            {
                              message: 'Firstname is Required',
                              required: true,
                            },


                          ]}
                        >
                          <Input placeholder="Enter Your First Name" disabled={true} style={{ height: '38px', width: '70%' }} />
                        </Form.Item>
                      </div>
                      <div className='col-md-6'>
                        <Form.Item
                          name="clname"
                          label="Last Name"
                          rules={[
                            { required: true, message: ' Last Name is required' },

                          ]}
                        >
                          <Input placeholder="Enter Your Last Name" disabled={true} style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDownfna} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Item
                          name="CEmail"
                          label="Username"
                          rules={[
                            {

                              message: 'Username is required',
                              required: true,
                            },
                            {
                              type: 'email',
                              message: 'Invalid Username'
                            }
                          ]}
                        >
                          <Input placeholder="Enter Your User Name" disabled={true} style={{ height: '38px', width: '70%' }} />
                        </Form.Item>
                      </div>
                      <div className='col-md-6'>
                        <Form.Item
                          name="CRestname"
                          label="Restaurant Name"
                          rules={[
                            {
                              message: ' Restaurant name is required',
                              required: true,
                            },

                          ]}
                        >
                          <Input placeholder="Enter Your Restaurant Name" disabled={true} style={{ height: '38px', width: '70%' }} />
                        </Form.Item>
                      </div>
                    </div>
                    <p className='formhead'>Enter Your Billing Information - SECURE</p>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Item
                          name="cpaymentmethod"
                          label="Payment Method"
                          rules={[
                            {
                              message: 'Payment type is required',
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            onChange={selectpayment}
                            //  defaultValue={paytype}
                            className='formroles'
                            style={{ height: '38px', width: '70%' }}
                            placeholder="Choose Payment Method"
                            options={[
                              {
                                value: '1',
                                label: 'Credit Card',
                              },
                              {
                                value: '2',
                                label: 'Bank Account',
                              }
                            ]}

                          >
                          </Select>
                        </Form.Item>
                      </div>

                    </div>
                    {paytype == '2' &&
                      <div className='row'>
                        <div className='col-md-6'>
                          <Form.Item
                            name="CBankAccountNumber"
                            label="Bank Account Number"
                            rules={[
                              { required: true, message: 'Account Number  is required' },
                              { min: 4, message: 'Maximum (4-17) digits' },
                              // { validator: validateaccountonly },
                            ]} >

                            <Input placeholder="Enter your Account Number" maxLength={17} style={{ height: '38px', width: '70%' }} onKeyDown={handleKeynumDown} />
                          </Form.Item>
                        </div>
                        <div className='col-md-6'>
                          <Form.Item
                            name="CBankRoutingNumber"
                            label="Bank Routing Number"
                            rules={[
                              {
                                message: 'Routing Number is required',

                                required: true,
                              },
                              { min: 9, message: 'Maximun 9 digits' }
                            ]}
                          >
                            <Input placeholder="Enter your Routing Number" style={{ height: '38px', width: '70%' }} maxLength={9} onKeyDown={handleKeynumDown} />
                          </Form.Item>
                        </div>
                      </div>
                    }
                    {paytype == '1' &&
                      <div className='row'>
                        {/* <div className='col-md-6'>
                          <Form.Item
                            name="CCardType"
                            label="Credit Card Type"

                            
                          >
                            <Select
                              //defaultValue="American Express"
                              style={{ height: '38px', width: '70%' }}
                              placeholder="Choose Card Type"
                              //onChange={handleChange}
                              className='formroles'
                              options={[
                                {
                                  value: '1',
                                  label: 'Visa',
                                },
                                {
                                  value: '2',
                                  label: 'Master Card',
                                },

                                {
                                  value: '3',
                                  label: 'American Express',
                                },


                              ]}

                            />
                          </Form.Item>
                        </div> */}
                        <div className='col-md-6'>
                          <Form.Item
                            name="CCardHolderName"
                            label="Card Holder Name"

                            rules={[
                              {
                                message: 'Cardholder Name is required',
                                required: true,
                              },

                            ]}
                          >
                            <Input placeholder="Enter Card Holder Name" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDown} />
                          </Form.Item>
                        </div>
                      </div>
                    }
                    {paytype == '1' &&
                      <div className='row'>
                        <div className='col-md-6'>
                          <Form.Item
                            name="CCreditCardNumber"
                            label="Credit Card Number"

                            rules={[
                              {
                                message: 'Credit Card is Required',
                                required: true,
                              },
                              { min: 9, message: '*Maximum (9-16) digits' },
                            ]}
                          >
                            <Input placeholder="Enter Credit Card Number" style={{ height: '38px', width: '70%' }} maxLength={16} onKeyDown={handleKeynumDown} />
                          </Form.Item>
                        </div>
                        <div className='col-md-3' style={{ width: '21%' }}>
                          <Form.Item
                            name="CExpiration_Month"
                            label="Expiration Month"

                            rules={[
                              {
                                message: 'Expiration Month is required',
                                required: true,
                              },
                              // ({getFieldValue})=>({
                              //   validator(rule,value){
                              //     if ((parseInt(value) < new Date().getMonth()) && getFieldValue('CExpiration_Year')==2023 ) {
                              //       return Promise.reject('Invalid Month');
                              //     }
                              //     return Promise.resolve();
                              //   }
                              // })
                            ]}
                          >
                            <Select

                              style={{ height: '38px', width: '70%' }}
                              placeholder={'Month'}
                              className='formroles'
                              options={Array.from({ length: 12 }, (_, index) => ({
                                label: index + 1 > 9 ? (index + 1).toString() : '0' + (index + 1).toString(),
                                value: index + 1 > 9 ? (index + 1).toString() : '0' + (index + 1).toString(),
                              }))}
                            />
                          </Form.Item>
                        </div>
                        <div className='col-md-3' style={{ width: '20%' }}>
                          <Form.Item
                            name="CExpiration_Year"
                            label="Expiration Year"
                            rules={[
                              {
                                message: 'Expiry Month is required',
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              placeholder={'Year'}
                              style={{ height: '38px', width: '70%' }}
                              className='formroles'
                              options={Array.from({ length: 12 }, (_, index) => ({
                                label: (currentYear + index).toString(),
                                value: (currentYear + index).toString()
                              }))}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    }
                    {paytype == '1' &&
                      <div className='row'>
                        <div className='col-md-6'>
                          <Form.Item
                            name="CSecurityCode"
                            label="Security Code"

                            rules={[
                              {
                                message: 'Security code is required',
                                required: true,
                              },
                              { min: 3, message: 'Security code is required' },
                            ]}
                          >
                            <Input placeholder="Enter Security Code" style={{ height: '38px', width: '70%' }} maxLength={4} onKeyDown={handleKeynumDown} />
                          </Form.Item>
                        </div>
                      </div>
                    }
                    <div className='row'>
                      <div className='termscon'>
                        <p>BY CLICKING ON THE CONTINUE BUTTON, I AGREE TO THE TERMS AND CONDITIONS OF USING MY CHECKING ACCOUNT AS A PAYMENT METHOD, WHICH ARE LISTED BELOW, AND AUTHORIZE FOBESOFT.COM  TO DEBIT MY CHECKING ACCOUNT FOR PURCHASES MADE ON FOBESOFT.COM , ANY FOBESOFT.COM  AFFILIATE WEB SITE, OR THIRD PARTY WEB SITE USING FOBESOFT PAYMENTS.</p>
                        <p>Welcome to the terms and conditions  for use of a bank account registered with fobesoft.com as a payment method on the fobesoft.com website, any fobesoft affiliate website, or third-party website. Please note that your use of the fobesoft.com website is also governed by our Conditions of Use and Privacy Notice, as well as all other applicable terms, conditions, limitations and requirements contained on the fobesoft.com website, all of which (as changed over time) are incorporated into these Terms and Conditions. If you choose to use a bank account as your payment method, you accept and agree to these Terms and Conditions.</p>
                        <ol>
                          <li>Bank Account Payments. By choosing to use a bank account as your payment method, you will be able to complete your purchase using any valid automated clearing house ('ACH') enabled bank account at a United States-based financial institution. Whenever you choose to pay for an order using your bank account, you are authorizing fobesoft.com (or its agent) to debit your bank account for the total amount of your purchase (including applicable taxes, fees and shipping costs). Your transaction must be payable in U.S. dollars. fobesoft.com, in its sole discretion, may refuse this payment option service to anyone or any user without notice for any reason at any time.</li>
                          <li>ACH Authorization.<br />
                            <p> By choosing your bank account as your payment method, you agree that: (a) you have read, understand and agree to these Terms and Conditions, and that this agreement constitutes a 'writing signed by you' under any applicable law or regulation, (b) you consent to the electronic delivery of the disclosures contained in these Terms and Conditions, (c) you authorize fobesoft.com (or its agent) to make any inquiries we consider necessary to validate any dispute involving your payment, which may include ordering a credit report and performing other credit checks or verifying the information you provide against third party databases, and (d) you authorize fobesoft.com (or its agent) to initiate one or more ACH debit entries (withdrawals) or the creation of an equivalent bank draft for the specified amount(s) from your bank account, and you authorize the financial institution that holds your bank account to deduct such payments.</p>
                          </li>
                          <li>Partial Debits and Returned Payments.<br />
                            <p>  If your full order is not processed by us at the same time, you hereby authorize partial debits from your bank account, not to exceed the total amount of your order. If any of your payments are returned unpaid, you authorize fobesoft.com (or its agent) to make a one-time electronic fund transfer from your account to collect a return fee. This return fee will vary based on which state you are located in, as follows: $20 in CO, CT, ID, IN, NY and UT; $10 in Puerto Rico; and $25.00 for all other states. The return fee may be added to your payment amount and debited from your bank account if we resubmit an ACH debit due to insufficient funds. We may initiate a collection process or legal action to collect any money owed to us. You agree to pay all our costs for such action, including any reasonable attorneys' fees.</p>
                          </li>
                          <li>Cancellations and Refunds.<br />
                            <p>To cancel a purchase made on the fobesoft.com website and request a refund, please follow the instructions and procedures contained on the fobesoft.com website. When you cancel a purchase in accordance with these procedures, fobesoft.com (or its agent) will initiate a credit to your bank account for the correct amount. For purchases made from a seller other than fobesoft.com, please contact the seller regarding its policy on returns and refunds.</p>
                          </li>
                          <li>Customer Service.<br />
                            <p>Transactions that we process using your bank account will be identified as 'fobesoft' (or similar identifier) on the statement issued by your bank or other financial institution holding your account. All questions relating to any transactions made using your bank account by us should be initially directed to us. Save the order confirmations that you are provided when you make a purchase, and check them against your bank account statement. You may contact us regarding your fobesoft.com orders or any payments made using your bank account and by writing to us at http://www.fobesoft.com/contact-us. You may also view your transaction history for your fobesoft.com order at any time in the 'Your Account' area of the fobesoft.com website.</p>
                          </li>
                          <li>Transaction Errors.<br />
                            <p>  If you believe that any payment transaction initiated by fobesoft.com (or its agent) with respect to your bank account is erroneous, or if you need more information about any such transaction, you should contact us as soon as possible as provided in Section 5 of these Terms and Conditions. Notify us at once if you believe the password associated with your fobesoft.com customer account has been lost or stolen, or if someone has attempted (or may attempt) to make a transfer from your bank account to complete a purchase using your fobesoft.com customer account without your permission. We reserve the right to cancel the ability to pay by bank account on fobesoft.com or other website for any reason at any time.</p>
                          </li>
                          <li>Your Liability for Unauthorized Transactions.<br />
                            <p> Federal law limits your liability for any fraudulent, erroneous unauthorized transaction from your bank account based on how quickly you report it to your financial institution. As general rule, you should report any fraudulent, erroneous or unauthorized transactions to your bank within 60 days after the questionable transaction FIRST appeared on your bank account statement. You should contact your bank for more information about the policies and procedures that apply to your account and any unauthorized transactions, including any limits on your liability.</p>
                          </li>
                          <li>Electronic Delivery of Future Disclosures.<br />
                            <p> You agree to accept all disclosures and other communications between you and us on this website or at the primary e-mail address associated with your fobesoft.com customer account. You should print and retain a copy of all such disclosures and communications.</p>
                          </li>
                          <li>Agreement Changes.<br />
                            <p> We may in our discretion change these Terms and Conditions, fobesoft.com's Conditions of Use and/or its Privacy Notice at any time without notice to you. If any change is found to be invalid, void, or for any reason unenforceable, that change is severable and does not affect the validity and enforceability of any other changes or the remainder of these Terms and Conditions. We reserve the right to subcontract any of our rights or obligations under these Terms and Conditions. YOUR CONTINUED USE OF YOUR BANK ACCOUNT AS A PAYMENT METHOD ON FOBESOFT.COM OR OTHER SITES USING YOUR FOBESOFT ACCOUNT ARE ACCEPTED AFTER WE CHANGE THESE TERMS AND CONDITIONS, FOBESOFT.COM'S CONDITIONS OF USE AND/OR ITS PRIVACY NOTICE CONSTITUTES YOUR ACCEPTANCE OF THESE CHANGES.</p>
                          </li>
                        </ol>
                      </div>
                    </div>

                    <p className='formhead' style={{ marginTop: '15px' }}>Enter Your Billing Address</p>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Item
                          name="clocation"
                          label="Location"
                          rules={[
                            {
                              message: 'Location is required',
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Enter your Location" style={{ height: '38px', width: '70%' }} />
                        </Form.Item>
                      </div>
                      <div className='col-md-6'>
                        <Form.Item
                          name="CStreetNo"
                          label="Street Name"
                          rules={[
                            {
                              message: 'Street Name is required',
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Enter your Street Name" style={{ height: '38px', width: '70%' }} />
                        </Form.Item>
                      </div>

                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Item
                          name="caddress"
                          label="Address"
                          rules={[
                            {
                              message: 'Address is required',
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Enter your Address" style={{ height: '38px', width: '70%' }} />
                        </Form.Item>
                      </div>
                      <div className='col-md-6'>
                        <Form.Item
                          name="ccity"
                          label="City"
                          rules={[
                            {
                              message: 'City is required',
                              required: true,
                            },

                          ]}
                        >
                          <Input placeholder="Enter your City" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDown} />
                        </Form.Item>
                      </div>

                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Item
                          name="cstate"
                          label="State"
                          rules={[
                            {
                              message: 'State is required',
                              required: true,
                            },

                          ]}
                        >
                          <Input placeholder="Enter your State" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDown} />
                        </Form.Item>
                      </div>
                      <div className='col-md-6'>
                        <Form.Item
                          name="ccounty"
                          label="Country"
                          rules={[
                            {
                              message: 'Country is required',
                              required: true,
                            },

                          ]}
                        >
                          <Input placeholder="Enter your Country" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDown} />
                        </Form.Item>
                      </div>

                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Item
                          name="czip"
                          label="Zip"
                          rules={[
                            {
                              message: 'Zip is required',
                              required: true,
                            },
                            { min: 3, message: 'Maximum (3-6) digits' },

                          ]}
                        >
                          <Input placeholder="Enter your Zip" maxLength={6} style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDownfna} />
                        </Form.Item>
                      </div>
                      <div className='col-md-6'>
                        <Form.Item
                          name="cproduct"
                          label="Product"
                          rules={[
                            {
                              message: 'Product is required',
                              required: true,
                            },

                          ]}
                        >
                          <Input placeholder="Enter your Product" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeyDown} />
                        </Form.Item>
                      </div>

                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Item
                          name="ctotal"
                          label="Total"
                          rules={[
                            {
                              message: 'Total is required',
                              required: true,
                            },

                          ]}
                        >
                          <Input placeholder="Enter your Total" style={{ height: '38px', width: '70%' }} onKeyDown={handleKeynumtotal} />
                        </Form.Item>
                      </div>
                      <Form.Item name='customer_profile_id'></Form.Item>
                      <Form.Item name='payment_profile_id'></Form.Item>

                      <Form.Item>
                        <div className='excardbut1' style={{ marginTop: '25px' }}>
                          <Button type="primary" disabled={disable} htmlType="submit" style={{ backgroundColor: '#19A73F' }}>Pay</Button>
                          <Button type="primary" style={{ backgroundColor: 'rgb(239 239 239)', border: '0.5px solid #b2b2b2', color: '#000' }} onClick={cancel}>Cancel</Button>
                        </div>
                      </Form.Item>

                    </div>
                  </div>



                </Form>
              </div>

            </>
          </AccordionDetails>
        </Accordion>

      </Box>
    </>
  );
};

export default MyForm;

